import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';

const services = [
  {
    name: "Nutriólogo",
    img: "assets/images/orientacionNutricionalTelefono.jpg",
     schedule: "Lunes a viernes 9 a 17 hrs."
  },
  {
    name: "Psicólogo",
    img: "assets/images/orientacionPsicologicaTelefono.jpg",
    schedule: "Lunes a viernes 9 a 17 hrs."
  }, {
    name: "Orientación Médica",
    img: "assets/images/orientacionMedicaTelefono.jpg",
    schedule: "24/7"
  }];


@Component({
  selector: 'app-medicine-dialog',
  templateUrl: './medicine-dialog.component.html',
  styleUrls: ['./medicine-dialog.component.scss']
})

export class MedicineDialogComponent implements OnInit {
  @Input() display;
  @Output('closeModalMedicine') closeOutput = new EventEmitter();
  services = services;

  constructor() {
  }

  ngOnInit() {
  }

  onClose() {
    this.closeOutput.emit(false);
  }
}
