import { Component } from '@angular/core';
import { ClientService } from 'src/app/core/api';
import { Search } from '../../shared/search/search.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
    selector: 'app-noticias',
    templateUrl: './noticias.component.html',
    styleUrls: ['./noticias.component.scss']
})
export class NoticiasComponent {
    search: Search;
    news: any[] = null;

    constructor(
        private clientService: ClientService,
        private router: Router
    ) {
        this.search = new Search(() => {
            this.getList();
        }, 4);

        this.getList();
    }

    getList(): void {
        this.news = null;

        this.clientService.getActiveClientNewsBySearch(this.search).subscribe((response: any) => {
            this.news = response.items;

            this.news.forEach((new_: any) => {
              new_.contentNews = decodeURI(new_.contentNews).replace(/(<([^>]+)>)/gi, "");
            });

            this.search.totalRecords = response.extra.total;
        }, (httpErrorResponse: HttpErrorResponse) => {
            this.news = undefined;
        });
    }

    navigateToPromotion(id: string ): void {
      this.router.navigate(['/dashboard/noticias/' + id]);
    }

    truncateHTML(text: string): string {
        let charlimit = 80;
        if (!text || text.length <= charlimit) {
            return text;
        }
        let adding_spaces = text.replace(/<br \/>/g, "&nbsp;");

        let without_html = adding_spaces.replace(/<(?:.|\n)*?>/gm, '');
        let shortened = without_html.substring(0, charlimit) + "...";
        return shortened;
    }
}
