import * as actionTypes from '../actions/home.actions';
import {HomeState} from '../models/home.model';
import {BannerService} from '../models/BannerService.model';
import { NavOption } from '../interfaces/navOption.interface';


const initialState: HomeState = {
  openLoginForm: false,
  navOptions: <NavOption[]>[
    {
      title: 'Inicio',
      route: 'resumen'
    },
    {
      title:'Plan',
      route: 'plan-contratado'
    },
    // {
    //   title:'Asistencias',
    //   route: 'asistencias'
    // },
    {
      title:'Noticias',
      route: 'noticias'
    }
  ],
  bannerServices: [
    new BannerService('home-services.png', 'asistencia hogar 24/7'),
    new BannerService('gas-services.png', 'asistencia gas'),
    new BannerService('telemedicina-services.png', 'asistencia telemedicina')
  ]

};

export function homeReducer ( state = initialState, action: actionTypes.actions) {
  let newState = Object.assign({}, state);
  switch(action.type){
    case actionTypes.OPEN_LOGIN_VIEW:
      newState.openLoginForm = true;
      return newState;
    case actionTypes.CLOSE_LOGIN_VIEW:
      newState.openLoginForm = false;
      return newState;

    default:
      return newState;
  }
}
