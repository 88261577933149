import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Utils } from 'src/app/utils/utils.module';

@Component({
  selector: 'banner-engie-client',
  templateUrl: './banner-engie-client.component.html',
  styleUrls: ['./banner-engie-client.component.scss']
})
export class BannerEngieClientComponent implements OnInit {
  username: string;
  isEngieClient: boolean;
  deviceType: string;
  displayNot = false;

  @Input()
  assistences: any[] = [];

  @Input()
  countLoaded: boolean = true;

  constructor(
    private router: Router,
  ) {
    this.username = localStorage.getItem('nameuser').toUpperCase();
    this.isEngieClient = localStorage.getItem('engie') === "true";
    this.deviceType = Utils.getMobileOperatingSystem();
  }

  ngOnInit() {
  }

  goToDownloadAppAndroid(): void {
      window.open('https://play.google.com/store', '_blank');
  }
  
  goToDownloadAppIos(): void {
      window.open('https://apps.apple.com', '_blank');
  }

  redirect(assistance) {
    this.router.navigate([assistance.url], {state: { type:  assistance.keyObject } })
  }
  
  onClick(){
    this.displayNot = true;
  }
  
  onClose(){
    this.displayNot = false;
  }
}
