import { Component, OnInit } from '@angular/core';
import {ClientService} from "../../core/api";
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as actionTypes from '../../actions/home.actions';
import { AppState } from 'src/app/interfaces/app.interface';

@Component({
  selector: 'app-header-app',
  templateUrl: './header-app.component.html',
  styleUrls: ['./header-app.component.scss'],
})
export class HeaderAppComponent implements OnInit {
  openLoginForm: boolean;
  isLogged: boolean;

  constructor(
    public clienteService: ClientService,
    private router: Router,
    private store: Store<AppState>
    ) {
      this.store.select('app').subscribe(
        app => this.isLogged = app.isLogged
      )
  }

  ngOnInit() {
    this.store.select('home').subscribe(
      home => this.openLoginForm = home.openLoginForm
    )
  }

  secondaryButtonClick() {
    if(!this.clienteService.isAuthenticated()) {
      this.clienteService.setIsRegister(true);
      this.router.navigate(['register']);
      this.store.dispatch(new actionTypes.CloseLoginViewActions());
      return;
    }
    else
      this.clienteService.logoutClient();
  }

  goHome():void {
    if(this.openLoginForm){
      this.store.dispatch(new actionTypes.CloseLoginViewActions());
    } else {
      this.router.navigate(['home']);
    }

  }

  onClickButtonLogin($event: boolean, op:any) {
    if (screen.width < 768 || screen.height < 768) {
      this.store.dispatch(new actionTypes.OpenLoginviewActions());
    } else if(!this.openLoginForm){
      op.toggle($event);
    }
  }
}
