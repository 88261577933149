import { Component, OnInit, Inject } from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ClientService } from "../../core/api/client/client.service"

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  bookingId: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    public dialogRef: MatDialogRef<DialogComponent>,
    public clientService: ClientService) { }

  ngOnInit() {
    this.bookingId = this.data.id;
  }

  click() {
    this.clientService.deleteRequest().subscribe((response: any) => {
      if (response !== undefined) {
        
      }
    });
    this.dialogRef.close();
    this.router.navigate(['/home'])
 }
 click1() {
  this.dialogRef.close();
  
}

}
