import {Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {ClientService} from '../../../core/api/client/client.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { MessageService } from 'primeng/api';
import { AppState } from 'src/app/interfaces/app.interface';
import { Store } from '@ngrx/store';
import * as actionTypes from 'src/app/actions/app.actions';

@Component({
  selector: 'app-downuser',
  templateUrl: './downuser.component.html',
  styleUrls: ['./downuser.component.scss']
})
export class DownuserComponent implements OnInit {

  title: any[] = ['Contraseña'];
  subtitle: any[] = [];
  userId: number;
  sessionToken: string;
  componentSubscr: Subscription;
  passwordChange: FormGroup;
  nPassword: string;
  id:string;
  oPassword: string;
  error: any;
  // passwordMatcher = new RepeatPasswordEStateMatcher();
  change: any;
  hide = true;

  constructor(private router: Router,
              public activate: ActivatedRoute,
              public clientService: ClientService,
              private formBuilder: FormBuilder,
              private store: Store<AppState>,
              private messageService: MessageService) {

    this.passwordChange = this.formBuilder.group({
      oldPassword: new FormControl('', Validators.required),

    }, {validator: this.globalFormValidator});


  }

  /**
   * Function to validate requirements of form provided
   * @param form Form to validate
   */
  globalFormValidator(form: FormGroup): { [key: string]: boolean } | null {
    const password = form.get('newPassword');
    const confirmPassword = form.get('confirmPassword');
    let errorObj = {};

    return !!Object.keys(errorObj).length ? errorObj : null;
  }


  ngOnInit() {
    this.componentSubscr = this.activate.parent.params.subscribe(parametros => {
      this.userId = JSON.parse(parametros.id);
    });
  }

  ngOnDestroy() {
    if (this.componentSubscr && !this.componentSubscr.closed)
      this.componentSubscr.unsubscribe();
  }

  back() {
    this.router.navigate([`/dashboard/perfil`]);
  }

  submitForm() {
    this.sessionToken = localStorage.getItem('sessionToken');
    this.id= localStorage.getItem('clientId');
    this.oPassword = this.passwordChange.value.oldPassword;

       this.clientService.lay_Off_Account(this.sessionToken, this.oPassword, this.id).subscribe(response=>{
        this.messageService.add({severity: 'success', summary:'Éxito', detail:'Su cuenta ha sido eliminada'});
        localStorage.clear();
        this.store.dispatch(new actionTypes.RemoveUserLoggedActions());
        this.router.navigate(['/home']);

       }, err=>{
        this.messageService.add({severity: 'error', summary:'Error', detail: err.error.error});
      })
  }


}
