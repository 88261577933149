import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl, } from '@angular/platform-browser';

@Component({
  selector: 'app-axa',
  templateUrl: './axa.component.html',
  styleUrls: ['./axa.component.scss']
})
export class AxaComponent implements OnInit {

  failedConnect: boolean = false;
  axaUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer, private router: Router, private zone: NgZone) {
    this.zone.runOutsideAngular(() => {
      navigator.getUserMedia({
        video: true,
        audio: true
      }, function () { }, function () { });
    });
    let data = history.state;
    if (!data.error && !data.axaUrl) {
      this.router.navigate(['/dashboard/solicitud']);
    } else if (data.error != undefined && data.error != null) {
      this.failedConnect = true;
    } else {
      this.axaUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data.axaUrl);
    }
  }

  ngOnInit() {
    window.addEventListener("message", this.receiveMessage.bind(this), false);
  }

  receiveMessage(event) {
    console.log(event.data);
    if (event.data == "close_phonepad") {
      this.router.navigate(['/dashboard/solicitud']);
    }
  }

}
