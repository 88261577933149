import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/api';

@Component({
  selector: 'app-base-dialog',
  templateUrl: './base-dialog.component.html',
  styleUrls: ['./base-dialog.component.scss']
})
export class BaseDialogComponent implements OnInit {

  public file=null;

  constructor(public dialogRef:DynamicDialogRef, public config: DynamicDialogConfig) { }

  ngOnInit() {
  }

  onClose() {
    this.dialogRef.close();
  }

	fileChange(event) {
    let fileList: FileList = event.files;
    if(fileList.length > 0) {
      this.file = fileList[0]
    }
	}

  deleteImage(){
  	this.file=null;
  }

  onSave() {
    this.dialogRef.close(this.file);
  }
}
