import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ClientService } from 'src/app/core/api';
import { AppState } from 'src/app/interfaces/app.interface';
import { API_MAIN_URL } from '../../shared/constants';
import * as actionTypes from '../../../actions/app.actions';
import {MessageService} from 'primeng/api';

export function getClientIdsFromForm(isEngie: boolean, formGroup: FormGroup): number[] {
  if (Boolean(isEngie).valueOf()) {
    const engieDataFormArray: FormArray = formGroup.get('engieAccountDatas') as FormArray;
    return engieDataFormArray.controls.map((formGroup: FormGroup) => {
      return formGroup.get('id').value
    });
  }
  return [];
}

export function getEngieDataFormGroup(): FormGroup {
  const formBuilder = new FormBuilder();
  return formBuilder.group({
    interlocutor: new FormControl('', Validators.required),
    accountNumber: new FormControl('', Validators.required),
    id: new FormControl('', Validators.required)
  });
}

@Component({
  selector: 'client-engie-form',
  templateUrl: './client-engie-form.component.html',
  styleUrls: ['./client-engie-form.component.scss'],

})
export class ClientEngieFormComponent {

  @Input()
  registerFormGroup: FormGroup;
  errorEngieMessage: string = '';
  url: string = API_MAIN_URL;

  @Output() engieClient = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder,
    private clientService: ClientService,
    private store: Store<AppState>,
    private messageService: MessageService
  ) { }

  addEngieFormGroup(): void {
    const engieDataFormArray: FormArray = this.registerFormGroup.get('engieAccountDatas') as FormArray;

    engieDataFormArray.push(this.getEngieDataFormGroup(engieDataFormArray.value[0].interlocutor));
  }

  removeEngieFormGroup(index: number): void {
    const engieDataFormArray: FormArray = this.registerFormGroup.get('engieAccountDatas') as FormArray;

    const formArrayLength: number = engieDataFormArray.length;

    if (formArrayLength === 1) {
      engieDataFormArray.at(index).reset();
    } else {
      if(index === this.getLastEngieClientIterator) {
        this.errorEngieMessage = null
      };
      engieDataFormArray.removeAt(index);
    }
  }

  getEngieDataFormGroup(interlocutor?: string): FormGroup {
    return this.formBuilder.group({
      interlocutor: new FormControl(interlocutor ? interlocutor : '', Validators.required),
      accountNumber: new FormControl('', Validators.required),
      id: new FormControl('', Validators.required)
    })
  }

  get getLastEngieClientIterator(): number {
    return this.registerFormGroup.get('engieAccountDatas').value.length - 1;
  }

  async validateEngieClient(index: number) {
    const isEngieData: FormArray = this.registerFormGroup.get('engieAccountDatas') as FormArray;
    const targetFormGroup: FormGroup = isEngieData.get(index.toString()) as FormGroup;

    if (targetFormGroup.get('interlocutor').invalid || targetFormGroup.get('accountNumber').invalid) {
      this.errorEngieMessage = "Por favor introduzca los datos solicitados para proceder a la validación"

      return;
    }

    const { interlocutor: { value: interlocutor }, accountNumber: { value: accountNumber } } = targetFormGroup.controls;

    const engieClientIndetifiers = {
      interlocutor,
      accountNumber: "00" + accountNumber
    };


    try {
      this.store.dispatch(new actionTypes.OpenLoadingActions());
      const response = await this.clientService.getEngieClientByIdentifiersWithoutInstalationNumber(engieClientIndetifiers).toPromise();
      this.engieClient.emit(response);
      const { userId } = response;
      for (let i = 0; i < isEngieData.controls.length; i++) {
        const id = isEngieData.controls[i].value.id;

        if (id && id === userId) {
          this.messageService.add({severity: 'error', summary:'Error', detail:"Ya agregaste este contrato"});

          return;
        }
      }

      targetFormGroup.get('id').setValue(userId);
      this.messageService.add({severity: 'success', summary:'Éxito', detail:'Cuenta ENGIE agregada'});
      this.errorEngieMessage = null;
    } catch (errResponse) {
      if (errResponse.error) {
        this.messageService.add({severity: 'error', summary:'Error', detail:errResponse.error.error ===
        "No se encontro cliente servi2" ? "No se encontró cuenta ENGIE" : errResponse.error.error});
      }
    }
    finally {
      this.store.dispatch(new actionTypes.CloseLoadingActions());
    }
  }

  get showButtonAddAccount() {
    const engieDataFormArray: FormArray = this.registerFormGroup.get('engieAccountDatas') as FormArray;
    const formArrayLength: number = engieDataFormArray.length;

    if (engieDataFormArray.controls[formArrayLength - 1].invalid) {
      return false;
    }

    return true;
  }
}
