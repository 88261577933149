import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ClientService } from 'src/app/core/api';
import { AppState } from 'src/app/interfaces/app.interface';
import * as notificationTypes from 'src/app/actions/notifications.actions';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-notifications-panel',
  templateUrl: './notifications-panel.component.html',
  styleUrls: ['./notifications-panel.component.scss']
})
export class NotificationsPanelComponent implements OnInit {

  notifications: any[] = []

  constructor(private store: Store<AppState>,
    private clientService: ClientService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.store.select('notifications').subscribe(data => this.notifications = data.unReads.length > 3 ? data.unReads.slice(0,3) : data.unReads);
  }

  setToReadNotification(notification: any) {
    this.clientService.setReadOneNotification(notification).subscribe((data: any) => {
      if(data){
        this.store.dispatch(new notificationTypes.UpdateNotificationActions(data));
        this.store.dispatch(new notificationTypes.DecrementNotificationsUnreadActions(data));
        this.messageService.add({severity: 'success', summary:'Éxito', detail:'Notificación marcada como leída.'});
      }
    }, error => {
      this.messageService.add({severity: 'error', summary:'Error', detail:'No se realizó la acción'});
    });
  }

}
