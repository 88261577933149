import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'asistences',
  templateUrl: './asistences.component.html',
  styleUrls: ['./asistences.component.scss']
})
export class AsistencesComponent {

  @Input()
  services: any[] = [];

  @Input()
  loading: boolean;

  constructor(
    private router: Router
  ) { }

  goToAssist(id: number): void {
    this.router.navigate([`dashboard/asistencia`, id]);
  }
}
