import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {ClientService} from '../../../core/api/client/client.service';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material';
import {getClientIdsFromForm} from '../../login/register-component/engie-details/engie-details.component';
import {TutenClientProfileData, TutenClientProfile} from '../../../core/api/client/client-interfaces';
import {SnackbarService} from '../../../core/api/snackbar.service';
import {MESSAGE_TIMES} from '../../shared/constants';

// Custom Error states matchers to trigger mat-error flag at templeate
class RepeatPasswordEStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return (control && control.parent.get('newPassword').value !== control.parent.get('confirmPassword').value && control.dirty)
  }
}

@Component({
  selector: 'app-contrasena',
  templateUrl: './contrasena.component.html',
  styleUrls: ['./contrasena.component.scss']
})


export class ContrasenaComponent implements OnInit, OnDestroy {

  title: any[] = ['Contraseña'];
  subtitle: any[] = [];
  userId: number;
  sessionToken: string;
  componentSubscr: Subscription;
  passwordChange: FormGroup;
  nPassword: string;
  oPassword: string;
  error: any;
  passwordMatcher = new RepeatPasswordEStateMatcher();
  change: any;
  hide = true;
  listCharacter: any = ['Debe tener al menos 6 caracteres de largo', 'Al menos 1 mayúscula', 'Al menos 1 carácter especial', 'No debe poseer ningún espacio', 'Solo se aceptan los siguientes caracteres: @ . - _ *'];

  constructor(private router: Router,
              public activate: ActivatedRoute,
              public clientService: ClientService,
              private formBuilder: FormBuilder,
              private snackBarService: SnackbarService) {

    this.passwordChange = this.formBuilder.group({
      oldPassword: new FormControl('', Validators.required),
      newPassword: new FormControl('', [
        Validators.required,
        Validators.pattern('^(?=.*\\\d)(?=.*[\\\u002D-\\\u002E\\\u0040\\\u005F\\\u002A.])(?=.*[A-Z])(?=.*[a-z])\\S{6,}$')
      ]),
      confirmPassword: new FormControl('', Validators.required)
    }, {validator: this.globalFormValidator});

  }

  /**
   * Function to validate requirements of form provided
   * @param form Form to validate
   */
  globalFormValidator(form: FormGroup): { [key: string]: boolean } | null {
    const password = form.get('newPassword');
    const confirmPassword = form.get('confirmPassword');
    let errorObj = {};
    if (password.dirty && confirmPassword.dirty && password.value != confirmPassword.value) {
      errorObj['passwordsNotEqual'] = true;
    }
    return !!Object.keys(errorObj).length ? errorObj : null;
  }


  ngOnInit() {
    this.componentSubscr = this.activate.parent.params.subscribe(parametros => {
      this.userId = JSON.parse(parametros.id);
    });
  }

  ngOnDestroy() {
    if (this.componentSubscr && !this.componentSubscr.closed)
      this.componentSubscr.unsubscribe();
  }

  back() {
    this.router.navigate([`/dashboard/perfil`]);
  }

  async submitForm() {
    this.nPassword = this.passwordChange.value.newPassword;
    this.oPassword = this.passwordChange.value.oldPassword;

    try {
      const response = await this.clientService.changePasswordBySessionToken(this.nPassword, this.oPassword).toPromise();
      //If instance of TutenClient
      if (response){
        this.change = response;
        this.passwordChange.reset();
        this.back();
        this.snackBarService.openSnackBar(MESSAGE_TIMES.SUCCESS_MESSAGE, 'Se cambio la contraseña exitosamente', 'success');
      }
    } catch (err) {
      this.snackBarService.openSnackBar(MESSAGE_TIMES.ERROR_MESSAGE, err.error.error, 'error');
      console.log('ERROR: ', err);
    }
  }


}
