import { Component } from "@angular/core";
import { ClientService } from "src/app/core/api";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/interfaces/app.interface";
import * as notificationTypes from "src/app/actions/notifications.actions";
import { Search } from "../../shared/search/search.component";
import { HttpErrorResponse } from "@angular/common/http";
import { MessageService } from "primeng/api";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.scss"],
})
export class NotificationsComponent {
  search: Search;

  loading: boolean;
  notifications: any[] = [];
  displayZoom = false;

  constructor(
    private clientService: ClientService,
    private store: Store<AppState>,
    private messageService: MessageService
  ) {
    this.search = new Search(() => {
      this.getList();
    }, 4);

    this.getList();

    this.store.select("notifications").subscribe((data) => {
      this.notifications = data.notifications;
    });
  }

  getList(): void {
    this.notifications = null;

    this.clientService.getNotificationsBySearch(this.search).subscribe(
      (response: any) => {
        this.store.dispatch(
          new notificationTypes.SetAllNotificationsActions(response.results)
        );
        this.search.totalRecords = response.total;
      },
      (httpErrorResponse: HttpErrorResponse) => {
        this.notifications = undefined;
      }
    );
  }

  setToReadNotification(notification: any) {
    this.clientService.setReadOneNotification(notification).subscribe(
      (data: any) => {
        if (data) {
          this.store.dispatch(
            new notificationTypes.UpdateNotificationActions(data)
          );
          this.clientService.emitChangeForNotifications(notification);
          if (notification.viewDate) {
            this.store.dispatch(
              new notificationTypes.IncrementNotificationsUnreadActions(data)
            );
            this.messageService.add({
              severity: "success",
              summary: "Éxito",
              detail: "Notificación marcada como no leída.",
            });
          } else {
            this.store.dispatch(
              new notificationTypes.DecrementNotificationsUnreadActions(
                notification
              )
            );
            this.messageService.add({
              severity: "success",
              summary: "Éxito",
              detail: "Notificación marcada como leída.",
            });
          }

          this.getList();
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "No se realizo la acción",
        });
      }
    );
  }
}
