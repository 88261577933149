import * as actionTypes from '../actions/notifications.actions';
import { Notifications } from '../interfaces/notifications.interface';

const initialState: Notifications = {
  unReads: [],
  notifications: []
};

const notificationIndex = (array: any[], notification: any) => array.findIndex(e => e.id === notification.id);

export function notificationsReducer ( state = initialState, action: actionTypes.actions) {
  let newState = Object.assign({}, state);
  switch(action.type){
    case actionTypes.SET_NOTIFICATIONS_UNREADS:
      newState.unReads = action.payload;
      return newState;

    case actionTypes.DECREMENT_UNREADS:
      var index = notificationIndex(newState.unReads, action.payload);
      if(~index) {
        newState.unReads.splice(index, 1);
      }
      return newState;

    case actionTypes.INCREMENT_UNREADS:
      newState.unReads.push(action.payload);
      return newState;

    case actionTypes.RESET_UNREADS:
      newState.unReads = [];
      return newState;

    case actionTypes.SET_ALL_NOTIFICATIONS:
      newState.notifications = action.payload;
      return newState;

    case actionTypes.UPDATE_NOTIFICATION:
      var index = notificationIndex(newState.notifications, action.payload);
      if (~index){
        newState.notifications[index] = action.payload;
      }
      return newState;

    default:
      return newState;
  }
}
