import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatCheckboxChange, MatDialogRef} from '@angular/material';
import { WebsocketService } from 'src/app/websocket.service';
import { ClientService } from "../../../../core/api/client/client.service";
import { SnackbarService } from 'src/app/core/api/snackbar.service';
import {MESSAGE_TIMES} from '../../../shared/constants';

@Component({
  selector: 'app-call-dialog',
  templateUrl: './call-dialog.component.html',
  styleUrls: ['./call-dialog.component.scss']
})

export class CallDialogComponent implements OnInit {

  request: any={"reason": "","fullname": "","phoneNumber": ""};
  changeNumber: boolean = true;
  selectTel: number = 10;

  constructor(private _wsService: WebsocketService,
              public dialogRef: MatDialogRef<CallDialogComponent>,
              private snackBarService: SnackbarService,
              private clientService: ClientService) {
  }

  ngOnInit() {}

  close(): void {
    this.dialogRef.close();
  }

  callRequest() {
    this.clientService.sendCallbackRequest(this.request).subscribe((response: any) => {
      this.dialogRef.close();
      this.snackBarService.openSnackBar(MESSAGE_TIMES.SUCCESS_MESSAGE, 'Se ha enviado tu solicitud de llamada exitosamente', '', 'Exito', false);
      this._wsService.sendMessagePrivate({type:'callback',para:'backoffice',mensaje:'Solicitud de llamada',topic:'topicGeneral'});
    }, err => {
      this.snackBarService.openSnackBar(MESSAGE_TIMES.WARNING_MESSAGE, 'Ha ocurrido un error. Por favor, intentelo mas tarde.', '', 'Advertencia', false);
    });
  }

  showOptions(event:MatCheckboxChange): void {
    this.changeNumber = !this.changeNumber;
    if (this.changeNumber == true) {
      this.selectTel = 10;
    } else {
      this.selectTel = 15;
    }
  }
}
