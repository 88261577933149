import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AxaService } from 'src/app/core/api/axa/axa-service';
import * as actionTypes from 'src/app/actions/app.actions';
import { AppState } from 'src/app/interfaces/app.interface';
import { Router} from '@angular/router';

const services = [
  {
    name: "Nutriólogo",
    img: "assets/images/orientacionNutricionalTelefono.jpg",
     schedule: "Lunes a viernes 9 a 17 hrs."
  },
  {
    name: "Psicólogo",
    img: "assets/images/orientacionPsicologicaTelefono.jpg",
    schedule: "Lunes a viernes 9 a 17 hrs."
  }, {
    name: "Orientación Médica",
    img: "assets/images/orientacionMedicaTelefono.jpg",
    schedule: "24/7"
  }];


@Component({
  selector: 'app-telemedicine-dialog',
  templateUrl: './telemedicine-dialog.component.html',
  styleUrls: ['./telemedicine-dialog.component.scss']
})

export class TelemedicineDialogComponent implements OnInit {
  @Input() display2;
  @Output('closeModalTelemedicine') closeOutput = new EventEmitter();
  services = services;

  constructor(              
    private router: Router,
    public axaService: AxaService,
    private store: Store<AppState>
    ) {
    
  }

  ngOnInit() {
  }

  onClose() {
    this.closeOutput.emit(false);
  }

  goToAxa() {
    this.store.dispatch(new actionTypes.OpenLoadingActions());
    let data = {
      name: localStorage.getItem('nameuser'),
      lastName: localStorage.getItem('apellidouser'),
      email: localStorage.getItem('emailuser'),
      cellphone: '52' + localStorage.getItem('telefonouser')
     //cellphone: '521234567872'
    };

    this.axaService.connectAxaFrame(data).subscribe((response: any) => {
      this.store.dispatch(new actionTypes.CloseLoadingActions());
      this.router.navigate(['/dashboard/axa'], {state: { axaUrl: response.urlIntegrationTelemedicine } })
    }, (err: any) => {
      this.store.dispatch(new actionTypes.CloseLoadingActions());
      this.router.navigate(['/dashboard/axa'], {state: { error: true }})
    });
  }
}
