import { Component, OnInit, OnChanges } from '@angular/core';
import {ClientService} from '../../../../core/api/client/client.service';
import {SnackbarService} from '../../../../core/api/snackbar.service';
import {MESSAGE_TIMES} from '../../../shared/constants';

@Component({
  selector: 'app-side-notifications',
  templateUrl: './side-notifications.component.html',
  styleUrls: ['./side-notifications.component.scss']
})
export class SideNotificationsComponent implements OnInit, OnChanges {

  notifications: any [] = [];
  progress: any;
  loadingNews: boolean = false;

  news: any[] = [];

  customOptions: any = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    autoplay: true,
    autoplayHoverPause: true ,
    //navText: ['<i class=material-icons>arrow_left</i>', '<i class=material-icons>arrow_right</i>'],
    responsive: {
      0: {
        items: 1
      },
      // 400: {
      //   items: 2
      // },
      // 740: {
      //   items: 3
      // },
      // 940: {
      //   items: 4
      // }
    },
    nav: false
  };

  displayAllNotifications: boolean = false;


  constructor(private clientService: ClientService,
              private snackBarService: SnackbarService) {
  }

  ngOnInit() {
    this.getUnreadNotifications();
    this.getActiveNews(1, 3);
  }

  ngOnChanges() {
    this.getUnreadNotifications();
  }

  selectToReadNotification(noti: any): void {
    this.clientService.setReadOneNotification(noti).subscribe((data: any) => {
      if(data) {
        this.notifications = this.notifications.filter(x => x !== noti);
        this.snackBarService.openSnackBar(MESSAGE_TIMES.SUCCESS_MESSAGE, 'Mensaje marcado como leido', 'success');
      }
    }, error => {
      this.snackBarService.openSnackBar(MESSAGE_TIMES.ERROR_MESSAGE, 'Mensaje no pudo ser marcado como leído', 'error');
    });

  }

  getUnreadNotifications(): void {
    this.clientService.getUnreadNotifications().subscribe((data: any) => {
      if(data){
        this.notifications = data;
      }
    }, error => {
      this.snackBarService.openSnackBar(MESSAGE_TIMES.ERROR_MESSAGE, 'No se recuperaron las notificaciones', 'error');
    });
  }

  setReadAllNotifications(): void {
    this.clientService.setReadAllNotifications().subscribe((data: any) => {
      if(data) {
        this.notifications = [];
        this.snackBarService.openSnackBar(MESSAGE_TIMES.SUCCESS_MESSAGE, 'Todos los mensajes marcados como leido', 'success');
      }
    }, error => {
      this.snackBarService.openSnackBar(MESSAGE_TIMES.ERROR_MESSAGE, 'No se realizo la acción', 'error');
    });
  }

  getActiveNews(page?: number, pageSize?: number) : void {
    this.loadingNews = true;
    this.clientService.getActiveClientNews(page, pageSize).subscribe((response: any) => {
      if(response != null) {
        this.news = response.results;
        //this.news = response;
        this.news.forEach(x => {
          x.contentNews = decodeURI(x.contentNews);
          if(x.imgs != null) {
            x.firstImg = x.imgs[0];
            if(x.firstImg == null || x.firstImg == undefined) {
              x.firstImg = 'https://www.noticiasaldiayalahora.co/wp-content/uploads/2017/09/Noticiasaldiayalahorashare.png';
            }
          } else {
            x.firstImg = 'https://www.noticiasaldiayalahora.co/wp-content/uploads/2017/09/Noticiasaldiayalahorashare.png';
          }
        });
      } else {
        this.snackBarService.openSnackBar(MESSAGE_TIMES.WARNING_MESSAGE, 'No se pudo cargar las noticias', 'warn');
      }
      this.loadingNews = false;
    },error => {
      this.snackBarService.openSnackBar(MESSAGE_TIMES.ERROR_MESSAGE, 'Error al cargar las noticias', 'error');
      this.loadingNews = false;
    });

    //this.progresBarService.emitBooleanObjects(this.loadingNews);
  }

  truncateHTML(text: string): string {
    let charlimit = 50;
    if(!text || text.length <= charlimit ) {
      return text;
    }
    let adding_spaces = text.replace(/<br \/>/g, "&nbsp;");

    let without_html = adding_spaces.replace(/<(?:.|\n)*?>/gm, '');
    let shortened = without_html.substring(0, charlimit) + "...";
    return shortened;
  }

}
