import { map } from 'rxjs/operators';
import { TutenClientCoverageResponse } from './plans.interfaces';

export function _mapTutenClientCoverage() {
  return map((res:any) => {
    const { coverageActive, lastPeriodicityEndDate } = res;
    const coverageResponse: TutenClientCoverageResponse = {
      coverageActive,
      lastPeriodicityEndDate
    }
    return coverageResponse;
  })
}