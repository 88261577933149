import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-guia-rapida',
  templateUrl: './guia-rapida.component.html',
  styleUrls: ['./guia-rapida.component.scss']
})
export class GuiaRapidaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
