import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {ClientService} from 'src/app/core/api';
import {TutenClientProfileData} from '../../../core/api/client/client-interfaces';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

  title: any[] = ['Información Personal'];
  subtitle: any[] = ['Aquí puedes editar tus datos personales'];
  profileForm: FormGroup;
  loading = true;

  profile: any = {
    id: '',
    primer_nombre: '',
    segundo_nombre: '',
    primer_apelliodo: '',
    segundo_apellido: '',
    sexo: '',
    telefono: '',
    email: '',
    contrasena: '',
    interlocutor: '',
    contrato: ''
  };


  userId: any;
  componentsSubscr: Subscription;
  data: any;
  name: any;

  profileEdit: TutenClientProfileData;
  editNames = false;
  editLasts = false;
  editPhones = false;

  constructor(private router: Router,
    private activate: ActivatedRoute,
    private clientService: ClientService,
    private messageService: MessageService) {

    this.profileForm = new FormGroup({
      'nombre': new FormControl(''),
      'apellido': new FormControl(''),
      'telefono': new FormControl('',
        // Validators.pattern('/^[1-9]\d{6,10}$/'),
        Validators.compose([
          Validators.minLength(9),
          Validators.maxLength(10)
        ])
      )
    });

    this.getProfile();
  }

  ngOnInit() {

    this.componentsSubscr = this.activate.parent.params.subscribe(parametros => {
      this.userId = parametros.id;
    });

  }

  ngOnDestroy() {
    if (this.componentsSubscr && !this.componentsSubscr.closed)
      this.componentsSubscr.unsubscribe();
  }

  contrasena() {
    this.router.navigate([`/dashboard/password`]);
  }

  downUser() {
    this.router.navigate([`/dashboard/down`]);
  }

  getProfile() {
      this.clientService.getClientProfile().subscribe(response => {
        let dato: any = response;
        this.profile = {
          id: dato.id,
          primer_nombre: dato.firstName,
          segundo_nombre: '',
          primer_apelliodo: dato.lastname,
          segundo_apellido: '',
          sexo: 'Femenino',
          telefono: dato.numberPhone,
          email: Boolean(localStorage.getItem('emailuser')) ? localStorage.getItem('emailuser') : 'DEBE ESPECIFICAR EMAIL',
          contrasena: '',
          interlocutor: "",
          contrato: ""
        };

      });
      this.loading = false;

  }

  editName() {
    this.editNames = !this.editNames;
    this.profileForm.get('nombre').setValue(this.profile.primer_nombre);
  }
  cancelEditNames() {
    this.editNames = !this.editNames;
    this.profileForm.controls['nombre'].reset();
  }

  editLast() {
    this.editLasts = !this.editLasts;
    this.profileForm.get('apellido').setValue(this.profile.primer_apelliodo);
  }
  cancelEditlasts() {
    this.editLasts = !this.editLasts;
    this.profileForm.controls['apellido'].reset();
  }

  editPhone() {
    this.editPhones = !this.editPhones;
    this.profileForm.get('telefono').setValue(this.profile.telefono);
  }
  cancelEditPhones() {
    this.editPhones = !this.editPhones;
    this.profileForm.controls['telefono'].reset();
  }

  disabledSave() {
    // if (this.profileForm.controls["telefono"].length)
  }

  async enviar() {
    // this.loading = true;
    var phone = '' + this.profileForm.value.telefono;
    console.log("this.profileForm.value.telefono: ", this.profileForm.value.telefono);

    if (this.profileForm.value.telefono && phone.length < 10) {
      this.messageService.add({severity: 'error', summary:'Error', detail:'Número de teléfono invalido'});
    }

    this.profileEdit = {
      authToken: "",
      email: "",
      firstName: this.profileForm.value.nombre,
      lastName: this.profileForm.value.apellido,
      name: "",
      photoUrl: "",
      provider: "",
      id: "",
      idToken: "",
      phone: this.profileForm.value.telefono,
      engie: true,
      tutenClientsIds: [],
      password: ""
    };
    try {
      const response = await this.clientService.putClientInfoProfile(this.profileEdit).toPromise();
      if (response) {
        this.router.navigateByUrl('/perfil', { skipLocationChange: true }).then(() =>
          this.router.navigate([`dashboard/${this.userId}/perfil`]));
        localStorage.setItem('userName', response['name']);
        localStorage.setItem('nameuser', response['firstName']);
        this.messageService.add({severity: 'success', summary:'Éxito', detail:'Edición realizada con éxito'});
        this.loading = false;
      }
    } catch (e) {
      this.messageService.add({severity: 'error', summary:'Error', detail: e.error.error});
      console.log('ERROR: ', e);
    }
  }

}

