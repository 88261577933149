import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from "@angular/common";
import { SnackbarService } from 'src/app/core/api/snackbar.service';
import { BookingsService } from 'src/app/core/api/bookings/bookings.service';
import { ProgressBarService } from 'src/app/core/api/progress-bar.service';
import { MatTableDataSource, MatSort, Sort, PageEvent } from '@angular/material';
import { FormGroup, FormControl } from '@angular/forms';
import { ClientService } from 'src/app/core/api';
import {MESSAGE_TIMES} from '../../../shared/constants';

@Component({
  selector: 'app-services-budget-to-approve',
  templateUrl: './services-budget-to-approve.component.html',
  styleUrls: ['./services-budget-to-approve.component.scss']
})
export class ServicesBudgetToApproveComponent implements OnInit {

  userId: number;
  title: any[] = ['Asistencias'];
  subtitle: any[] = ['Lista de Asistencias con Presupuesto por Aprobar'];
  isLoading: any = {
    bookingsLoading: true
  };

  dataAvailibity: any[] = [];
  groupsBookings: any[] = [];
  services: any[] = [];

  startDate: Date;
  endDate: Date;

  pageEvent: PageEvent = new PageEvent;

  dataSource: MatTableDataSource<any>;

  @ViewChild(MatSort)sort: MatSort;
  bookings: any[] = [];
  bookingsFilter: any[] = [];
  loading = true;

  bookingsParams: any = {
    pagination: {
      page: 1,
      pagesize: 10,
      intervals: [5, 10, 25, 50, 100]
    },
    filters: {
      caseId: undefined,
      fromDateSolicitud: undefined,
      toDateSolicitud: undefined,
      serviceDefinition: [],
      state: [],
      id: undefined
    },
    total: 0
  };
  tabla: FormGroup;

  displayedColumns = ['idBooking', 'date', 'grupo', 'tipo', 'state', 'icon'];

  show: boolean = false;

  unmodifiedServices: any;

  constructor(
    private activate: ActivatedRoute,
    public router: Router,
    private _location: Location,
    private snackBarService: SnackbarService,
    private bookingsService: BookingsService,
    private progresBarService: ProgressBarService,
    private clientService: ClientService
  ) {
    this.activate.parent.params.subscribe(parametros => {
      this.userId = parametros.id;
    });
    this.tabla = new FormGroup({
      'idBooking': new FormControl(''),
      'fechaInicio': new FormControl(''),
      'fechaFin': new FormControl(''),
      'services': new FormControl('')
    });

    this.pageEvent.pageSize = this.bookingsParams.pagination.pagesize;
    this.pageEvent.pageIndex = 0;
  }

  ngOnInit() {
    this.getServices();
  }

  // esto carga as asistencias del dropdown de busqueda
  getServices() {
    this.bookingsService.getServicesDefinition().subscribe((response: any) => {
      if (response != null) {
        this.unmodifiedServices = response;
        //this.getGroups();
      } else {
        this.snackBarService.openSnackBar(MESSAGE_TIMES.ERROR_MESSAGE, 'Sin obtención de registros de Asistencias', 'warn');
      }
    }, error => {
      this.snackBarService.openSnackBar(MESSAGE_TIMES.ERROR_MESSAGE, 'No se pueden obtener las Asistencias', 'error');
    }, () => {
      this.getGroups();

    });
  }

  // esto no lo necesito, es para filtrar local - GRUPO DE ASISTENCIA
  getGroups() {
    this.bookingsService.getServicesGroups().subscribe((response: any) => {

      if (response != null) {
        this.groupsBookings = response;
      } else {
        this.snackBarService.openSnackBar(MESSAGE_TIMES.ERROR_MESSAGE, 'Sin obtención de registros de grupos', 'warn');
      }
    }, error => {
      this.snackBarService.openSnackBar(MESSAGE_TIMES.ERROR_MESSAGE, 'No se pueden obtener los grupos', 'error');
    }, () => {
      this.getAvailabilityCoveragesBooking();
    });
  }
  // esto tampoco se necesita, es para filtrar local - TIPO DE ASISTENCIA
  getAvailabilityCoveragesBooking() {
    this.clientService.getAvailabilityCoverages().subscribe((response: any) => {

      if (response !== undefined) {
        this.dataAvailibity = response;
        //this.searchBookings();
      } else {
        this.snackBarService.openSnackBar(MESSAGE_TIMES.ERROR_MESSAGE, 'Sin obtención de registros coberturas', 'warn');
      }
    }, error => {
      this.snackBarService.openSnackBar(MESSAGE_TIMES.ERROR_MESSAGE, 'No se pueden obtener los registros de coberturas', 'error');
    }, () => {
      this.getBookingsBugetForApprove();
    });
  }


  // este si se necesita, es el que coloca la data en la tabla
  getBookingsBugetForApprove() {
    this.bookingsService.getBookingsBudgetForApprove(this.bookingsParams).subscribe(
      (response : any) => {
        if(response != null){
          this.bookings = response.results;
          this.bookingsFilter = this.bookings;
          this.bookingsParams.total = this.bookings.length;
          this.searchBookings(this.bookingsFilter);
        } else {
          this.snackBarService.openSnackBar(MESSAGE_TIMES.ERROR_MESSAGE, 'Sin obtención de registros', 'warn');
        }
      }, error => {
        this.snackBarService.openSnackBar(MESSAGE_TIMES.ERROR_MESSAGE, 'Problemas de servidor', 'error');
      }
    );
  }

  searchBookings(bookings: any[]): void {
    const localService = bookings.map(el => {
      const service = this.dataAvailibity.find(({ id }) => id == el.serviceId);
      return ({
        ...el,
        serviceName: service ? service.name : 'N/A',
        groupId: service ? service.groupId : 'N/A'
      })
    });

    const localGroup = localService.map(el => {
      const group = this.groupsBookings.find(({ id }) => id == el.groupId);
      return ({
        ...el,
        groupName: group ? group.name : 'N/A'
      })
    });

    this.services = localGroup.map(el => {
      const { id: idBooking, caseId: idSolicitud, creationDate: date, serviceName: tipo, stateBookingName: state, groupName: grupo } = el;
      return { idBooking, idSolicitud, date, grupo, tipo, state, icon: 'keyboard_arrow_right' };
    });

    this.services = this.services.sort((a,b) => {
      return this.getTime(new Date(a.date)) - this.getTime(new Date(b.date));
    });

    this.services = this.services.slice();

    this.dataSource = new MatTableDataSource(this.services);
    this.dataSource.sort = this.sort;

    // Parametros para el progressBar
    this.isLoading.bookingsLoading = false;
    this.progresBarService.emitBooleanObjects(this.isLoading);

    this.loading = false;
  }

  getTime(date?: Date):number{
    return date != null ? date.getTime() : 0;
  }
  // muestra la barra de filtrado
  showFilter() {
    this.show = !this.show;
  }

  compareFunc(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  // esta funcion deberia de llevar al detalle del presupuesto por pagar
  assit(idS: number) {
    //this.router.navigate(['./' + idS],{relativeTo: this.activate})
    this.router.navigate([`dashboard/${this.userId}/asistencia`, idS]);
    this.progresBarService.setCountTipe('2');
  }

  backClicked() {
    this._location.back();
  }

  applyFilter() {
    if (!(this.tabla.controls.fechaFin.value == "" || this.tabla.controls.fechaFin.value == null)) {
      let filterDate = new Date(this.endDate);
      filterDate.setHours(23,59,59);
      this.bookingsParams.filters.fechaFin = filterDate;
     }
     if (!(this.tabla.controls.fechaInicio.value == "" || this.tabla.controls.fechaInicio.value == null)) {
      this.bookingsParams.filters.fechaInicio = this.getTime(new Date(this.getTime(new Date(this.startDate))));
     }

    if (!(this.tabla.controls.services.value == "" || this.tabla.controls.services.value == null)){
        this.bookingsParams.filters.services = this.tabla.controls.services.value
    }
    this.getBookingsBugetForApprove();
  }

  clean() {
    this.bookingsFilter = this.bookings;
    this.tabla.controls['idBooking'].reset();
    this.tabla.controls['fechaInicio'].reset();
    this.tabla.controls['fechaFin'].reset();
    this.tabla.controls['services'].reset();
    this.bookingsParams.filters.caseId = undefined;
    this.bookingsParams.filters.idBooking = undefined;
    this.bookingsParams.filters.fechaInicio = undefined;
    this.bookingsParams.filters.fechaFin = undefined;
    this.bookingsParams.filters.services = [];
    this.bookingsParams.filters.state= [];

    this.searchBookings(this.bookingsFilter);
  }

}
