import { Component } from '@angular/core';
import { NavigationEnd, Router} from "@angular/router";
import { ClientService } from 'src/app/core/api';
import { MatDialog } from '@angular/material';
import { AppState } from 'src/app/interfaces/app.interface';
import { Store } from '@ngrx/store';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  name: string = localStorage.getItem('nameuser') != null && localStorage.getItem('apellidouser') != null ? localStorage.getItem('nameuser') + ' ' + localStorage.getItem('apellidouser') : '';
  title: any[] = [name];

  subtitle: any[] = ['Aquí puedes gestionar todos tus asistencias contratadas'];

  notifications: any [] = [];
  progress: any;

  news: any[] = [];
  loadingNews: boolean = false;

  public href: string = "";
  display: boolean;
  displayS: boolean;
  dashboard: boolean;
  displayPass: boolean;
  viewCallback: boolean;
  name_user: String;
  sla: any;

  displayAllNotifications: boolean = false;

  isLogged: boolean = true;

  constructor(
              public router: Router,
              public dialog: MatDialog,
              private clientService: ClientService,
              private store: Store<AppState>
              )
  {

    this.store.select('app').subscribe(data => {
      this.isLogged = data.isLogged;
    });

    this.viewCallback = false;

    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.href = this.router.url;
        let respHref = (this.href.split('/'));
        this.displayS = respHref[3] == 'asistencia' ||
          respHref[3] == 'reagendar' ||
          (respHref[3] == 'asistencias' && respHref[4] == 'encuesta-incompleta') ||
          (respHref[3] == 'asistencias' && respHref[4] == 'presupuesto-por-aprobar') ||
          (respHref[3] == 'asistencias' && respHref[4] == 'presupuesto-por-pagar') ||
          respHref[3] == 'disponibilidad-detalles';
        this.display = respHref[3] == 'resumen';
        this.displayPass = respHref[3] == 'password' || respHref[3] == 'reagendar';
      }
    });


    /*** https://stackoverflow.com/questions/37662456/angular-2-output-from-router-outlet */
    this.clientService.changeEmittedNotification$.subscribe((data: any) => {
      if(data) {
        this.notifications = this.notifications.filter(x => x.id !== data.id);
      }
    });
  }
}
