import { Routes } from "@angular/router";
import {ResumenComponent} from "./resumen/resumen.component";
import {ServicesComponent} from "./services/services.component";
import {ProfileComponent} from "./profile/profile.component";
import {ContrasenaComponent} from "./contrasena/contrasena.component";
import {AssistanceComponent} from "./assistance/assistance.component";
import { NotificationsComponent } from './notifications/notifications.component';
import {PollComponent} from "./assistance/poll/poll.component";
import {HelpRequestComponent} from "./help-request/help-request.component";
import { NoticiasComponent } from './noticias/noticias.component';
import { NewsComponent } from './noticias/news/news.component';
import { AssistPollIncompleteComponent } from './services/assist-poll-incomplete/assist-poll-incomplete.component';
import { ServicesBudgetForPayComponent } from './services/services-budget-for-pay/services-budget-for-pay.component';
import { ServicesBudgetToApproveComponent } from './services/services-budget-to-approve/services-budget-to-approve.component';
import {PlansComponent} from './plans/plans.component';
import {AvailabilityComponent} from './plans/availability/availability.component';
import { DownuserComponent } from './downuser/downuser.component';
import { ChatComponent } from './assistance/chat/chat.component';
import { Servi2AccountsComponent } from "../servi2-accounts/servi2-accounts.component";
import { Servi2AccountDetailsComponent } from "../servi2-account-details/servi2-account-details.component";
import { SolicitudComponent } from "../solicitud/solicitud.component";
import { ExitoComponent } from "../solicitud/exito/exito.component";
import { AxaComponent } from "../solicitud/axa/axa.component";

export const DASHBOARD_ROUTES: Routes = [
  {path: 'resumen', component: ResumenComponent},
  {path: 'exito', component: ExitoComponent},
  {path: 'plan-contratado', component: PlansComponent},
  {path: 'disponibilidad-detalles', component: AvailabilityComponent},
  {path: 'asistencias', component: ServicesComponent},
  {path: 'perfil', component: ProfileComponent},
  {path: 'noticias', component: NoticiasComponent},
  {path: 'solicitud', component: SolicitudComponent},
  {path: 'axa', component: AxaComponent},
  {path: 'noticias/:newsId', component: NewsComponent },
  {path: 'password', component: ContrasenaComponent},
  {path: 'down', component: DownuserComponent},
  {path: 'cuentas', component: Servi2AccountsComponent},
  {path: 'cuentas/:id', component: Servi2AccountDetailsComponent},
  {path: 'notificaciones', component: NotificationsComponent},
  {path: 'asistencia/:serviceId', component: AssistanceComponent},
  {path: 'disponibilidad/:serviceId', component: AssistanceComponent},
  {path: 'asistencia/:serviceId/encuesta', component: PollComponent},
  {path: 'asistencia/:serviceId/help-request', component: HelpRequestComponent},
  { path: 'asistencia/:serviceId/chat', component: ChatComponent },
  {path: 'asistencias/encuesta-incompleta', component: AssistPollIncompleteComponent},
  {path: 'asistencias/presupuesto-por-pagar', component: ServicesBudgetForPayComponent},
  {path: 'asistencias/presupuesto-por-aprobar', component: ServicesBudgetToApproveComponent},
  {path: '**', pathMatch: 'full', redirectTo: 'resumen'},
];
