import { Component, OnInit } from '@angular/core';
import { ClientService } from 'src/app/core/api';
import { Router, ActivatedRoute } from '@angular/router';
import { SnackbarService } from 'src/app/core/api/snackbar.service';
import { ProgressBarService } from 'src/app/core/api/progress-bar.service';
import {MESSAGE_TIMES} from '../../../shared/constants';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  userId : string;
  news: any = new Object;
    /*{id:1,
      title: 'TITULO # 1',
      descripcion : 'Lorem ipsum dolor sit amet, vel imperdiet quis ut natoque diam volutpat, in ac massa. Morbi sed ut eu, justo placerat, auctor nec amet ante porta proin viverra, mollis ligula, sit sed nunc mauris odio ultricies. Donec vel eget a nulla, porttitor in. Sodales enim suspendisse dolor. Et excepteur posuere, donec eget arcu tempus platea eros, consectetuer purus in nullam viverra per, vitae vestibulum ultricies. Urna potenti tristique phasellus integer augue, lectus quisque id magna egestas, nulla ut. Leo elit arcu, commodo ultrices etiam diam. Et hac, sed ac blandit eget urna. Metus tellus dolor ipsum mattis beatae dignissim. Sit ante ante vehicula nascetur, in at ut, ultricies viverra. Wisi amet sed rutrum pellentesque imperdiet. Gravida aliquam libero phasellus ipsum sed ac, orci semper mollis ac rhoncus nibh non.   Ut quis, accumsan scelerisque diam luctus purus sed, pulvinar ligula sem lacus fringilla, vel erat pede justo morbi magna. In vel fusce voluptas mauris, sed sit pharetra, malesuada et, lorem inceptos mattis sit, semper nulla in. In pellentesque, non dignissim iaculis nec sem, rutrum vitae penatibus amet rhoncus, et luctus proin et, esse non wisi magna vestibulum. Imperdiet laoreet, facilisis nec dolor lacus metus integer risus, ut quis, dictum volutpat eu. Sed nibh, lacus nec dolor duis justo, quam per egestas wisi sed a, tincidunt hendrerit, dui tortor quisque. Vivamus viverra donec massa, arcu wisi, gravida vestibulum orci. At a elit massa wisi ante pretium, vestibulum vel turpis vel porta, libero diam eu. Metus ridiculus. Ad quam bibendum phasellus, nonummy id congue orci nibh lacus dis, erat tristique. Lorem natoque vivamus ligula urna eros.',
      img : 'https://www.noticiasaldiayalahora.co/wp-content/uploads/2017/09/Noticiasaldiayalahorashare.png'}*/

  loading: boolean = false;

  imgAux: any = {
    id: 0,
    img: 'https://www.noticiasaldiayalahora.co/wp-content/uploads/2017/09/Noticiasaldiayalahorashare.png'
  };

  customOptions: any = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    autoplay: true,
    autoplayHoverPause: true,
    //navText: ['<i class=material-icons>arrow_back_ios</i>', '<i class=material-icons>arrow_forward_ios</i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      700: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: false,
    autoHeight: true,
    autoWidth: true,
  };

  constructor(public router: Router,
              private activate: ActivatedRoute,
              private clientService: ClientService,
              private snackbarService: SnackbarService,
              private progressBarService: ProgressBarService) {
    //this.userId = '1'
    this.activate.parent.params.subscribe(params => {
      this.userId = params.id;
    });
  }

  ngOnInit() {
    this.activate.params.subscribe(params => {
      this.news.id = params.newsId;
      this.getNewsById(this.news.id);
    });
  }

  getNewsById(newsId: number) : void {
    this.loading = true;
    this.clientService.getClientNews(newsId).subscribe((response: any) => {
      if(response != null) {
        this.news = response;
        this.news.contentNews = decodeURI(this.news.contentNews);
        this.news.showImgs = [];
        if(this.news.imgs != null) {
          this.news.showImgs = this.news.imgs;
          /*this.news.showImgs = this.news.imgs.slice(1);
          console.log(this.news.showImgs.length);
          if(this.news.showImgs.length < 1 || this.news.showImgs == null || this.news.showImgs == undefined){
            console.log(this.news.showImgs.length);
            this.news.showImgs = this.news.imgs;
            this.news.showImgs.push(this.imgAux);
          }*/
        } else{
          this.news.showImgs.push(this.imgAux);
        }
      } else {
        this.snackbarService.openSnackBar(MESSAGE_TIMES.ERROR_MESSAGE, 'No se pudo obtener la noticia', 'warn');
      }
      this.loading = false;
    }, error => {
      this.snackbarService.openSnackBar(MESSAGE_TIMES.ERROR_MESSAGE, 'Error al obtener la noticia', 'error');
      this.loading = false;
    });
  }



}
