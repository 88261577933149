import { ExceedDialogComponent } from './exceed-dialog/exceed-dialog.component';
import { BaseDialogComponent } from '../shared/base-dialog/base-dialog.component';
import { Component, OnInit, ViewChild, OnDestroy, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatHorizontalStepper } from '@angular/material';
import { DialogComponent } from './dialog.component';
import { AudioDialogComponent } from '../shared/audio-dialog/audio-dialog.component';
import { BookingsService } from '../../core/api/bookings/bookings.service';
import { CalendarioComponent } from '../shared/calendario/calendario.component';
import { Subscription } from 'rxjs';
import { ClientService } from "../../core/api/client/client.service"
import { ReminderDialogComponent } from '../shared/reminder-dialog/reminder-dialog.component';
import { Router } from '@angular/router';
import { MouseEvent, MapsAPILoader } from '@agm/core';
import { marker } from '../interfaces/marker';
import * as moment from 'moment';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { DialogService, MessageService } from 'primeng/api';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/interfaces/app.interface';
import * as actionTypes from 'src/app/actions/app.actions';
import * as _ from 'lodash';
import { mapImageService } from 'src/app/contants/app.contants';
import { PlansService } from 'src/app/core/api/plans/plans.service';
import { AxaService } from 'src/app/core/api/axa/axa-service';

export interface select {
  value: string;
}

export interface problem {
  value: string;
  viewValue: string;
}

export interface agenda {
  value: StateEnum;
  viewValue: string;
}

const filesFormat = {
  image: ['.jpg', '.png', '.jpeg'],
  video: ['.avi', '.mp4', '.mpa', '.mpe', '.mp4', '.mpeg', '.mpg'],
  document: ['.pdf', '.text', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx']
}

const filesSize = {
  image: 20,
  video: 300,
  document: 20
}

const dialogHeader = {
  image: 'Cargar una imagen',
  video: 'Cargar un video',
  document: 'Cargar un documento'
}

const telemedicineGroup = [{
  name: "Asistencia telefónica",
  picture: "assets/images/orientacionPsicologicaTelefono.jpg"
  }, {
  name: "Telemedicina",
  picture: "assets/images/orientacionMedicaTelefono.jpg"
  }
];

declare const google: any;
@Component({
  selector: 'app-solicitud',
  templateUrl: './solicitud.component.html',
  styleUrls: ['./solicitud.component.scss']
})
export class SolicitudComponent implements OnInit, OnDestroy {
  @ViewChild(CalendarioComponent) calendar: CalendarioComponent;
  @ViewChild('stepper') stepper: MatHorizontalStepper;
  // Zoom del google Maps
  zoom: number = 15;

  // Posicion Inicial del Mapa
  lat = 19.413957576622426;
  lng = -99.15108269687744;

  markers: marker = { lat: 19.413957576622426, lng: -99.15108269687744, adress: null, draggable: null };
  stateInvalid = false;
  scheduledAppointment = false;

  invalidAddress = false;

  mapRangesBusyByProfessional: any = {};
  allProfessionalsRange: any = [];
  rangesServices: any[] = [];
  professionalsId: number[] = [];
  busyRangesForAll: any[] = [];
  promotionsComponent;
  type;

  request = {
    bookingId: undefined,
    definitionId: undefined,
    problem: undefined,
    category: undefined,
    address: undefined,
    addressComplement: undefined,
    contactName: undefined,
    contactPhone: undefined,
    email: undefined,
    latitude: undefined,
    longitude: undefined,
    stateId: undefined,
    stateName: undefined,
    timezoneMin: undefined,
    professionalId: undefined,
    transferId: undefined,
    productId: undefined,
    bookingInitDate: undefined,
    bookingEndDate: undefined,
    addressBetweenStreet: undefined,
    addressBetweenStreetAndStreet: undefined,
  };
  problemFormGroup: FormGroup;
  contactFormGroup: FormGroup;
  addressFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  StateEnum = StateEnum;
  selected;
  opcion = 'Escoge';
  selecto;
  view = 1;
  services: any;
  service: String;
  tooltip: any;
  groups: any = [];
  available: any;
  idGroup: any;
  serviceGroup: any = [];
  private geocoder: any;
  name: string = localStorage.getItem('nameuser') != null && localStorage.getItem('apellidouser') != null ? localStorage.getItem('nameuser') + ' ' + localStorage.getItem('apellidouser') : '';
  email: string = localStorage.getItem('emailuser') != null ? localStorage.getItem('emailuser') : '';
  phone: string = localStorage.getItem('telefonouser') != null ? localStorage.getItem('telefonouser') : '';

  bookingId: number;
  files: any[] = [];
  audios: any[] = [];
  videos: any[] = [];
  documents: any[] = [];
  images: any[] = [];
  grupo: any;
  directions: string;
  direcciones: string[] = ['Direccion 1', 'Direccion 2', 'Direccion 3'];
  agendas: agenda[] = [
    { value: StateEnum.INMEDIATO, viewValue: 'Inmediato' },
    { value: StateEnum.AGENDAR, viewValue: 'Agendar Visita' }
  ];
  dialogSubcription: Subscription;
  professionalId: number;
  tipos: Array<any>;
  surverysSubscription: Subscription;
  unfinishedSurveys: any;
  formsSubscription: Subscription = new Subscription();
  requestSubscription: Subscription;
  invalidAddresReasons: string[] = [];
  @ViewChild("autoComplete")
  autoComplete: any;
  userInfo: any;
  defaultUserCoord: any = { lat: null, lng: null };
  invalidDistance: boolean = false;
  selectedIndex: number;
  matchCallCount: number = 0;
  ranges: any[] = [];
  statesAssociatedTransfer: any[] = [];
  pointMap: any;

  currentOffSet : any = 0;

  contactStep: number = 0;
  display: boolean = false;
  display2: boolean = false;
  clientPlan: any;
  clientPlanCovered: any;
  telemedicineGroup = telemedicineGroup;
  selectedService = null;
  firstPlace = "";


  constructor(private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    public bookingService: BookingsService,
    private clientservice: ClientService,
    private router: Router,
    private ngZone: NgZone,
    private mapsAPILoader: MapsAPILoader,
    private messageService: MessageService,
    private store: Store<AppState>,
    public dialogService: DialogService,
    public axaService: AxaService,
    public plans: PlansService) {
      this.currentOffSet = new Date().getTimezoneOffset();
  }

  async getClientPlan() {
    this.clientPlan = await this.plans.getClientPlan().toPromise();
  }

  async planCovered () {
    let response = await this.plans.getClientCoverage().toPromise();
    if(response !=null ) {
      this.clientPlanCovered = response['coverageActive']
    }
  }

  mapGroupImage = {
    Gas: 'assets/images/iconoherramienta-magenta.png',
    Hogar: 'assets/images/iconocasa-magenta.png',
    Medicina: 'assets/images/iconomedicina-magenta.png'
  }

  mapGroupImageSelected = {
    Gas: 'assets/images/iconoherramienta-blanco.png',
    Hogar: 'assets/images/iconocasa-blanco.png',
    Medicina: 'assets/images/iconomedicina-blanco.png'
  }

  ngOnInit() {

    this.setForm();
    this.setFormListeners();
    this.getAvailabilityCoverages();
    this.getUnfinishedSurveys();
    this.getUserData();
    this.startCalendar()
  }
  // pre cargado del calendario
  startCalendar() {
    let model = {
      bookingId: this.bookingId,
      start: moment().format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD')
    };
    this.iniComponentCalendarioParent(model)
  }

  onInitPromotions(e) {
    this.promotionsComponent = e.target;
  }


  ngOnDestroy() {
    this.closeDialogSubscr();
    if (this.surverysSubscription && !this.surverysSubscription.closed)
      this.surverysSubscription.unsubscribe();
    if (this.formsSubscription && !this.formsSubscription.closed)
      this.formsSubscription.unsubscribe();
  }

  setForm(): void {
    this.problemFormGroup = this._formBuilder.group({
      groupInput: ['', Validators.required],
      serviceInput: ['', Validators.required],
      problemInput: ['', Validators.required]
    });

    this.contactFormGroup = this._formBuilder.group({
      nameInput: [localStorage.getItem('nameuser') != null ? localStorage.getItem('nameuser') : '', Validators.required],
      phoneInput: [localStorage.getItem('telefonouser') != null ? localStorage.getItem('telefonouser') : '',[Validators.required, Validators.maxLength(10)]],
      emailInput: [localStorage.getItem('emailuser') != null ? localStorage.getItem('emailuser') : '', [Validators.required, Validators.email]]
    });

    this.addressFormGroup = this._formBuilder.group({
      streetInput: ['', Validators.required],
      exteriorInput: ['', Validators.required],
      colonyInput: ['', Validators.required],
      municipalityInput: ['', Validators.required],
      stateInput: ['', Validators.required],
      zipCodeInput: ['', Validators.required],
      addressSAP: [{ disabled: true }],
      autoComplete: [''],
      addressComplement: [this.request.addressComplement, Validators.required],
      addressBetweenStreet: [this.request.addressBetweenStreet, Validators.required],
      addressBetweenStreetAndStreet: [this.request.addressBetweenStreetAndStreet, Validators.required],
    });

    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['option1', Validators.required]
    });
  }

  setInputListener() {
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(document.getElementById('autoComplete'), {
        types: ["address"],
        componentRestrictions: { country: 'MEX' }
      });

      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            this.invalidAddress = true;
            return;
          }
          if (this.firstPlace !== place.formatted_address) {
            this.firstPlace = place.formatted_address;
            this.validateDistance({lat: place.geometry.location.lat(), lng: place.geometry.location.lng()});
            if (!this.invalidDistance) {
              this.markers.lat = place.geometry.location.lat();
              this.markers.lng = place.geometry.location.lng();
              const { lat, lng } = this.markers;
              this.getUbicationByCoords({ lat, lng });
              
            } else  {
              const { lat, lng } = this.markers;
              this.getUbicationByCoords({ lat, lng });
              this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: 'La dirección no puede exceder los 400 metros a partir de la registrada',
              });
            }
          }
        });
      });
    });
  }

  setFormListeners(): void {
    const groupInputSubs = this.problemFormGroup.get('groupInput').valueChanges.subscribe(response => {
      const group = response;
      this.idGroup = this.groups.filter(function (el) {
        return el.name == group;
      })[0];
      this.getServicesDefinition();
      this.problemFormGroup.get('serviceInput').setValue(null);
      this.scheduledAppointment = false;
      this.type = this.idGroup.newsTypeId;
      setTimeout(() => {
        this.promotionsComponent.reload();
      }, 0);
    });

    const secondOptSubs = this.problemFormGroup.get('serviceInput').valueChanges.subscribe(response => {
      const service = response;
    });

    this.formsSubscription.add(groupInputSubs);
    this.formsSubscription.add(secondOptSubs);

  }

  getRequestIfExists(): void {
    this.store.dispatch(new actionTypes.OpenLoadingActions());
    this.requestSubscription = this.clientservice.confirmRequestIfExist().subscribe((response: any) => {
      if (response !== undefined) {
        if (response == null) {
          this.clientservice.createRequest().subscribe((response: any) => {
            if (response !== undefined) {
              this.bookingId = response.bookingId;
            }
          });
        } else {
          if (response != undefined) {

            this.request = {
              ...response
            };

            if (response.bookingId) {
              this.bookingId = response.bookingId;
            }

            if (response.category) {
              this.problemFormGroup.get('groupInput').setValue(this.findGroup(response.category)[0].name);
            }

            if (response.problem) {
              this.problemFormGroup.get('problemInput').setValue(response.problem);
              this.getBookingFiles(this.bookingId);
            }

            if (response.contactName) {
              this.contactFormGroup.get('nameInput').setValue(response.contactName);
            } else {
              this.contactFormGroup.get('nameInput').setValue(this.name);
            }

            if (response.contactPhone) {
              this.contactFormGroup.get('phoneInput').setValue(response.contactPhone);
            } else {
              this.contactFormGroup.get('phoneInput').setValue(this.phone);
            }

            if (response.email) {
              this.contactFormGroup.get('emailInput').setValue(response.email);
            } else {
              this.contactFormGroup.get('emailInput').setValue(this.email);
            }

            if (response.latitude && response.latitude != 0 && response.longitude && response.longitude != 0) {
              this.lat = response.latitude;
              this.lng = response.longitude;
              this.markers.lat = response.latitude;
              this.markers.lng = response.longitude;
              this.vista(false);
            };

            if (response.addressComplement) {
              this.addressFormGroup.get('addressComplement').setValue(response.addressComplement);
              this.request.addressComplement = response.addressComplement;
            }

            if (response.addressBetweenStreet) {
              this.addressFormGroup.get('addressBetweenStreet').setValue(response.addressBetweenStreet);
              this.request.addressBetweenStreet = response.addressBetweenStreet;
            }

            if (response.addressBetweenStreetAndStreet) {
              this.addressFormGroup.get('addressBetweenStreetAndStreet').setValue(response.addressBetweenStreetAndStreet);
              this.request.addressBetweenStreetAndStreet = response.addressBetweenStreetAndStreet;
            }

          }
        }
        this.store.dispatch(new actionTypes.CloseLoadingActions());
      }
    });
  }

  findGroup(group: String) {
    return this.groups.filter(el => {
      return el.name == group;
    });
  }

  findService(definitionId: any) {
    return this.serviceGroup.filter(el => {
      return el.id == definitionId;
    });
  }

  getServicesDefinition(): void {
    this.store.dispatch(new actionTypes.OpenLoadingActions());
      this.bookingService.getServicesDefinition().subscribe((response: any) => {
      if (response !== undefined) {
        this.services = response;
        let services = [];
        if (this.problemFormGroup.get('groupInput').value.toUpperCase() != 'MEDICINA') {
          for (let index = 0; index < this.available.length; index++) {
            if (this.available[index]['groupId'] == this.idGroup.id) {
              this.services.forEach(element => {
                if (element.id === this.available[index]['id']) {
                  if(this.available[index]['available'] == null ||
                      this.available[index]['available'] > 0 || 
                      this.available[index]["isUnlimited"] == 'ilimitado') {
                    element['allow'] = true;
                  } else {
                    element['allow'] = false;
                  }
                  services.push(element);
                }
              });
            }
          }
        } else {
          this.services = [];
        }
        this.serviceGroup = this.orderServicesGroup(services);
        if (this.request != undefined && this.request.definitionId != undefined) {
          const selectedService = this.findService(this.request.definitionId)[0];
          this.problemFormGroup.get('serviceInput').setValue(selectedService ? selectedService.name : '');
        }
        this.store.dispatch(new actionTypes.CloseLoadingActions());
      }
      
    });
  }

  clickedMarker(label: string, index: number) { }
  getCoordsByAddres(address: string, fromApi: boolean = false): void {
    this.geocoder = new google.maps.Geocoder();
    if (this.geocoder != undefined) {
        this.geocoder.geocode({ address }, (result, status) => {
          if (google != undefined && status === google.maps.GeocoderStatus.OK) {
            const [data] = result;
            //
            this.invalidAddress = false;
            // Formatemos la dirección
            this.formatAddress(data);

            // latitud y longitud segun la dirección
            const lat = data.geometry.location.lat();
            const lng = data.geometry.location.lng();

            this.lat = lat;
            this.lng = lng;
            if (fromApi)
              this.defaultUserCoord = { lat, lng };              

            this.validateDistance({ lat, lng });
          } else {
            this.invalidAddress = true;
          }
          this.getDefaultUserUbication();

        });
      }
  }

  getUbicationByCoords(coordsObj: any) {
      this.geocoder = new google.maps.Geocoder();
      if (this.geocoder != undefined) {
        this.geocoder.geocode({ 'location': coordsObj }, (results, status) => {
          if (google != undefined && status == google.maps.GeocoderStatus.OK) {
            const [address] = results;
            this.formatAddress(address);
            this.lat = coordsObj.lat;
            this.lng = coordsObj.lng;
          }
        });
      }
    // }
  }

  isDefined(variable) { return (typeof (window[variable]) != "undefined"); }

  /**
   * Function to validate distance
   * @param coordsObj LatLng literal Object
   */
  validateDistance(coordsObj: any): void {
    const distance = this.getDistance(coordsObj);
    this.invalidDistance = distance > 400;
  }

  listenToStepChange(event: StepperSelectionEvent) {
    this.selectedIndex = event.selectedIndex;
  }

  /**
   * Function to format addres to use at form
   * @param address
   */

  formatAddress(address: any) {
    if (!address) {
      this.invalidAddress = true;
    }

    for (let i = 0; i < address.address_components.length; i++) {
      if (address.address_components[i].types[0] == "administrative_area_level_1" || address.address_components[i].types[1] == "administrative_area_level_1") {
        this.addressFormGroup.get('stateInput').setValue(address.address_components[i].long_name);
        this.getState(address.address_components[i].long_name);
        this.getTimeZoneMin();
      } else if (address.address_components[i].types[0] == "locality" || address.address_components[i].types[1] == "locality") {
        this.addressFormGroup.get('municipalityInput').setValue(address.address_components[i].long_name);
      } else if (address.address_components[i].types[0] == "sublocality" || address.address_components[i].types[1] == "sublocality") {
        this.addressFormGroup.get('colonyInput').setValue(address.address_components[i].long_name);
      } else if (address.address_components[i].types[0] == "street_number" || address.address_components[i].types[1] == "street_number") {
        this.addressFormGroup.get('exteriorInput').setValue(address.address_components[i].long_name);
      } else if (address.address_components[i].types[0] == "route" || address.address_components[i].types[1] == "route") {
        this.addressFormGroup.get('streetInput').setValue(address.address_components[i].long_name);
      } else if (address.address_components[i].types[0] == "postal_code" || address.address_components[i].types[1] == "postal_code") {
        this.addressFormGroup.get('zipCodeInput').setValue(address.address_components[i].long_name);
      }
    }

    this.invalidAddress = false;

    this.markers.adress = address.formatted_address;
    this.request.address = address.formatted_address;

    this.addressFormGroup.get('autoComplete').setValue(address.formatted_address);
  }

  getState(state: string) {
    this.store.dispatch(new actionTypes.OpenLoadingActions());
    this.bookingService.getStates(state).subscribe(response => {
      if (response != undefined) {
        if (response[0] != undefined && response[0].stateId != undefined && response[0].stateId != null) {
          this.stateInvalid = false;
          this.request.stateId = response[0].stateId;
          this.request.stateName = response[0].stateName;
        } else {
          this.stateInvalid = true;
        }
      }
      this.store.dispatch(new actionTypes.CloseLoadingActions());
    });
  }

  getTimeZoneMin() {
    this.bookingService.getTimeZone(this.lat, this.lng).subscribe(response => {
      if (response != undefined) {
        let dst = response['dstOffset'];
        let raw = response['rawOffset'];
        this.request.timezoneMin = dst == 0 ? (raw / 60) : (raw < 0 ? ((raw + dst) / 60) : ((raw - dst) / 60));
      }
    });
  }

  mapClicked($event: any) {
    this.validateDistance({lat: Number($event.coords.lat.toFixed(7)), lng: Number($event.coords.lng.toFixed(7))});
    if (!this.invalidDistance) {
      this.markers.lat = Number($event.coords.lat.toFixed(7));
      this.markers.lng = Number($event.coords.lng.toFixed(7));
      const { lat, lng } = this.markers;
      this.pointMap = { lat, lng };
      this.getUbicationByCoords({ lat, lng });
    } else  {
      this.invalidDistance = false;
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: 'La dirección no puede exceder los 400 metros a partir de la registrada',
      });
    }
  }

  findMe(myLocation: boolean) {
    if (myLocation) {
      this.deleteMarker();
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.zoom = 15;
          this.markers = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            label: "o",
            adress: "Buscando...",
            draggable: true
          };
          this.getUbicationByCoords({ lat: this.markers.lat, lng: this.markers.lng });
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
        });
      } else {
        alert("Geolocation is not supported by this browser.");
      }
    } else {
      this.markers = {
        lat: this.lat,
        lng: this.lng,
        label: "o",
        adress: "Buscando...",
        draggable: true
      };
      this.getUbicationByCoords({ lat: this.markers.lat, lng: this.markers.lng });
    }
  }

  deleteMarker() {
    this.markers = null;
  }

  getDefaultUserUbication() {
    if (this.userInfo != undefined && this.userInfo != null) {
      // Establecemos la ubicación por defecto del usuario.

      const DEFAULT_ADDRESS = `${this.userInfo.Calle} ${this.userInfo.NumExterior} ${this.userInfo.Colonia} ${this.userInfo.Municipio} ${this.userInfo.Estado}`;

      this.addressFormGroup = this._formBuilder.group({
        streetInput: [this.userInfo.Calle, Validators.required],
        exteriorInput: [this.userInfo.NumExterior, Validators.required],
        colonyInput: [this.userInfo.Colonia, Validators.required],
        municipalityInput: [this.userInfo.Municipio, Validators.required],
        stateInput: [this.userInfo.Estado, Validators.required],
        zipCodeInput: [this.userInfo.CodigoPostal, Validators.required],
        autoComplete: [DEFAULT_ADDRESS, Validators.required],
        addressSAP: [{ value: DEFAULT_ADDRESS, disabled: true }],
        addressComplement: ['', Validators.required],
        addressBetweenStreet: ['', Validators.required],
        addressBetweenStreetAndStreet: ['', Validators.required],
      });

      this.deleteMarker();
      this.autoComplete = `${this.userInfo.Calle} ${this.userInfo.NumExterior} ${this.userInfo.Colonia} ${this.userInfo.Municipio} ${this.userInfo.Estado}`;
      this.markers = {
        lat: this.defaultUserCoord.lat,
        lng: this.defaultUserCoord.lng,
        label: 'o',
        adress: 'Buscando...',
        draggable: true
      };
      this.getUbicationByCoords({ lat: this.markers.lat, lng: this.markers.lng }); // Comentar esto y descomentar las lineas superiores para mostrar tal cual como en BO
      this.setInputListener();
    }
  }

  markerDragEnd(m: marker, $event: MouseEvent) {
    this.markers = {
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      label: "o",
      adress: "suerte",
      draggable: true
    };
    const { lat, lng } = this.markers;
    this.getUbicationByCoords({ lat, lng });
  }

  getUnfinishedSurveys(): void {
    this.store.dispatch(new actionTypes.OpenLoadingActions());
      this.surverysSubscription = this.bookingService.getUncompletedSurveys().subscribe(response => {
      if (response)
        this.unfinishedSurveys = response;
      this.store.dispatch(new actionTypes.CloseLoadingActions());
    })
  }

  submitForm(): void {
    this.sendBookingRequest();
  }

  sendBookingRequest(): any {
    this.request.addressComplement = this.addressFormGroup.get('addressComplement').value;
    this.request.addressBetweenStreet = this.addressFormGroup.get('addressBetweenStreet').value;
    this.request.addressBetweenStreetAndStreet = this.addressFormGroup.get('addressBetweenStreetAndStreet').value;

    this.store.dispatch(new actionTypes.OpenLoadingActions());
    this.clientservice.sendRequest(this.request).subscribe((response: any) => {
      this.store.dispatch(new actionTypes.CloseLoadingActions());
      const hasPendingSurveys = this.unfinishedSurveys && this.unfinishedSurveys.length > 0;
      if (hasPendingSurveys) {
        const dialogRef = this.dialogService.open(ReminderDialogComponent, {
          header: 'Recordatorio',
          width: 'auto',
          closeOnEscape: false,
          closable: false,
        });

        this.closeDialogSubscr();

        this.dialogSubcription = dialogRef.onClose.subscribe(data => {
          this.router.navigate(['/dashboard/exito'], { queryParams: { id: this.bookingId } });
        });
        return;
      }
      this.router.navigate(['/dashboard/exito'], { queryParams: { id: this.bookingId } });
    });
  }

  validationsBlocks(fechaInicioSolicitud, fechaFinSolicitud) {
    let isValid = false;
    let auxRanges2 = this.rangesServices.map((x) => {
      x.start = moment.utc(x.start);
      x.end = moment.utc(x.end);
      return x;
    });

    isValid = !auxRanges2.some( (puntos) => this.validateBlock(puntos, fechaInicioSolicitud, fechaFinSolicitud));

    if (!isValid) return false;

    for( let i = 0; i < this.professionalsId.length; i++ ) {

      auxRanges2 = this.mapRangesBusyByProfessional[this.professionalsId[i]];
      auxRanges2 = auxRanges2.map((x) => {
        x.start = moment.utc(x.start);
        x.end = moment.utc(x.end);
        return x;
      });

      if(!auxRanges2.some(puntos => this.validateBlock(puntos, fechaInicioSolicitud, fechaFinSolicitud))){
        this.professionalId = this.professionalsId[i];
        isValid = true;
        break;
      } else {
        isValid = false;
      }
    }

    return isValid;
  }

  validateBlock(puntos , fechaInicioSolicitud, fechaFinSolicitud): boolean {

    if(puntos.title == 'Reserva de booking' )
        return (puntos.start < fechaInicioSolicitud && fechaInicioSolicitud < puntos.end) ||
          (puntos.start < fechaFinSolicitud && fechaFinSolicitud < puntos.end) ||
          (fechaInicioSolicitud <= puntos.start && puntos.end <= fechaFinSolicitud ) ||
          (fechaInicioSolicitud == puntos.start && puntos.end == fechaFinSolicitud)
       else  return (puntos.start < fechaInicioSolicitud && fechaInicioSolicitud < puntos.end) ||
          (puntos.start < fechaFinSolicitud && fechaFinSolicitud < puntos.end) ||
          (fechaInicioSolicitud < puntos.start && puntos.end < fechaFinSolicitud ) ||
          (fechaInicioSolicitud == puntos.start && puntos.end == fechaFinSolicitud);
  }

  getTransferTime(start) {
    return this.statesAssociatedTransfer[0]? this.statesAssociatedTransfer[0].transferTimeMin : 60;

  }

  /*
    [selectDate, función encargada de recibir el evento cuando se selecciona una fecha y hora en el componente hijo Calendario]
  */
  selectDate(model: any) {

    let transferTime = this.getTransferTime(model['model'].start);

    let definition = this.findService(this.request.definitionId)[0];
    let fechaInicioSolicitud = moment.utc(model['model'].start)
        .subtract(transferTime, 'minutes')
        .subtract(definition['anticipationTime'], 'minutes');

    let fechaFinSolicitud = moment.utc(model['model'].start).add(definition['duration'], 'minutes');
    let dataBooking = {
      start: moment.utc(model['model'].start),
      end: moment.utc(model['model'].start).add(definition['duration'], 'minutes'),
     // id: this.professionalId,
      bookingId: this.bookingId,
      duration: definition['duration'],
      transferTime: transferTime,
      anticipationTime: definition['anticipationTime']
    };
    if (this.validationsBlocks(fechaInicioSolicitud, fechaFinSolicitud)) {

      dataBooking['professionals'] = "[" + this.professionalsId + "]";
          this.bookingService.searchBookingProfessionalBusyRanges(dataBooking).subscribe((response: any) => {
            if (response !== undefined ) {
              let dateTimePreviewSelect = definition['anticipationTime'] + transferTime;
              this.rangesServices = response.filter(
                (range) => range.title  != "Reserva de booking" && range.title != 'Reserva de contratista').map((x) => {
                x.start = moment.utc(x.start);
                x.end = moment.utc(x.end);
              return x;
            });
              this.allProfessionalsRange = response.filter(
                (range) => range.title == 'Reserva de booking' || range.title == 'Reserva de contratista').map(
                range => {
                  if(range.title == 'Reserva de booking') {
                  range.start = this.parseUTCtoLocal(moment.utc(range.start));
                  range.end = this.parseUTCtoLocal(moment.utc(range.end));
                  } else if (range.title == 'Reserva de contratista') {
                    range.start = moment.utc(range.start);
                    range.end = moment.utc(range.end);
                  }
                return range;
              });

              this.getProfessionalsBusyRanges(this.allProfessionalsRange);
              let rangesServicestoPaint = _.cloneDeep(this.rangesServices);
              rangesServicestoPaint = rangesServicestoPaint.map(range => {
                range.end = moment.utc(range.end).add(dateTimePreviewSelect, 'minutes');
                return range;
              });
              let busyAllToPaint = _.cloneDeep(this.busyRangesForAll);
              busyAllToPaint = busyAllToPaint.map(range => {
                if(range.title == 'Reserva de booking') {
                  range.start = this.parseLocaltoUTC(moment.utc(range.start));
                  range.end = this.parseLocaltoUTC(moment.utc(range.end).add(dateTimePreviewSelect, 'minutes'));
                }
                return range;
              });
              if (this.professionalId && this.validationsBlocks(fechaInicioSolicitud,fechaFinSolicitud)) {
                this.request.professionalId = this.professionalId;
                this.scheduledAppointment = true;
                this.calendar.paintBusyRangesOfProfessional(rangesServicestoPaint.concat(busyAllToPaint));
                this.calendar.paintSolicitud(dataBooking);
                if (new Date().getTimezoneOffset() >= 0) {
                  this.request.bookingInitDate = fechaInicioSolicitud.add(this.currentOffSet + transferTime + definition['anticipationTime'], 'minutes').toISOString();
                  this.request.bookingEndDate = fechaFinSolicitud.add(this.currentOffSet, 'minutes').toISOString()
                } else {
                  this.request.bookingInitDate = fechaInicioSolicitud.add(transferTime + definition['anticipationTime'], 'minutes').subtract(this.currentOffSet, 'minutes').toISOString();
                  this.request.bookingEndDate = fechaFinSolicitud.subtract(this.currentOffSet, 'minutes').toISOString()
                }
                this.calendar.messageDateSelect('Se ha asignado correctamente su asistencia en este horario', 'success');
              } else {
                this.calendar.messageDateSelect('No hay técnicos disponibles para esta fecha', 'error');
              }
            }
          });

    } else {

      this.calendar.messageDateSelect('Por favor seleccione un espacio disponible para su asistencia', 'error');
    }

  }

  parseUTCtoLocal(date): any {
    if(this.currentOffSet >= 0) {
      return date.subtract(this.currentOffSet, 'minutes').toISOString()
    } else {
      return date.add(this.currentOffSet, 'minutes').toISOString()
    }
  }

  parseLocaltoUTC(date): any {
    if(this.currentOffSet >= 0) {
      return date.add(this.currentOffSet, 'minutes').toISOString()
    } else {
      return date.subtract(this.currentOffSet, 'minutes').toISOString()
    }
  }

  getProfessionalsBusyRanges(ranges: any): void {
    if(ranges != null && ranges.length > 0) {
      ranges.map(
        range => {
          if( range.title == 'Reserva de booking') {
            if(this.mapRangesBusyByProfessional.hasOwnProperty(range.id)) {
              this.mapRangesBusyByProfessional[range.id].push(range);
            } else {
              this.mapRangesBusyByProfessional[range.id] = [range];
            }
          } else if(range.title == 'Reserva de contratista'){
            range.professionalChildren.map(professional => {
                range.id = professional;
              if(this.mapRangesBusyByProfessional.hasOwnProperty(professional)){
                this.mapRangesBusyByProfessional[professional].push(range);
              } else {
                this.mapRangesBusyByProfessional[professional] = [range];
              }
            });
          }
        }
      )
    }
    this.intersection();
  }

  intersection() {
    let mapCopy = _.cloneDeep(this.mapRangesBusyByProfessional);
    this.busyRangesForAll = mapCopy[this.professionalsId[0]];
    for(let i = 0; i< this.busyRangesForAll.length; i++) {
      let isBusy = false;
      for(let j = 1; j < this.professionalsId.length; j++) {
        for(let r = 0; r < this.mapRangesBusyByProfessional[this.professionalsId[j]].length; r++) {
          let aux = this.intersectionLogic(this.busyRangesForAll[i],
            this.mapRangesBusyByProfessional[this.professionalsId[j]][r]);
          this.busyRangesForAll[i] = aux.range;
            isBusy = aux.busy;
            if(isBusy) break;
        }
        if(!isBusy) {
          this.busyRangesForAll[i] = null;
          break;
        }
      }
    }

    this.busyRangesForAll = this.busyRangesForAll.filter(x => x !=null);
  }

  intersectionLogic(range, rangeNext) {
    let busy = false;
      if(range.start >= rangeNext.start && range.start >= rangeNext.end) {
        return  {
          range,
          busy
        };
      }
      if(range.end <= rangeNext.start && range.end <= rangeNext.end) {
        return {range, busy};
      }

      busy = true;
      if(range.start <= rangeNext.start && range.end >= rangeNext.end){
        range.start = rangeNext.start;
        range.end = rangeNext.end;
        return  {range, busy};
      }
      if(range.start > rangeNext.start && range.end >= rangeNext.end) {
        range.end = rangeNext.end;
        return {range,busy};
      }
      if(range.start <= rangeNext.start && range.end > rangeNext.end) {
        range.start = rangeNext.start;
        return {range, busy};
  }
    return { range, busy: false};
  }

  /*
    [iniComponentCalendario, función encargada de recibir el evento cuando se cargue el compoenete Calendario]
  */
  iniComponentCalendarioParent(model: any) {
    let body = {
      bookingId: this.bookingId,
      start: moment(model['start']).format('YYYY-MM-DD'),
      end: moment(model['end']).format('YYYY-MM-DD')
    };

    // Consulta si existe profesional disponible
    if  (this.bookingId != undefined) {
      //
      let transferTime = this.getTransferTime(model['start']);

      let definition = this.findService(this.request.definitionId)[0];

      let dateTimePreviewSelect = definition['anticipationTime'] + transferTime;
      //
      this.store.dispatch(new actionTypes.OpenLoadingActions());
      this.bookingService.getBookingMatch(body).subscribe((response: any) => {
        if (response !== undefined) {
          this.professionalsId = response.map(e => e.professionalId);
          body['professionals'] = `[${this.professionalsId}]`;
          // Consulta los profesiones disponibles en el rango de fechas
          this.bookingService.searchBookingProfessionalBusyRanges(body).subscribe((responseRange: any) => {
            this.store.dispatch(new actionTypes.CloseLoadingActions());
            if (responseRange !== undefined) {
              if(this.professionalsId.length == 0) {
                this.calendar.messageDateSelect('No hay técnicos disponibles para esta semana', 'error');
                return;
              }
                this.rangesServices = responseRange.filter(
                  (range) => range.title  != "Reserva de booking" && range.title != 'Reserva de contratista').map((x) => {
                  x.start = moment.utc(x.start);
                  x.end = moment.utc(x.end);
                return x;
              });
                this.allProfessionalsRange = responseRange.filter(
                  (range) => range.title == 'Reserva de booking' || range.title == 'Reserva de contratista').map(
                  range => {
                    if(range.title == 'Reserva de booking') {
                    range.start = this.parseUTCtoLocal(moment.utc(range.start));
                    range.end = this.parseUTCtoLocal(moment.utc(range.end));
                    } else if (range.title == 'Reserva de contratista') {
                      range.start = moment.utc(range.start);
                      range.end = moment.utc(range.end);
                    }
                  return range;
                });

                this.getProfessionalsBusyRanges(this.allProfessionalsRange);
                let rangesServicestoPaint = _.cloneDeep(this.rangesServices);
                rangesServicestoPaint = rangesServicestoPaint.map(range => {
                  range.end = moment.utc(range.end).add(dateTimePreviewSelect, 'minutes');
                  return range;
                });
                let busyAllToPaint = _.cloneDeep(this.busyRangesForAll);
                busyAllToPaint = busyAllToPaint.map(range => {
                  if(range.title == 'Reserva de booking') {
                    range.start = this.parseLocaltoUTC(moment.utc(range.start));
                    range.end = this.parseLocaltoUTC(moment.utc(range.end).add(dateTimePreviewSelect, 'minutes'));
                  }
                  return range;
                });
                this.calendar.paintBusyRangesOfProfessional(rangesServicestoPaint.concat(busyAllToPaint));
              }

          }, (err: any) => {
            this.store.dispatch(new actionTypes.CloseLoadingActions());
            this.calendar.messageDateSelect('No hay técnicos disponibles para esta semana', 'error');
          });
        }
      }, (err: any) => {
        this.store.dispatch(new actionTypes.CloseLoadingActions());
        // No existen técnicos
        this.calendar.messageDateSelect('No hay técnicos disponibles para esta semana', 'error');
      });
    }
  }

  editBookingRequest(contactStepNext = false) {
    this.request.bookingId = this.bookingId;
    this.request.category = this.problemFormGroup.get('groupInput').value;
    this.request.definitionId = this.services.find(el => el.name == this.problemFormGroup.get('serviceInput').value).id;
    this.request.productId = this.available.find(el => el.name == this.problemFormGroup.get('serviceInput').value).productId;
    this.request.problem = this.problemFormGroup.get('problemInput').value;

    this.request.contactName = this.contactFormGroup.get('nameInput').value;
    this.request.contactPhone = this.contactFormGroup.get('phoneInput').value;
    this.request.email = this.contactFormGroup.get('emailInput').value;

    if (this.request.address == undefined || this.request.address == null || this.request.address == "") {
      this.getState(this.addressFormGroup.get('stateInput').value);
      this.request.address = this.addressFormGroup.get('streetInput').value + " " + this.addressFormGroup.get('exteriorInput').value + ", " + this.addressFormGroup.get('zipCodeInput').value + " " + this.addressFormGroup.get('colonyInput').value + ", " + this.addressFormGroup.get('municipalityInput').value + ", " + this.addressFormGroup.get('stateInput').value;
    }

    this.request.latitude = this.lat;
    this.request.longitude = this.lng;

    this.request.addressComplement = this.addressFormGroup.get('addressComplement').value;
    this.request.addressBetweenStreet = this.addressFormGroup.get('addressBetweenStreet').value != '' ? this.addressFormGroup.get('addressBetweenStreet').value : 'calle';
    this.request.addressBetweenStreetAndStreet = this.addressFormGroup.get('addressBetweenStreetAndStreet').value != '' ? this.addressFormGroup.get('addressBetweenStreetAndStreet').value : 'calle';
    this.store.dispatch(new actionTypes.OpenLoadingActions());
    this.clientservice.updateRequest(this.request).subscribe((response: any) => {
      this.getRequestIfExists();
      this.store.dispatch(new actionTypes.CloseLoadingActions());
    });
    if(contactStepNext) this.contactStep++;
    if (this.request.stateId) {
      this.store.dispatch(new actionTypes.OpenLoadingActions());
      this.bookingService.getStateTransfersByStateId(this.request.stateId).subscribe((response: any) => {
        this.store.dispatch(new actionTypes.CloseLoadingActions());
        this.statesAssociatedTransfer = response;

      });
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '250px',
      data: { id: this.bookingId }
    });

    dialogRef.afterClosed().subscribe(result => { });
  }

  public  getAvailabilityCoverages() {
    this.store.dispatch(new actionTypes.OpenLoadingActions());
    this.clientservice.getAvailabilityCoverages().subscribe((response: any) => {
      if (response !== undefined && response.length > 0) {
        this.available = response;
        this.getServicesGroups();
      }else {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: 'Usted no tiene coberturas disponibles.',
        });
      }
      this.store.dispatch(new actionTypes.CloseLoadingActions());
    });
  }

  getDistance(coordsObj: any): number {
    if (!this.defaultUserCoord)
      return;
    const { lat, lng } = coordsObj;
    const { lat: defaultLat, lng: defaultLng } = this.defaultUserCoord;
    const pointOne = new google.maps.LatLng(lat, lng);
    const pointTwo = new google.maps.LatLng(defaultLat, defaultLng);
    const distance = google.maps.geometry.spherical.computeDistanceBetween(pointOne, pointTwo);
    return distance;
  }

  public getServicesGroups() {
    this.store.dispatch(new actionTypes.OpenLoadingActions());
      this.bookingService.getServicesGroups().subscribe((response: any) => {
      if (response !== undefined) {
        response.forEach(group => {
          group['icon'] = this.mapGroupImage[group.name];
          group['iconSelected'] = this.mapGroupImageSelected[group.name];
          group['allow'] = false;
          for (let index = 0; index < this.available.length; index++) {
            if (this.available[index]['type'] == 'GROUP' && (this.available[index]['available'] > 0 || this.available[index]["isUnlimited"] == 'ilimitado')&& this.available[index]['id'] == group['id']) {
              group['allow'] = true;
              break;
            } else if (this.available[index]['type'] == 'SERVICE' && (this.available[index]['available'] > 0 || this.available[index]["isUnlimited"] == 'ilimitado') && this.available[index]['groupId'] == group['id']) {
              group['allow'] = true;
              break;
            }
          }
        });
        this.groups = response;
        this.getRequestIfExists();
      }
      this.store.dispatch(new actionTypes.CloseLoadingActions());
    });
  }

  getUserData() {
    this.store.dispatch(new actionTypes.OpenLoadingActions());
    this.clientservice.getUserData(localStorage.getItem('clientId')).subscribe(
      response => {
        this.userInfo = response;
        this.getClientPlan();
        this.planCovered();
        this.formatDataAddress(response);
        this.store.dispatch(new actionTypes.CloseLoadingActions());
      },
      error => {
        this.store.dispatch(new actionTypes.CloseLoadingActions());
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: error
        });
      });

  }

  formatDataAddress(data: any): string | null {
    const { Municipio, Colonia, Calle, CodigoPostal, EntreCalleyCalle } = data;
    const verifiedString = (incommingString: string) => incommingString && incommingString.trim().length ? incommingString + ', ' : '';
    const formatedAddres = `${verifiedString(Municipio)} ${verifiedString(Colonia)} ${verifiedString(Calle)} ${verifiedString(CodigoPostal)}, ${verifiedString(EntreCalleyCalle)}`;
    this.getCoordsByAddres(formatedAddres, true);
    this.addressFormGroup.get('addressSAP').setValue(formatedAddres);

    return formatedAddres;
  }

  clean() {
    if (this.problemFormGroup.controls.serviceInput.value != null && this.problemFormGroup.controls.serviceInput.touched == true) {
      this.problemFormGroup.controls['serviceInput'].reset();
    }
  }

  vista(myLocation: boolean) {
    this.view = 1;
     
    if (myLocation) {
      this.getDefaultUserUbication();
      this.markers = {
        lat: this.defaultUserCoord.lat,
        lng: this.defaultUserCoord.lng,
        label: 'o',
        adress: 'Buscando...',
        draggable: true
      };
    }

    this.getUbicationByCoords({ lat: this.markers.lat, lng: this.markers.lng });
    this.setInputListener();
  }

  closeDialogSubscr(): void {
    if (this.dialogSubcription && !this.dialogSubcription.closed)
      this.dialogSubcription.unsubscribe();
  }

  openAudioDialog(): void {
    const ref = this.dialogService.open(AudioDialogComponent, {
      header: "Grabar un audio",
      width: "70%",
      closeOnEscape: false,
      closable: false,
    });

    this.closeDialogSubscr();

    this.dialogSubcription = ref.onClose.subscribe((result) => {
      if (!result) return;

      if (result) {
        this.uploadFile(
          this.blobToFile(result, "audio-" + this.bookingId + ".wav"),
          "audio"
        );
      } else {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Algo salio mal con el audio",
        });
      }
    });
  }

  uploadAudio(audio) {
      this.bookingService.uploadBookingAudio(audio, this.bookingId).subscribe((response: any) => {
      if (response !== undefined) {
        this.audios.push(response);
        this.files.push(response);
      }
      this.store.dispatch(new actionTypes.CloseLoadingActions());
      this.messageService.add({
        severity: "success",
        summary: "Éxito",
        detail: "Audio cargado exitosamente",
      });
    }, err => {
      this.store.dispatch(new actionTypes.CloseLoadingActions());
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Algo fallo en la subida del audio",
      });
    });
  }

  blobToFile = (theBlob: Blob, fileName: string): File => {
    const b: any = theBlob;
    // A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    // Cast to a File() type
    return <File>theBlob;
  }

  validateDocument(
    file: any,
    admitedFormats: string[],
    maxMbSize: number = 20
  ): boolean {
    const { name, size } = file;
    const admitedFormat = admitedFormats.find((format) =>
      name.endsWith(format)
    );
    const sizeMb = size / 1000000;
    const admitedMb = sizeMb <= maxMbSize;
    return Boolean(admitedFormat) && admitedMb;
  }

  uploadImage(image) {
      this.bookingService.uploadBookingImage(image, this.bookingId).subscribe((response: any) => {
      if (response !== undefined) {
        this.images.push(response);
        this.files.push(response);
      }
      this.store.dispatch(new actionTypes.CloseLoadingActions());
      this.messageService.add({
        severity: "success",
        summary: "Éxito",
        detail: "Imagen cargada exitosamente",
      });
    }, err => {
      this.store.dispatch(new actionTypes.CloseLoadingActions());
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Error al subir la imagen",
      });
    });
  }

  uploadVideo(video) {
      this.bookingService.uploadBookingVideo(video, this.bookingId).subscribe((response: any) => {
      if (response !== undefined) {
        this.videos.push(response);
        this.files.push(response);
      }
      this.store.dispatch(new actionTypes.CloseLoadingActions());
      this.messageService.add({
        severity: "success",
        summary: "Éxito",
        detail: "Video cargado exitosamente",
      });
    }, err => {
      this.store.dispatch(new actionTypes.CloseLoadingActions());
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Error al subir el video",
      });
    });
  }

  openBaseDialog(fileType: string = "document"): void {
    const ref = this.dialogService.open(BaseDialogComponent, {
      header: dialogHeader[fileType],
      width: "70%",
      closeOnEscape: false,
      closable: false,
      data: {
        admitedFormats: filesFormat[fileType],
        maxFileSize: filesSize[fileType],
      },
    });

    this.closeDialogSubscr();

    this.dialogSubcription = ref.onClose.subscribe((result) => {
      if (!result) return;
      const canUpload = this.validateDocument(
        result,
        filesFormat[fileType],
        filesSize[fileType]
      );
      if (canUpload) {
        this.uploadFile(result, fileType);
      } else {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Algo salio mal con el archivo",
        });
      }
    });
  }

  uploadDocument(document) {
      this.bookingService.uploadBookingDocument(document, this.bookingId).subscribe((response: any) => {
      if (response !== undefined) {
        this.documents.push(response);
        this.files.push(response);
      };
      this.store.dispatch(new actionTypes.CloseLoadingActions());
      this.messageService.add({
        severity: "success",
        summary: "Éxito",
        detail: "Video cargado exitosamente",
      });

    }, err => {
      this.store.dispatch(new actionTypes.CloseLoadingActions());
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Error al subir el arhivo",
      });
    });
  }

  public getBookingFiles(bookingId: number) {
    this.bookingService.getBookingFiles(bookingId).subscribe((response: any) => {
      if (response !== undefined) {
        this.files = response;
        this.audios = this.files.filter(function (file) { return file.context == 'booking-client-audio'; });
        this.videos = this.files.filter(function (file) { return file.context == 'booking-client-video'; });
        this.images = this.files.filter(function (file) { return file.context == 'booking-client-image'; });
        this.documents = this.files.filter(function (file) { return file.context == 'booking-client-document'; });
      }
    });
  }

  removeFile(context, i) {
    let file;
    if (context === 'audio') {
      file = this.audios[i];
    } else if (context === 'video') {
      file = this.videos[i];
    } else if (context === 'image') {
      file = this.images[i];
    } else if (context === 'document') {
      file = this.documents[i];
    }
    let filesIndex;
    for (const j in this.files) {
      if (this.files[j].fileId === file.fileId) {
        filesIndex = j;
      }
    }

    this.bookingService.removeBookingFile(file.fileId).subscribe((response: any) => {
      if (response !== undefined) {
        this.files.splice(filesIndex, 1);
        if (context === 'audio') {
          this.audios.splice(i, 1);
        } else if (context === 'video') {
          this.videos.splice(i, 1);
        } else if (context === 'image') {
          this.images.splice(i, 1);
        } else if (context === 'document') {
          this.documents.splice(i, 1);
        }
        this.store.dispatch(new actionTypes.CloseLoadingActions());
        this.messageService.add({
          severity: "success",
          summary: "Éxito",
          detail: "Archivo Eliminado exitosamente",
        });
        return;
      }
      this.store.dispatch(new actionTypes.CloseLoadingActions());
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "No se ha eliminado el archivo",
      });
    });
  }

  exceedDialog(): void {
    const ref = this.dialogService.open(ExceedDialogComponent, {
      header: "Capacidad excedida",
      width: "70%",
      closeOnEscape: false,
      closable: false,
    });
  }

  uploadFile(file: any, type: string) {
    this.store.dispatch(new actionTypes.OpenLoadingActions());
    switch (type) {
      case 'document':
        this.uploadDocument(file);
        break;

      case 'image':
        this.uploadImage(file);
        break;

      case 'video':
        this.uploadVideo(file);
        break;

      case 'audio':
        this.uploadAudio(file);
        break;
      default:
        break;
    }
  }

  selectAssitenceType(group: any): void {
      if (group.name != this.problemFormGroup.get("groupInput").value && group.allow) {
        this.problemFormGroup.get("groupInput").setValue(group.name);
        this.selectServiceType(null);
      }
      else if(group.name === this.problemFormGroup.get('groupInput').value){

      } else
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: 'No tiene asistencias disponibles de ' + group.name,
      });
  }

  selectTelemedicineType(name: string): void {
    this.selectedService = name;
    if (this.selectedService === "Asistencia telefónica") {
      this.display = true;
    }
    
    if (this.selectedService === "Telemedicina") {
      this.display2 = true;
    }
}

  selectServiceType(service: any): void {
    if(service == null) {
      this.problemFormGroup.get("serviceInput").setValue("");
      return;
    }
    if(service.allow)
      this.problemFormGroup.get("serviceInput").setValue(service.name);
    else
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: 'No tiene asistencias disponibles de ' + service.name,
      });
  }

  getImageByService(idService: number){
    return mapImageService[idService] ? mapImageService[idService] : "assets/images/empty.jpg";
  }

  editAndGoToMap(): void {
    this.editBookingRequest();

  }

  closeModalMedicine($event) {
    this.display = $event;
  }
  
  closeModalTelemedicine($event) {
    this.display2 = $event;
  }

  goToAxa() {
    this.store.dispatch(new actionTypes.OpenLoadingActions());
    let data = {
      name: localStorage.getItem('nameuser'),
      lastName: localStorage.getItem('apellidouser'),
      email: localStorage.getItem('emailuser'),
      cellphone: '52' + localStorage.getItem('telefonouser')
     //cellphone: '521234567872'
    };

    this.axaService.connectAxaFrame(data).subscribe((response: any) => {
      this.store.dispatch(new actionTypes.CloseLoadingActions());
      this.router.navigate(['/dashboard/axa'], {state: { axaUrl: response.urlIntegrationTelemedicine } })
    }, (err: any) => {
      this.store.dispatch(new actionTypes.CloseLoadingActions());
      this.router.navigate(['/dashboard/axa'], {state: { error: true }})
    });
  }

  orderServicesGroup(services) {
    const index = services.findIndex(service => service.name.toLowerCase() === "raia");
    if (index !== -1) {
      const raia = services[index];
      services.splice(index, 1);
      services.splice(0, 0, raia);
    }
    return services;
  }
}

enum StateEnum {
  INMEDIATO, AGENDAR
}
