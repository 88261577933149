
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { WebsocketService } from 'src/app/websocket.service';
import { BookingsService } from "../../../../core/api/bookings/bookings.service";
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-reject-budget-dialog',
  templateUrl: './reject-budget-dialog.component.html',
  styleUrls: ['./reject-budget-dialog.component.scss']
})
export class RejectBudgetDialogComponent implements OnInit {

  request: any={"rejectedBudgetReason": "","anotherReasonForRejectedBudget": ""};

  constructor(private _wsService: WebsocketService,
              public dialogRef: MatDialogRef<RejectBudgetDialogComponent>,
              private messageService: MessageService,
              private bookingsService: BookingsService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {}

  close(): void {
    this.dialogRef.close();
  }

  rejectBudget() {
  	if(this.request.rejectedBudgetReason!="Otro"){
  		this.request.anotherReasonForRejectedBudget="";
  	}
    this.bookingsService.rejectBudget(this.data.bookingID,this.request).subscribe((response: any) => {
      this.dialogRef.close();
      this.messageService.add({severity: 'success', summary:'Éxito', detail:'Se ha rechazado el presupuesto exitosamente.'});
    }, err => {
      this.messageService.add({severity: 'error', summary:'Error', detail: 'Ha ocurrido un error. Por favor, inténtelo nuevamente.'})
    });
  }
}
