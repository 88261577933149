import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BookingsService } from 'src/app/core/api/bookings/bookings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TutenBookingChatMessage } from 'src/app/core/api/bookings/booking.interfaces';
import { interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChatComponent implements OnInit {

  messageForm: FormGroup;
  bookingId: number;
  // Just for mocking up
  chat: any[] = [1, 2, 3, 4, 5];
  booking: any;
  chatStatus: string;
  chatDate: string;
  showMessageForm = true;
  noChatMessage = 'No hay mensajes inicia la conversación.';

  constructor(
    public formBuilder: FormBuilder,
    private bookingService: BookingsService,
    private activate: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getBookingId();
    this.getBooking();
    this.getChat();
    this.buildMessageForm();
    this.getUpdatedChat();
  }

  /**
   * function to submit message to server
   * @param message message to be sent
   */
  submitMessage() {
    if (this.messageForm.invalid) {
      return;
    }

    const message: TutenBookingChatMessage = {
      authorFullname: localStorage.getItem('nameuser') + ' ' + localStorage.getItem('apellidouser'),
      creationDate: new Date().getTime().toString(),
      messageContent: this.messageForm.get('message').value
    };

    this.bookingService.putBookingChat(this.bookingId, message).subscribe((response: any) => {
      this.chat = response;
      this.messageForm.reset();
      this.messageForm.clearValidators();
    });
  }

  /**
   * Function to inject form for messaging
   */
  buildMessageForm() {
    this.messageForm = this.formBuilder.group({
      message: this.formBuilder.control('', Validators.compose([
        Validators.required,
        Validators.maxLength(140),
        Validators.minLength(1)
      ]))
    });
  }


  /**
   * Function to get and update chat
   */
  getChat(): any {
    this.bookingService.getChatByBookingId(this.bookingId).subscribe((response: any) => {
      this.chat = response;
      console.log('CHAT: ', this.chat);
      // Chat is not created
      if (!this.chat || !this.chat.length && this.booking.state === 5) {
        this.chatStatus = 'Por iniciar';
        this.chatDate = '';
        // Injecting message by default
        this.messageForm.get('message').setValue(`Saludos ${this.booking.professional}.`);
      } else if (!!this.chat.length && this.booking.state === 5) {
        this.chatStatus = 'Chat activo';
        this.chatDate = new Date(this.chat[0].chatId.creationDate).getTime().toString();
      } else if (!!this.chat.length && this.booking.state !== 5) {
        this.chatStatus = 'Chat Finalizado';
        this.chatDate = new Date(this.chat[this.chat.length - 1].creationDate).getTime().toString();
        this.showMessageForm = false;
      } else if (!this.chat.length && this.booking.state !== 5) {
        this.noChatMessage = 'No hay mensajes para mostrar.';
        this.showMessageForm = false;
      }
    });
  }

  getBookingId(): void {
    this.activate.params.subscribe(params => {
      this.bookingId = JSON.parse(params.serviceId);
      if (this.bookingId == null) {
        return this.goBack();
      }
    });
  }

  getBooking(): void {
    this.bookingService.getBooking(this.bookingId).subscribe((response: any) => {
      if (response == null) {
        return this.goBack();
      }
      this.booking = response;
    }, (error: any) => {
      this.goBack();
    });
  }

  goBack(): void {
    this.router.navigate([`/dashboard/asistencia/${this.bookingId}/`]);
  }

  /**
   * Function to fetch the messages every 15 seconds
   */
  intervalWrapper(): any {
    return interval(15000).pipe(
      switchMap(counter => {
        return this.bookingService.getChatByBookingId(this.bookingId);
      })
    );
  }

  getUpdatedChat() {
    this.intervalWrapper().subscribe((response: any) => {
      this.chat = response;
    });
  }

}
