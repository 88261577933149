import {Component, OnInit, ViewChild, Output, EventEmitter} from '@angular/core';
import * as moment from 'moment';
import {CalendarComponent} from 'ng-fullcalendar';
import {Options} from 'fullcalendar';
import {Calendar} from 'fullcalendar';
import {getBootstrapListener} from '@angular/router/src/router_module';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.scss']
})
export class CalendarioComponent implements OnInit {

  @ViewChild(CalendarComponent) ucCalendar: CalendarComponent;
  @Output() emitEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitEventIniComponent: EventEmitter<any> = new EventEmitter<any>();

  calendarOptions: Options;

  constructor(private messageService: MessageService) {}

  ngOnInit() {
    let _this = this;
    this.calendarOptions = {
      header: {
        left: 'title',
        center: '',
        right: 'customPrevButton, customNextButton'
      },
      locale: 'es',
      defaultView: 'agendaWeek',
      nowIndicator: true,
      selectable: true,
      longPressDelay: 0,
      selectHelper: false,
      eventLimit: true,
      editable: true,
      allDaySlot: false,
      titleFormat: 'DD-MM-YY',
      slotLabelFormat: 'HH:mm',
      height: 500,
      eventDurationEditable: false,
      customButtons: {
        customPrevButton: {
          text: 'Anterior',
          click: function () {
            _this.ucCalendar.fullCalendar('prev');
            _this.ucCalendar.fullCalendar('removeEvents');
            _this.iniComponentAgain();
          }
        },
        customNextButton: {
          text: 'Siguiente',
          click: function () {
            _this.ucCalendar.fullCalendar('next');
            _this.ucCalendar.fullCalendar('removeEvents');
            _this.iniComponentAgain();
          }
        },
        today: {
          text: 'Hoy',
          click: function () {
            _this.ucCalendar.fullCalendar('today');
            _this.ucCalendar.fullCalendar('removeEvents');
            _this.iniComponentAgain();
          }
        }

      }
    };
  }

  select(model: any) {
    //Verifica si la fecha seleccionada es menor a la actual
    if (!this.minorEventToActualDate(model.end))
      this.emitEvent.emit({
        model: model,
        start: this.ucCalendar.fullCalendar('getCalendar').view.start,
        end: this.ucCalendar.fullCalendar('getCalendar').view.end
      });
  }

  /*
    [iniComponent ,Función encargada de llamarse una vez que se cree el componente fullcalendar]
  */
  iniComponent(model: any) {
    this.getPastBlock();
    this.emitEventIniComponent.emit({
      start: this.ucCalendar.fullCalendar('getCalendar').view.start,
      end: this.ucCalendar.fullCalendar('getCalendar').view.end
    });
  }

  iniComponentAgain(){
    this.getPastBlock();
    this.emitEventIniComponent.emit({
      start: this.ucCalendar.fullCalendar('getCalendar').view.start,
      end: this.ucCalendar.fullCalendar('getCalendar').view.end
    });
  }

  eventDragStart(model: any) {}

  eventDragStop(model: any) {
    this.select(model.event);
  }

  selectLongPressDelay() {}

  /*
    [getPastBlock ,Consulta los bloques de fecha y hora menor al actual y se pintan de otro color]
  */
  getPastBlock() {

    let range: any;
    range = this.getCalendarDatesRange();
    if (range != undefined) {
      if (moment(range.start).add(new Date().getTimezoneOffset(), 'minutes') < moment()) {
        this.ucCalendar.fullCalendar('renderEvent', {
          _id: '_fc_past_block',
          title: 'Fecha pasada',
          color: 'transparent',
          overlap: false,
          eventOverlap: false,
          editable: false,
          rendering: 'background',
          start: range.start,
          end: moment()
        });
      }
    }

  }

  /**
   * [getCalendarDatesRange, Rango de fechas del calendario según el defautView]
   */
  getCalendarDatesRange() {
    if (this.ucCalendar.fullCalendar('getCalendar') != undefined) {
      var range = {
        start: this.ucCalendar.fullCalendar('getCalendar').view.start,
        end: this.ucCalendar.fullCalendar('getCalendar').view.end
      };
      return range;
    }
  }

  /*
    [minorEventToActualDate, Función encargada de verificar si la fecha y hora seleccionar es menor al actual]
  */
  minorEventToActualDate(date: any) {
    if (moment(date._i).subtract(30, 'minutes') < moment()) { // Hacer tiempo de traslado dinamico
      this.ucCalendar.fullCalendar('unselect');
      this.messageService.add({severity: 'error', summary:'Error', detail: 'La fecha y hora seleccionada es menor a la fecha y hora actual '});
      return true;
    }
    return false;
  }

  /*
    [minorEventToActualDate, Función encargada de verificar si la fecha y hora seleccionar es menor al actual]
  */
  messageDateSelect(msj: string, type: string) {
    this.ucCalendar.fullCalendar('unselect');
    this.messageService.add({severity: type === 'error' ? 'error' : 'success', summary:type === 'error' ? 'Error' : 'Éxito', detail:msj});
  }

  /*
   [pintarReserva, Función encargada de pintar la reserva solicitdad]
 */
  paintSolicitud(data: any) {
    /* my nueva solicitud */

    let booking = {
      'rendering': 'background',
      'color': 'rgba(105, 175, 35, 1)',
      'opacity': '1',
      'overlap': true,
      'eventOverlap': false,
      'editable': true,
      'start': data.start,
      'end': data.end,
      'professionalId': data.id,
      'title': '',
      'id': '_fc_booking',
    };
    this.ucCalendar.fullCalendar('removeEvents', '_fc_booking');
    this.ucCalendar.fullCalendar('renderEvent', booking);

  }

  paintBusyRangesOfProfessional(response: any) {
    if (response !== undefined) {
      let data = response;
      for (let i = 0; i < data.length; i++) {
        if (moment(data[i]['end']) < moment()) {
          data[i]['color'] = '#9FA0A6';
          data[i]['overlap'] = false;
          data[i]['eventOverlap'] = false;
          data[i]['editable'] = false;
          data[i]['rendering'] = 'background';
        }
        if (data[i].title == 'Reserva de profesional') {
          data[i]['color'] = '#9FA0A6';
          data[i]['overlap'] = false;
          data[i]['eventOverlap'] = false;
          data[i]['editable'] = false;
          data[i]['rendering'] = 'background';
          data[i]['start'] = data[i]['start'];
          data[i]['end'] = data[i]['end'];
          data[i]['professionalId'] = data[i].id;
        } else if (data[i].title == 'Reserva de servicio') {
          data[i]['color'] = '#9FA0A6';
          data[i]['overlap'] = false;
          data[i]['eventOverlap'] = false;
          data[i]['editable'] = false;
          data[i]['rendering'] = 'background';
          data[i]['start'] = data[i]['start'];
          data[i]['end'] = data[i]['end'];
        } else if (data[i].title == 'Reserva de contratista') {
          data[i]['color'] = '#9FA0A6';
          data[i]['overlap'] = false;
          data[i]['eventOverlap'] = false;
          data[i]['editable'] = false;
          data[i]['rendering'] = 'background';
          data[i]['start'] = data[i]['start'];
          data[i]['end'] = data[i]['end'];
        } else if (data[i].title == 'No disponible') {
          data[i]['color'] = '#9FA0A6';
          data[i]['overlap'] = false;
          data[i]['eventOverlap'] = false;
          data[i]['editable'] = false;
          data[i]['rendering'] = 'background';
          data[i]['start'] = data[i]['start'];
          data[i]['end'] = data[i]['end'];
        }  else if (data[i].title == 'Reserva de booking') { //
          data[i]['color'] = '#9FA0A6';
          data[i]['overlap'] = false;
          data[i]['eventOverlap'] = false;
          data[i]['editable'] = false;
          data[i]['rendering'] = 'background';
          data[i]['start'] = new Date(data[i]['start']);
          data[i]['end'] = new Date(data[i]['end']);
          data[i]['professionalId'] = data[i].id;
          //data[i]['url'] = '/#!/dashboard/servicio/'+data[i].bookingId;
        }
        delete data[i].title;
      }
      this.ucCalendar.fullCalendar('removeEvents', '_fc_booking_proffesional');
      this.ucCalendar.fullCalendar('addEventSource', data);
    }
  }
}
