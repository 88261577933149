import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'banner-register-details',
  templateUrl: './banner-register-details.component.html',
  styleUrls: ['./banner-register-details.component.scss']
})
export class BannerRegisterDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
