import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators, FormArray } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { API_MAIN_URL } from "../shared/constants";
import { ClientService } from "../../core/api";
import { AuthService, SocialUser } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { MatDialog } from '@angular/material';
import { DialogRegisterComponent } from '../shared/dialog-register/dialog-register.component'
import { CellphoneRegisterComponent } from '../shared/cellphone-register/cellphone-register.component';
import { TutenClientProfileData, TutenClientProfile, TutenClientProfileProvide } from 'src/app/core/api/client/client-interfaces';
import { getClientIdsFromForm } from './register-component/engie-details/engie-details.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  url: string = API_MAIN_URL;

  ingresar: FormGroup;

  id: number = 10;

  hide = true;

  checked = false;
  loaded=false;
  tabIndex: number;
  error: string;

  constructor(private router: Router,
    private clientService: ClientService,
    private authService: AuthService,
    public dialog: MatDialog
  ) {


    this.ingresar = new FormGroup({
      'user': new FormControl('', [
        Validators.required,
        Validators.pattern('^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,4})$')]),
      'password': new FormControl('', Validators.required)
    })
  }

  ngOnInit() {
    this.setTab();
  }

  async login() {
    if ((this.ingresar.value.user).length > 0) {
      this.loaded=true;
      try {
        this.error = null;
        const result = await this.clientService.loginClient(this.ingresar.value.user, this.ingresar.value.password, 'CLIENT').toPromise();
        this.clientService.redirectLogin(result);
      } catch({ error }) {
        this.loaded=false;
        let errorString: string = error.error;
        //errorString = errorString.charAt(0).toUpperCase() + errorString.slice(1).toLowerCase();
        this.error = errorString;
      }
    }
  }

  email = new FormControl('', [Validators.required, Validators.email]);
  emailConfir = new FormControl('', [Validators.required, Validators.email]);

  passwordValidator(g: FormControl) {
    return g.get('password').value === g.get('repitPassword').value
      ? null : { 'mismatch': true };
  }

  getErrorMessage() {
    return this.email.hasError('required') ? 'Por favor ingrese un correo electronico' :
      this.email.hasError('email') ? 'Correo no valido' : '';
  }

  /**
   * Function to make login from any of the available
   * Soscial Media
   * @param socialMedia GOOGLE or FACEBOOK
   */
  async doSocialMediaLogin(socialMedia: string) {
    try {
      const socialPlatformProvider = socialMedia === 'FACEBOOK' ? FacebookLoginProvider.PROVIDER_ID : GoogleLoginProvider.PROVIDER_ID;
      const userData: SocialUser = await this.authService.signIn(socialPlatformProvider);
      const { isAvailable } = await this.clientService.getAvailableUserEmail(userData.email, socialPlatformProvider).toPromise();
      if(isAvailable) {
          //Ask to user for cellphone in case not having
          const cellPhoneModal = this.dialog.open(CellphoneRegisterComponent, {
            width: 'auto',
            height: '400px',
            disableClose: true
          });
          const cellPhoneForm: FormGroup = await cellPhoneModal.afterClosed().toPromise();
          //In case of not specifing cellphone, stop loggin process
          if (cellPhoneForm == null)
            return;
          const phone = cellPhoneForm.get('cellPhoneNumber').value;
          const engie = cellPhoneForm.get('isEngie').value;
          const tutenClientsIds = getClientIdsFromForm(engie, cellPhoneForm);
          const register: TutenClientProfileData = {
            authToken: userData.authToken,
            email: userData.email,
            firstName: userData.firstName,
            lastName: userData.lastName,
            name: userData.name,
            photoUrl: userData.photoUrl,
            provider: userData.provider,
            id: userData.id,
            idToken: userData.idToken,
            phone,
            engie,
            tutenClientsIds
          };
          await this.clientService.registerUser(register).toPromise();
          const loginResponse: TutenClientProfile = await this.clientService.loginClient(register.email, userData.id, userData.provider).toPromise();
          this.clientService.redirectLogin(loginResponse);
      } else {
        const response: TutenClientProfile = await this.clientService.loginClient(userData.email, userData.id, userData.provider).toPromise();
        this.clientService.redirectLogin(response);
      }

    } catch (error) {
    }
  }

  registerRSS() {

  }

  /**
   * Function to set weter is register
   * or login view
   */
  setTab() {
    this.tabIndex = this.clientService.getIsRegister() ? 1 : 0;
    this.clientService.setIsRegister(false);
  }
}



