import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ACTIONS_POLL } from "../../../shared/constants";
import { ClientService } from 'src/app/core/api';
import { SnackbarService } from 'src/app/core/api/snackbar.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-poll',
  templateUrl: './poll.component.html',
  styleUrls: ['./poll.component.scss']
})
export class PollComponent implements OnInit, OnDestroy {

  serviceId: number;
  userId: number;
  title: any[] = ['Calificar asistencia #'];
  actionsPolls = ACTIONS_POLL;
  componentsSubscr: Subscription

  constructor(
    private activate: ActivatedRoute,
    public router: Router
    ) {
  }

  ngOnInit() {
    this.componentsSubscr = this.activate.params.subscribe(params => {
      this.serviceId = JSON.parse(params.serviceId);
      this.userId = params.id;
    });
  }

  ngOnDestroy() {
    if(this.componentsSubscr && !this.componentsSubscr.closed)
      this.componentsSubscr.unsubscribe();
  }



}
