import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ClientService} from '../../../core/api/client/client.service';
import {BookingsService} from '../../../core/api/bookings/bookings.service';
import {PlansService} from '../../../core/api/plans/plans.service';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { TutenClientCoverageResponse } from 'src/app/core/api/plans/plans.interfaces';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss']
})
export class PlansComponent implements OnInit {
  plan: any = {};
  dataAvailibity: any;
  dispServiceDate: any;
  selectServices: any;
  tableAvailable: any = [];
  bookings: any;
  groups: any;
  groupsBookings: any;
  unmodifiedServices: any;
  services: any;
  show: boolean = false;
  componentsSubscription: Subscription = new Subscription();

  mapGroupImage = {
    Gas: 'assets/images/iconoherramienta-magenta.png',
    Hogar: 'assets/images/iconocasa-magenta.png',
    Medicina: 'assets/images/iconomedicina-magenta.png'
  }

  constructor(
              public clientService: ClientService,
              public bookingsService: BookingsService,
              private messageService: MessageService,
              private plansService: PlansService) {
    localStorage.removeItem('nameGroup');
  }

  ngOnInit() {
    this.getClientPlan();
    this.getServicesGroups();
    this.getServicesGroupsNoId();
  }

  ngOnDestroy() {
    this.componentsSubscription.unsubscribe();
  }

  async getClientPlan() {
    const plansSubscription = await this.plansService.getClientPlan().subscribe((response: any) => {
      if (response) {
        this.plan = response;
      }
    });
    try {
      const response:TutenClientCoverageResponse = await this.plansService.getClientCoverage().toPromise();
      this.dispServiceDate = moment(response.lastPeriodicityEndDate).format('DD/MM/YYYY');
    } catch (error) {
      console.log(error);
    }

    this.componentsSubscription.add(plansSubscription);
  }

  public getServicesGroupsNoId() {
    const getServicesSubsc = this.bookingsService.getServicesGroupsNoId().subscribe((response: any) => {
      if (response != undefined) {
        this.groupsBookings = response;
      }
    });
    this.componentsSubscription.add(getServicesSubsc);
  }

  getServicesGroups() {
    const serviceSubscr =
      this.bookingsService.getServicesGroups().subscribe((response: any) => {
        if (response !== undefined) {
          /**
           * [Crea grupos de servicios]
           */
          this.groups = [];
          for (var i = 0; i < response.length; i++) {
            this.groups.push({
              name: response[i].name,
              id: response[i].id,
              availableBookings: null,
              services: [],
              icon: this.mapGroupImage[response[i].name]
            });
          }
        }
        this.getAvailabilityCoverages();
      });
    this.componentsSubscription.add(serviceSubscr);
  }

  public getAvailabilityCoverages() {
    this.clientService.getAvailabilityCoverages().subscribe((response: any) => {
      if (response !== undefined) {
        this.dataAvailibity = response;
        let linkedServicesIds = [];
        let productsGroupsIds = [];

        /**
         * [ID precios preferenciales o linked services ]
         */
        for (var i = 0; i < response.length; i++) {
          if (response[i].linkedService != null) {
            linkedServicesIds.push(response[i].linkedService.id);
          }
        }

        /**
         * [productos por grupo]
         */
        for (var i = 0; i < response.length; i++) {
          if (response[i].type == 'GROUP') {
            productsGroupsIds.push(response[i].id);
            for (var f = 0; f < this.groups.length; f++) {
              if (this.groups[f].id == response[i].id) {
                this.groups[f].productId = response[i].productId;
                this.groups[f].grouptId = response[i].grouptId;
                this.groups[f].available = response[i].available;
                this.groups[f].isUnlimited = response[i].isUnlimited;
                this.groups[f].coveredAmount = response[i].coveredAmount;
                this.groups[f].requiresAsignation = response[i].requiresAsignation;
                this.groups[f].isInmediate = response[i].isInmediate;
                this.groups[f].resumeId = response[i].resumeId;
              }

            }
          }
        }

        /**
         * [cobertura por ramo]
         */
        this.selectServices = {...this.groups};
        for (var a = 0; a < this.selectServices.length; a++) {
          if (productsGroupsIds.indexOf(this.selectServices[a].id) >= 0) {
            for (var i = 0; i < response.length; i++) {
              if (this.selectServices[a].id == response[i].groupId) {
                /**
                 * [coloca el producto si ilimitado o si es limitado con disponibilidad mayor a cero]
                 */
                if ((linkedServicesIds.indexOf(response[i].id) == -1 && this.selectServices[a].isUnlimited == 'ilimitado') || (linkedServicesIds.indexOf(response[i].id) == -1 && this.selectServices[a].available > 0)) {
                  response[i].linkedService = null;
                  response[i].isLinkedService = false;
                  this.selectServices[a].services.push(response[i]);
                }

                /**
                 * [coloca el linkedservice si tiene y la disponibildad menor igual a 0]
                 */
                if (this.selectServices[a].isUnlimited == 'limitado' && linkedServicesIds.indexOf(response[i].id) >= 0 && this.selectServices[a].available <= 0) {
                  this.selectServices[a].services.push({
                    id: response[i].id,
                    name: response[i].name,
                    available: null,
                    type: 'SERVICE',
                    groupId: this.selectServices[a].id,
                    productId: null,
                    isUnlimited: 'ilimitado',
                    coveredAmount: this.selectServices[a].coveredAmount,
                    isInmediate: this.selectServices[a].isInmediate,
                    requiresAsignation: response[i].requiresAsignation,
                    linkedService: null,
                    isLinkedService: true
                  });
                }
              }
            }
          }
        }

        /**
         * [productos por servicio]
         */
        for (var i = 0; i < response.length; i++) {
          for (var a = 0; a < this.selectServices.length; a++) {
            if (this.selectServices[a].id == response[i].groupId && response[i].type == 'SERVICE') {
              /**
               * [coloca el producto si ilimitado o si es limitado con disponibilidad mayor a cero]
               */
              if (response[i].isUnlimited == 'ilimitado' || (response[i].isUnlimited == 'limitado' && response[i].available > 0)) {
                response[i].linkedService = null;
                response[i].isLinkedService = false;
                this.selectServices[a].services.push(response[i]);
              }

              /**
               * [coloca el linkedservice si tiene y la disponibildad menor igual a 0]
               */
              if (response[i].isUnlimited == 'limitado' && response[i].available <= 0 && response[i].linkedService != null) {
                this.selectServices[a].services.push({
                  id: response[i].linkedService.id,
                  name: response[i].linkedService.name,
                  available: this.selectServices[a].available,
                  type: 'SERVICE',
                  groupId: this.selectServices[a].id,
                  productId: null,
                  isUnlimited: 'ilimitado',
                  coveredAmount: response[i].linkedService.amountCovered,
                  isInmediate: response[i].linkedService.isInmediate,
                  requiresAsignation: response[i].linkedService.requiresAsignation,
                  linkedService: null,
                  isLinkedService: true
                });
              }
            }
          }
        }

        /**
         * [Arma objeto de tabla de disponibilidad cobertura por ramo]
         */
        this.tableAvailable = this.groups;
        for (var a = 0; a < this.tableAvailable.length; a++) {
          if (productsGroupsIds.indexOf(this.tableAvailable[a].id) >= 0) {
            for (var i = 0; i < response.length; i++) {
              if (this.tableAvailable[a].id == response[i].groupId) {
                /**
                 * [coloca el producto si ilimitado o si es limitado con disponibilidad mayor a cero]
                 */
                if (linkedServicesIds.indexOf(response[i].id) == -1) {
                  response[i].linkedService = null;
                  response[i].isLinkedService = false;
                  this.tableAvailable[a].services.push(response[i]);
                }
              }
            }
          }
        }

        /**
         * [Arma objeto de tabla de disponibilidad cobertura por servicios]
         */
        for (var i = 0; i < response.length; i++) {
          for (var a = 0; a < this.tableAvailable.length; a++) {
            if (this.tableAvailable[a].id == response[i].groupId && response[i].type == 'SERVICE') {
              /**
               * [coloca el producto si ilimitado o si es limitado con disponibilidad mayor a cero]
               */
              if (response[i].isUnlimited == 'ilimitado' || (response[i].isUnlimited == 'limitado' && linkedServicesIds.indexOf(response[i].id) == -1)) {
                response[i].linkedService = null;
                response[i].isLinkedService = false;
                this.tableAvailable[a].services.push(response[i]);
              }
            }
          }
        }
      }
    }, error => {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "No se realizo la acción", //TODO verificar mensaje de error
      });
    });
  }

}
