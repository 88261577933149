import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { ClientService } from "../../../core/api/client/client.service";
import { SnackbarService } from 'src/app/core/api/snackbar.service';
import { Subscription } from 'rxjs';
import {MESSAGE_TIMES} from '../../shared/constants';

@Component({
  selector: 'app-help-request',
  templateUrl: './help-request.component.html',
  styleUrls: ['./help-request.component.scss']
})
export class HelpRequestComponent implements OnInit, OnDestroy {

  serviceId: number;
  title: any[] = ['Asistencia'];
  request: any = { "bookingId": 0, "problem": "", "fullname": "", "contactPhone": "" };
  componentSubscr: Subscription = new Subscription();

  constructor(private _location: Location,
    private activate: ActivatedRoute,
    private snackBarService: SnackbarService,
    private clientService: ClientService) {
  }

  ngOnInit() {
    const activatedRouteSubsc = this.activate.params.subscribe(parametros => {
      this.serviceId = JSON.parse(parametros.serviceId);
      this.request.bookingId = this.serviceId;
      this.request.fullname = localStorage.getItem('nameuser') != null && localStorage.getItem('apellidouser') != null ? localStorage.getItem('nameuser') + ' ' + localStorage.getItem('apellidouser') : '';
      this.request.contactPhone = localStorage.getItem('telefonouser') != null ? localStorage.getItem('telefonouser') : '';
    });

    this.componentSubscr.add(activatedRouteSubsc);
  }

  ngOnDestroy() {
    if (this.componentSubscr && !this.componentSubscr.closed)
      this.componentSubscr.unsubscribe();
  }

  backClicked() {
    this._location.back();
  }

  sendHelpRequest() {
    const helpResquestSubscr =
      this.clientService.sendHelpRequest(this.request).subscribe((response: any) => {
        this.snackBarService.openSnackBar(MESSAGE_TIMES.SUCCESS_MESSAGE, 'Se ha enviado la consulta exitosamente', '', 'Exito', false);
        this._location.back();
      }, err => {
        this.snackBarService.openSnackBar(MESSAGE_TIMES.ERROR_MESSAGE, err.error.error, '', 'Advertencia', false);
      });
    this.componentSubscr.add(helpResquestSubscr);
  }
}
