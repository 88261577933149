
import { Action } from '@ngrx/store';

export const SET_NOTIFICATIONS_UNREADS = '[Notifications] Set notifications unreads';
export const INCREMENT_UNREADS = '[Notifications] Increment notifications unredas';
export const DECREMENT_UNREADS = '[Notifications] Decrement notifications unredas';
export const RESET_UNREADS = '[Notifications] Reset notifications unredas';
export const SET_ALL_NOTIFICATIONS = '[Notifications] Set all notifications';
export const UPDATE_NOTIFICATION = '[Notifications] Update notification'


export class SetNotificationsUnreadActions implements Action {
  readonly type = SET_NOTIFICATIONS_UNREADS;

  constructor (public payload: any) {};
}

export class IncrementNotificationsUnreadActions implements Action {
  readonly type = INCREMENT_UNREADS;
  constructor (public payload: any) {};
}

export class DecrementNotificationsUnreadActions implements Action {
  readonly type = DECREMENT_UNREADS;
  constructor (public payload: any) {};
}

export class ResetNotificationsUnreadActions implements Action {
  readonly type = RESET_UNREADS;
}

export class SetAllNotificationsActions implements Action {
  readonly type = SET_ALL_NOTIFICATIONS;
  constructor (public payload: any) {};
}

export class UpdateNotificationActions implements Action {
  readonly type = UPDATE_NOTIFICATION;
  constructor (public payload: any) {};
}

export type actions = SetNotificationsUnreadActions
| IncrementNotificationsUnreadActions
| DecrementNotificationsUnreadActions
| ResetNotificationsUnreadActions
| SetAllNotificationsActions
| UpdateNotificationActions;

