import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { API_MAIN_URL } from "../../shared/constants";
import { Location } from "@angular/common";
import { BookingsService } from "../../../core/api/bookings/bookings.service";
import { Subscription } from "rxjs";
import { ACTIONS_POLL } from "../../shared/constants";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { MatDialog } from '@angular/material';
import { CancelAssistanceComponent } from './cancel-assistance/cancel-assistance.component';
import { ApproveDialogComponent } from '../shared/approve-dialog/approve-dialog.component';
import { RejectBudgetDialogComponent } from '../shared/reject-budget-dialog/reject-budget-dialog.component';

import { AssistanceObserver } from 'src/app/core/api/bookings/booking.interfaces';

import { COST_LINES } from '../../shared/constants'
import { mapImageService } from 'src/app/contants/app.contants';
import { DialogService, MessageService } from 'primeng/api';
import { ReagendarComponent } from '../reagendar/reagendar.component';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/interfaces/app.interface';
import * as actionTypes from 'src/app/actions/app.actions';

const statusStepColor = {
  red: 'status-step red-circle',
  yellow: 'status-step yellow-circle',
  green: 'status-step green-circle',
  blue: 'status-step blue-circle'
}

const statusBudgetStepColor = {
  red: 'status-budget-step red-circle',
  yellow: 'status-budget-step yellow-circle',
  green: 'status-budget-step green-circle',
  blue: 'status-budget-step blue-circle'
}

declare const google: any;
@Component({
  selector: 'app-assistance',
  templateUrl: './assistance.component.html',
  styleUrls: ['./assistance.component.scss']
})
export class AssistanceComponent implements OnInit, OnDestroy {

  imageService: 'assets/images/empty.jpg';

  // Posicion Inicial del Mapa
  lat: number = 19.413957576622426;
  lng: number = -99.15108269687744;
  private geocoder: any;
  url: string = API_MAIN_URL;
  actionsPoll = ACTIONS_POLL;
  bookingId: number;
  userId: number;
  poll: any;
  disableRescheduleBookingButton: boolean = false;
  getPollSubscription: Subscription;
  submitPollSubscription: Subscription;
  bookingSubscription: Subscription;
  panelOpenState = false;
  pollForm: FormGroup;
  controlNamesArr: any[] = [];
  comments: any;
  notAttended: boolean;
  pollError: string = "";
  pollNotResponse: boolean = false;
  pollPending: boolean;
  booking: any;
  byScheduled: boolean;
  scheduled: boolean;
  accepted: boolean;
  onWay: boolean;
  onArrival: boolean;
  initiated: boolean;
  waitBudget: boolean;
  supplierBudget: boolean;
  operationsBudget: boolean;
  clientBudget: boolean;
  approvedBudget: boolean;
  rejectedBudget: boolean;
  checkBudget: boolean;
  finished: boolean;
  finishedCanceledClient: boolean;
  sla: any;
  iconCheckByScheduled: boolean = false;
  iconCheckScheduled: boolean = false;
  iconCheckAccepted: boolean = false;
  iconCheckOnWay: boolean = false;
  iconCheckArrival: boolean = false;
  iconCheckInitiated: boolean = false;
  iconCheckWaitBudget: boolean = false;
  iconCheckSupplierBudget: boolean = false;
  iconCheckOperationsBudget: boolean = false;
  iconCheckClientBudget: boolean = false;
  iconCheckApprovedBudget: boolean = false;
  iconCheckRejectedBudget: boolean = false;
  iconCheckBudget: boolean = false;
  iconCheckFinished: boolean = false;
  iconCheckFinishedCanceledClient: boolean = false;
  disableCancelBookingButton: boolean;
  dialogSubscription: Subscription;
  cancelBookingSubscription: Subscription;
  serviceDefinitionsSubscription: Subscription;
  contractorAssignSubscription: Subscription;
  budgetApprove: boolean = false;
  runtime: any;
  stateNoShow: boolean = true;
  expande: boolean = false;
  finishedOrCancelled: string = 'Finalizado';

  username: string = 'AU';

  budget: any;
  items: any[] = [];
  showBudgetState: boolean = true;
  ivaMexico: 0.16;
  dialogSubcription: Subscription;
  showReagendar: boolean = false;
  professionalSkills: string[] = [];
  professionalId: any;
  servicesDefinitions: any = [];
  unmodificableServices: any;
  answeredPoll: boolean = false;

  isMedicineBooking: boolean = false;
  professionalPhoto: string = 'assets/images/profile_empty.jpg';
  actionInProgress: boolean = false;

  constructor(private activate: ActivatedRoute,
    public router: Router,
    private _location: Location,
    private messageService: MessageService,
    private bookingService: BookingsService,
    private dialogRef: MatDialog,
    public dialogService: DialogService,
    private store: Store<AppState>

  ) {
    this.activate.parent.params.subscribe(parametros => {
      this.userId = parametros.id;
    });

    this.activate.params.subscribe(parametros => {
      this.bookingId = JSON.parse(parametros.serviceId);
    });

    this.activate.queryParams.subscribe(params => {
      if (params.message != undefined && params.message != null && params.message != "") {
        this.messageService.add({severity: 'success', summary:'Éxito', detail:params.message});
      }
    });
  }

  ngOnInit() {

    this.getPollsByServiceId();
    this.getHelpRequets();
    this.getBookingDefinitions();
    this.budgetToApprove();

  }

  openReagendar(): void {
    const ref = this.dialogService.open(ReagendarComponent, {
      header: 'Reagendar Solicitud',
      width: '100%',
      contentStyle: {"max-height": "550px", "overflow": "auto"},
      closeOnEscape: false,
      closable: false,
      data: {
        bookingId: this.bookingId,
        bookingDefinition: this.booking.definition,
        assistanceStart: this.booking.bookingDate,
        assistanceEnd: this.booking.closeDate
      },
    });

    this.closeDialogSubscr();

    this.dialogSubcription = ref.onClose.subscribe((result) => {
      if (!result) return;
      this.router.navigate(['/dashboard/exito'], { queryParams: { id: this.bookingId } })
      this.messageService.add({severity: 'success', summary:'Éxito', detail: 'La asistencia ha sido reagendada correctamente.'});
    });
  }

  ngOnDestroy() {
    this.unsubscribeSubscription(this.dialogSubscription);
    this.unsubscribeSubscription(this.cancelBookingSubscription);
    this.unsubscribeSubscription(this.submitPollSubscription);
    this.unsubscribeSubscription(this.getPollSubscription);
    this.unsubscribeSubscription(this.bookingSubscription);
    this.unsubscribeSubscription(this.serviceDefinitionsSubscription);
    this.unsubscribeSubscription(this.serviceDefinitionsSubscription);
  }

  budgetToApprove() {
    this.store.dispatch(new actionTypes.OpenLoadingActions());
    this.bookingService.getBudgetForApprove(this.bookingId).subscribe(response => {
      this.store.dispatch(new actionTypes.CloseLoadingActions());
      if (response != null && response['excessPaid'] > 0) {
        this.budgetApprove = true;
        this.budget = response;
        let time = moment.duration(this.budget['runtimeMin'] * 60000);
        this.runtime = time.hours() + ':' + time.minutes();

        if (this.budget['workforce'] == null) {
          this.budget['workforce'] = '-'
        }
        if (this.budget['subtotal'] == null) {
          this.budget['subtotal'] = '-'
        }
        if (this.budget['iva'] == null) {
          this.budget['iva'] = '-'
        }
        if (this.budget['total'] == null) {
          this.budget['total'] = '-'
        }
        if (this.budget['coveredAmount'] == null) {
          this.budget['coveredAmount'] = '-'
        }
        if (this.budget['visitCost'] == null) {
          this.budget['visitCost'] = '-'
        }

        this.items = response['budgetSkus'];
        if (this.items['subtotal'] == null) {
          this.items['subtotal'] = '-'
        }
        this.budget['workforce'] = (this.budget['workforce']/(1+0.16));
        this.budget['visitCost'] = (this.budget['visitCost']/(1+0.16));
      } else {
        this.budgetApprove = false;
      }

    });
  }

  unsubscribeSubscription(subscription: Subscription): void {
    if (subscription && !subscription.closed)
      subscription.unsubscribe();
  }

  backClicked() {
    this._location.back();
  }

  reagendar() {
    this.router.navigate([`dashboard/reagendar/${this.bookingId}`])
  }

  multiSelectionPoll(index, value) {
    if(!this.answeredPoll){
      let previousControl = this.pollForm.controls[this.controlNamesArr[index]].value;
      if(previousControl == null) {
      previousControl = [];
      }

      let indexValue = previousControl.findIndex(e => e == value.id);

      if(~indexValue) {
        previousControl.splice(indexValue, 1);
      } else {
        previousControl.push(value.id)
      }
      this.pollForm.controls[this.controlNamesArr[index]].setValue(previousControl);
    }
  }

  selectedPollOption(index, value) {
    if(!this.answeredPoll){
      this.pollForm.controls[this.controlNamesArr[index]].setValue(value.id);
    }
  }

  getPollsByServiceId() {
    this.store.dispatch(new actionTypes.OpenLoadingActions());
    this.getPollSubscription =
      this.bookingService.getPollByBookingId(this.bookingId).subscribe(response => {
        this.store.dispatch(new actionTypes.CloseLoadingActions());
        const { response: answeredPoll } = response;
        const isAnswered = Boolean(answeredPoll);
        this.answeredPoll = isAnswered;
        const poll = isAnswered ? JSON.parse(answeredPoll) : response;
        this.poll = poll.sort((a,b)=> (a.scale > b.scale ? 1 : -1));
        this.setPollForm(isAnswered);
      }, err => {
        this.pollError = err.error.error;
        this.store.dispatch(new actionTypes.CloseLoadingActions());
      })
  }

  isSelected(questions, elementId) {
    let index = JSON.parse(questions.value).findIndex(e => e === elementId);
    if (~index) {
      return true;
    }
    return false;
  }

  setPollForm(isAnswered: boolean): void {
    this.controlNamesArr = [];
    let formControlsObj = {};

    this.poll.forEach((question, index) => {
      const { required: r, value: v } = question;
      // The validation of the value is in case of empty string
      let value = isAnswered && Boolean(v) ? JSON.parse(v) : null;

      if (question.action === 5) // Element date
      {
        if (value != null && value.split(" ").length > 1)
          value = value.split(" ")[0].split("-")[2] + "-" + value.split(" ")[0].split("-")[1] + "-" + value.split(" ")[0].split("-")[0] + "T" + value.split(" ")[1] + ":00.000Z";
      }

      if (question.action == 6) // Element select simple
        value = parseInt(value);

      if (question.action == 8) // Element radio button
      {
        let index = question.value ? parseInt(question.value.replace('"', '')) : null;
        if (index != null) {
          for (let i = 0; i < question.options.length; i++) {
            if (parseInt(question.options[i].name) == index) {
              value = question.options[i].id;
              break;
            }
          }
        };
      }

      const validators = r ? [Validators.required, this.noWhitespaceValidator] : null;
      const controlName = isAnswered ? index : question.id;
      this.controlNamesArr.push(controlName);
      formControlsObj[controlName] = new FormControl(value, validators);
    });
    this.pollForm = new FormGroup(formControlsObj);
    // If have answered Poll is a readOnly Form
    if (isAnswered) {
      this.pollForm.disable();
      this.pollNotResponse = true;
    } else {
      this.pollPending = true;
    }
  }

  noWhitespaceValidator(control: FormControl) {
    console.log('control:', control.value);
    if(control.value != null && !isNaN(control.value)){
      return null;
    }
    if(typeof control.value !== "string") return null;
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}

  onPollSubmit(event: any) {
    const answeredPoll = Object.keys(this.pollForm.controls).map(key => {
      const value =
        this.pollForm.get(key).value ? JSON.stringify(this.pollForm.get(key).value) : "";
      const actualPoll = this.poll.find(({ id }) => id == key);
      delete actualPoll.id;
      delete actualPoll.creationDate;
      delete actualPoll.pollId;
      return { ...actualPoll, value };
    });
    this.actionInProgress = true;
    this.store.dispatch(new actionTypes.OpenLoadingActions());
    this.submitPollSubscription = this.bookingService.submitPollResponse(this.bookingId, answeredPoll)
      .subscribe(
        res => {
          this.store.dispatch(new actionTypes.CloseLoadingActions());
          this.pollForm.disable();
          this.answeredPoll = true;
          this.messageService.add({severity: 'success', summary:'Éxito', detail: 'La encuesta se ha respondido'});
          this.actionInProgress = false;
        },
        err => { 
          this.messageService.add({severity: 'error', summary:'Error', detail: 'No se respondio la encuesta'});
          this.actionInProgress = false;
          this.store.dispatch(new actionTypes.CloseLoadingActions());
        });
  }

  orderBy(arr: any[], prop: string) {
    return arr.sort((a, b) => {
      return Number(a[prop]) > Number(b[prop]) ? 1 : -1
    }
      );
  }

  getHelpRequets() {
    this.store.dispatch(new actionTypes.OpenLoadingActions());
    this.bookingService.getHelpRequets(this.bookingId).subscribe(response => {
      this.store.dispatch(new actionTypes.CloseLoadingActions());
      this.comments = response;
      const commentsNotAttended = this.comments.filter(c => c.checked === false);
      this.notAttended = commentsNotAttended.length > 0;
    });
  }
  helpRequest() {
    if (!this.notAttended) {
      this.router.navigate([`dashboard/asistencia/${this.bookingId}/help-request`])
    } else {
      this.messageService.add({severity: 'success', summary:'Éxito', detail: 'Ya posee consultas sin atender. En breve lo contáctaremos.'});
      }
  }

  canShowBudgetSLA(book) {
    this.showBudgetState = !(
      book.costLines.includes(COST_LINES.COST_WORKFORCE) ||
      book.costLines.includes(COST_LINES.COST_TWARRANT)
      )
  }

  getBooking() {
    this.store.dispatch(new actionTypes.OpenLoadingActions());
    this.bookingSubscription = this.bookingService.getBooking(this.bookingId).subscribe(response => {
      this.store.dispatch(new actionTypes.CloseLoadingActions());
      this.booking = response;
      if(this.booking.caso.category == "Telemedicina") {
        this.isMedicineBooking = true;
        // TODO a.guzman borrar todo de esta linea hacia abajo, luego de resolver backend con telemedicina
        this.booking.professional = 'AXA Assistance';
        this.booking.professionalEmail = 'n/a';
        this.booking.professionalPhone = 'n/a';
        this.professionalSkills = ['Orientación médica teléfono', 'Médico a domicilio', 'Orientación nutricional teléfono',
      'Orientación psicológica teléfono', 'Video llamada nutrición']
      }

      
      this.getImageByService();
      this.lat = this.booking.latitude;
      this.lng = this.booking.longitude;
      if(this.booking.contactName != null) {
        let nameSplitted = this.booking.contactName.split(" ").filter(e => e !== "");
        if(nameSplitted.length == 1) {
          this.username = `${nameSplitted[0][0]}`.toUpperCase();
        } else if (nameSplitted.length > 1) {
          this.username = `${nameSplitted[0][0]}${nameSplitted[1][0]}`.toUpperCase();
        }
      }
      switch (this.booking.colorSLA) {
        case 1:
          this.sla = "green";
          break;
        case 2:
          this.sla = "yellow";
          break;
        case 3:
          this.sla = "red";
          break;
        case null:
          this.sla = 'blue';
          break;
      }
      this.setCancelButton(response);
      this.calculateDate(response);
      this.emitToBookingObservable(response);
      this.canShowBudgetSLA(response);
      this.professionalId = response['professionalId'];

      switch (this.booking.state) {
        case 9: //por agendar
          this.byScheduled = true;
          break;
        case 10: // agendado
          this.scheduled = true;
          this.iconCheckByScheduled = true;
          break;
        case 1: // aceptado
          this.accepted = true;
          this.iconCheckByScheduled = true;
          this.iconCheckScheduled = true;
          break;
        case 5: // en camino
          this.onWay = true;
          this.iconCheckByScheduled = true;
          this.iconCheckScheduled = true;
          this.iconCheckAccepted = true;
          break;
        case 14: // en arribo
          this.onWay = true;
          this.iconCheckByScheduled = true;
          this.iconCheckScheduled = true;
          this.iconCheckAccepted = true;
          this.iconCheckOnWay = true;
          this.onArrival = true;
          break;
        case 4: // iniciado
          this.initiated = true;
          this.iconCheckByScheduled = true;
          this.iconCheckScheduled = true;
          this.iconCheckAccepted = true;
          this.iconCheckOnWay = true;
          this.iconCheckArrival = true;
          this.onWay = true;
          break;
        case 6: // en espera de presupuesto
          this.waitBudget = true;
          this.iconCheckByScheduled = true;
          this.iconCheckScheduled = true;
          this.iconCheckAccepted = true;
          this.iconCheckOnWay = true;
          this.onWay = true;
          this.iconCheckArrival = true;
          this.iconCheckInitiated = true;
          break;
        case 7: // presupuesto en proveedor
          this.supplierBudget = true;
          this.iconCheckByScheduled = true;
          this.onWay = true;
          this.iconCheckScheduled = true;
          this.iconCheckAccepted = true;
          this.iconCheckOnWay = true;
          this.iconCheckInitiated = true;
          this.iconCheckArrival = true;
          this.iconCheckWaitBudget = true;
          // this.iconCheckSupplierBudget = true;
          break;
        case 8: // presupuesto en operaciones
          this.operationsBudget = true;
          this.iconCheckByScheduled = true;
          this.iconCheckScheduled = true;
          this.onWay = true;
          this.iconCheckAccepted = true;
          this.iconCheckOnWay = true;
          this.iconCheckArrival = true;
          this.iconCheckInitiated = true;
          this.iconCheckWaitBudget = true;
          this.iconCheckSupplierBudget = true;
          // this.iconCheckOperationsBudget = true;
          break;
        case 11: // presupuesto en cliente // pintar aqui
          this.clientBudget = true;
          this.iconCheckByScheduled = true;
          this.iconCheckScheduled = true;
          this.iconCheckArrival = true;
          this.iconCheckAccepted = true;
          this.onWay = true;
          this.iconCheckOnWay = true;
          this.iconCheckInitiated = true;
          this.iconCheckWaitBudget = true;
          this.iconCheckSupplierBudget = true;
          this.iconCheckOperationsBudget = true;
          break;
        case 12: // presupuesto aprobado por el cliente
          this.approvedBudget = true;
          this.iconCheckByScheduled = true;
          this.iconCheckScheduled = true;
          this.iconCheckAccepted = true;
          this.iconCheckOnWay = true;
          this.onWay = true;
          this.iconCheckArrival = true;
          this.iconCheckInitiated = true;
          this.iconCheckWaitBudget = true;
          this.iconCheckSupplierBudget = true;
          this.iconCheckOperationsBudget = true;
          this.iconCheckClientBudget = true;
          break;
        case 13: // presupuesto rechazado por el cliente
          this.rejectedBudget = true;
          this.iconCheckByScheduled = true;
          this.iconCheckScheduled = true;
          this.iconCheckAccepted = true;
          this.iconCheckOnWay = true;
          this.onWay = true;
          this.iconCheckInitiated = true;
          this.iconCheckArrival = true;
          this.iconCheckWaitBudget = true;
          this.iconCheckSupplierBudget = true;
          this.iconCheckOperationsBudget = true;
          this.iconCheckClientBudget = true;
          break;
        case 3: // finalizado satisfactoriamente
          this.finished = true;
          this.iconCheckByScheduled = true;
          this.operationsBudget = true;
          this.iconCheckScheduled = true;
          this.onWay = true;
          this.iconCheckAccepted = true;
          this.iconCheckOnWay = true;
          this.iconCheckArrival = true;
          this.iconCheckInitiated = true;
          this.iconCheckWaitBudget = true;
          this.iconCheckSupplierBudget = true;
          this.iconCheckOperationsBudget = true;
          this.iconCheckFinished = true;
          break;
        case -5: // finalizado con incidencia
          this.finished = true;
          this.iconCheckByScheduled = true;
          this.operationsBudget = true;
          this.iconCheckScheduled = true;
          this.iconCheckAccepted = true;
          this.iconCheckOnWay = true;
          this.onWay = true;
          this.iconCheckInitiated = true;
          this.iconCheckWaitBudget = true;
          this.iconCheckArrival = true;
          this.iconCheckSupplierBudget = true;
          this.iconCheckOperationsBudget = true;
          this.iconCheckFinished = true;
          break;
        case -2: // cancelado por el cliente
          this.finishedCanceledClient = true;
          //this.onWay = true;

          this.finished = true;
          this.iconCheckByScheduled = true;
          this.operationsBudget = true;
          this.iconCheckScheduled = true;
          this.iconCheckAccepted = true;
          this.iconCheckOnWay = true;
          this.onWay = true;
          this.iconCheckInitiated = true;
          this.iconCheckWaitBudget = true;
          this.iconCheckArrival = true;
          this.iconCheckSupplierBudget = true;
          this.iconCheckOperationsBudget = true;
          this.iconCheckFinished = true;
          this.finishedOrCancelled = 'Cancelado por el cliente';
          break;

      }

      if (this.iconCheckWaitBudget == true || this.iconCheckSupplierBudget == true || this.iconCheckOperationsBudget == true) {
        this.checkBudget = true;
      }

      // TODO a.guzman borrar condicional (dejar los gets) cuando se integren en backend los cambios de telemedicina booking
    if(!this.isMedicineBooking) {
      this.getProfessionalSkills(); 
    }
    this.getProfessionalPicture();
    });

  }

  getBookingDefinitions(): void {
    this.store.dispatch(new actionTypes.OpenLoadingActions());
    this.serviceDefinitionsSubscription = this.bookingService.getServicesDefinition().subscribe(data => {
      this.store.dispatch(new actionTypes.CloseLoadingActions());
      if(data != null) {
        this.servicesDefinitions =  JSON.parse(JSON.stringify(data));
        this.getBooking();
      }
    });
  }

  getProfessionalSkills() {
    var aux = [];
    this.store.dispatch(new actionTypes.OpenLoadingActions());
    this.contractorAssignSubscription = this.bookingService.getBookingContractorsAssign(this.professionalId).subscribe((data: any) => {
      this.store.dispatch(new actionTypes.CloseLoadingActions());
      if(data != null) {
        this.unmodificableServices = JSON.parse(data['professionalServices']);
        this.unmodificableServices.map(service => {
          var index = this.servicesDefinitions.findIndex(definition => definition.id == service);
          if(~index) {
            aux.push(this.servicesDefinitions[index]['name']);
          }
        });
      }
      this.professionalSkills = _.cloneDeep(aux);
    });
  }

  getProfessionalPicture() {
    this.store.dispatch(new actionTypes.OpenLoadingActions());
    this.contractorAssignSubscription = this.bookingService.getProfessionalPicture(this.professionalId).subscribe(
      (data: any) => {
        this.store.dispatch(new actionTypes.CloseLoadingActions());
        if(data !=null) {
          this.professionalPhoto = data.picture;
        }
      }
    )
  }

  emitToBookingObservable(response: any): void {
    const assistanceRelevantData: AssistanceObserver = {
      assistanceId: response.id,
      assistanceStatus: response.state
    };
    this.bookingService.emitAssitance(assistanceRelevantData);
  }

  setCancelButton(booking: any): void {
    if (!booking) {
      return;
    }

    const invalidStates = {
      canceledByClient: -2,
      finishedSuccesfully: 3,
      finishedWithIncidence: -5,
      waitForBudget: 7,
      budgetInClient: 11,
      budgedAprovatedByTheClient: 12,
    };
    const invalidState = Object.values(invalidStates).includes(booking.state);
    this.disableCancelBookingButton = invalidState;
  }

  calculateDate(data) {
    const dateNow = moment();
    const dateBookingService = moment(data.bookingDate);
    if (dateBookingService.diff(dateNow, 'hours') <= 2) {
      this.disableRescheduleBookingButton = true;
    } else if (this.booking.state == -2 || this.booking.state == -5 || this.booking.state == 3 || this.booking.state == 9) {
      this.disableRescheduleBookingButton = true
    }
  }

  closeDialogSubscr(): void {
    if (this.dialogSubcription && !this.dialogSubcription.closed)
      this.dialogSubcription.unsubscribe();
  }

  onCancelButtonClick(): void {
    const ref = this.dialogService.open(CancelAssistanceComponent, {
      header: 'Cancelar Solicitud',
      width: "90%",
      closeOnEscape: false,
      closable: false,
      data: {
        bookingId: this.booking.id,
        email: this.booking.email
      },
    });

    this.closeDialogSubscr();

    this.dialogSubcription = ref.onClose.subscribe((data) => {
      if (data == null) return;
      this.booking = data;
      this.setCancelButton(data);
      this.getBooking();
    });
  }

  approve() {
    this.store.dispatch(new actionTypes.OpenLoadingActions());
    this.bookingService.approveBudget(this.bookingId).subscribe(res => {
      this.store.dispatch(new actionTypes.CloseLoadingActions());
      this.ngOnInit();
    });
  }

  openCallDialog(): void {
    const dialogRef = this.dialogRef.open(ApproveDialogComponent, {
      width: '300px',
      data: { bookingID: this.booking.id }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

  openCallRejectBudgetDialog(): void {
    const dialogRef = this.dialogRef.open(RejectBudgetDialogComponent, {
      width: '300px',
      data: { bookingID: this.booking.id }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

  goChat(): void {
    this.router.navigate([`dashboard/asistencia/${ this.bookingId }/chat`]);
  }


  getStatusStepColor(useDefault: boolean = true) {
    if(useDefault) {
      return 'status-step';
    } else {
    return statusStepColor[this.sla] ? statusStepColor[this.sla] : 'status-step'
    }
  }

  getStatusBudgetStepColor(useDefault: boolean = true) {
    if(useDefault) {
      return 'status-budget-step';
    } else {
      return statusBudgetStepColor[this.sla] ? statusBudgetStepColor[this.sla] : 'status-budget-step'
    }
  }

  getImageByService() {
    if(this.booking.definition.picture != null) {
      this.imageService = this.booking.definition.picture;
    } else {
      this.imageService = mapImageService[this.booking.definition.id] ? mapImageService[this.booking.definition.id] : "assets/images/empty.jpg";
    }
  }
}
