import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { ClientService } from "../../core/api/client/client.service";
import { SnackbarService } from 'src/app/core/api/snackbar.service';
import { Location } from "@angular/common";
import {TutenEngieServi2Account} from '../../core/api/client/client-interfaces';
import {FormControl, FormGroup,Validators} from '@angular/forms';
import {MatInput} from '@angular/material';
import {MESSAGE_TIMES} from '../shared/constants';

@Component({
  selector: 'app-servi2-account-details',
  templateUrl: './servi2-account-details.component.html',
  styleUrls: ['./servi2-account-details.component.scss']
})
export class Servi2AccountDetailsComponent implements OnInit {

  @ViewChild('nombre') nameInput: MatInput;

  accoundId:number;
  account :any;
  tutenEngieServi2Account: TutenEngieServi2Account;
  detailsAccount: FormGroup;
  datos: any;
  hide = true;
  edit = false;
  panelOpenState = false;

  constructor(private route: ActivatedRoute,
              private _location: Location,
              private snackBarService: SnackbarService,
              private clientService: ClientService,
              private router: Router) {

    this.detailsAccount = new FormGroup({
      'nombre': new FormControl(''),
      'apellido': new FormControl(''),
      'telefono1': new FormControl('',[,Validators.minLength(10),Validators.maxLength(10)]),
      'telefono2': new FormControl('',[Validators.minLength(10),Validators.maxLength(10)]),
      'password': new FormControl('')
    });

  }

  ngOnInit() {
  	this.route.params.subscribe(params => {
      this.accoundId = +params['id'];
    });
    this.getServi2Account();
  }

  getServi2Account(){
    this.clientService.getServi2Account(this.accoundId).subscribe((response: any) => {
      this.account = response;
      this.detailsAccount.controls['nombre'].setValue(this.account.Nombre);
      this.detailsAccount.controls['apellido'].setValue(this.account.Apellidos);
      this.detailsAccount.controls['telefono1'].setValue(this.account.Telefono1);
      this.detailsAccount.controls['telefono2'].setValue(this.account.Telefono2);
    }, err => {
      this.snackBarService.openSnackBar(MESSAGE_TIMES.ERROR_MESSAGE, err.error.error, '', 'Advertencia', false);
    });
  }

  editar() {
    this.edit = !this.edit;
  }

  cancelar() {
    this.edit = !this.edit;
  }

  enviar() {
    if (this.detailsAccount.invalid) {
      return;
    }
    this.datos = {
      nombre: this.detailsAccount.controls['nombre'].value,
      apellido: this.detailsAccount.controls['apellido'].value,
      telefono1: this.detailsAccount.controls['telefono1'].value,
      telefono2: this.detailsAccount.controls['telefono2'].value,
      password: this.detailsAccount.controls['password'].value
    };
    this.putClientInfoAccounts(this.datos);
  }

  async putClientInfoAccounts(datos) {
    this.tutenEngieServi2Account = {
      numeroDePoliza: '',
      folioVenta: 0,
      folioBaja: '',
      razon: '',
      telefonoServi2: '',
      observacionesServi2: '',
      fechaEmisionFactura: '',
      fechaUpdate: '',
      enviarFacturacion: this.account.enviarFacturacion,
      fechaInicioPromo: '',
      fechaTerminoPromo: '',
      porcentajePromo: 0,
      fechaBaja: '',
      fechaEstatus: '',
      fechaInicioS2: '',
      fechaUltimaFactura: '',
      periodoServi2: 0,
      correoServi2: '',
      motivoBaja: '',
      tipoBaja: '',
      userId: this.account.userId,
      idPromo: 0,
      domain: '',
      NumInterior: '',
      Manzana: '',
      Lote: '',
      Edificio: '',
      EntreCalleyCalle: '',
      MarcaMedidor: '',
      TipoMedidor: '',
      UbicacionMedidor: '',
      Referencias: '',
      ContratoSAP: '',
      RFC: '',
      MotivoDeAlta: '',
      FechaDeBajaCliente: '',
      Municipio: '',
      CodigoPostal: '',
      Telefono1: datos.telefono1,
      Telefono2: datos.telefono2,
      Calle: '',
      NumExterior: '',
      Colonia: '',
      Estado: '',
      Email: '',
      Estatus: '',
      Interlocutor: '',
      PlanContratado: '',
      CuentaContrato: '',
      Porcion: '',
      Nombre: datos.nombre,
      Apellidos: datos.apellido,
      Regional: '',
      FechaPuestaEnServicio: '',
      FechaDeAltaCliente: '',
      NumeroDeInstalacion: ''
    };

    try {
      const response = await this.clientService.putClientInfoAcounts(this.tutenEngieServi2Account, datos.password).toPromise();
      if (response) {
        this.getServi2Account();
        this.edit = !this.edit;
        this.router.navigate([`cuentas/`+ this.accoundId]);
        this.snackBarService.openSnackBar(2000, 'Cuenta contrato modificada exitosamente', 'success');
      }
    } catch (err) {
      this.snackBarService.openSnackBar(2000, err.error.error, 'error');
      console.log('ERROR: ', err);
    }

  }

  backClicked() {
    this._location.back();
  }
}
