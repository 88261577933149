import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from "@angular/common";
import { SnackbarService } from 'src/app/core/api/snackbar.service';
import { BookingsService } from 'src/app/core/api/bookings/bookings.service';
import { ProgressBarService } from 'src/app/core/api/progress-bar.service';
import { MatTableDataSource, MatSort, Sort, MatPaginator, PageEvent } from '@angular/material';
import { FormGroup, FormControl } from '@angular/forms';
import { ClientService } from 'src/app/core/api';
import { Subscription } from 'rxjs';
import {MESSAGE_TIMES} from '../../../shared/constants';

@Component({
  selector: 'app-assist-poll-incomplete',
  templateUrl: './assist-poll-incomplete.component.html',
  styleUrls: ['./assist-poll-incomplete.component.scss']
})
export class AssistPollIncompleteComponent implements OnInit, OnDestroy{

  userId: number;
  title: any[] = ['Asistencias'];
  subtitle: any[] = ['Lista de Asistencias Pendientes de calificación'];
  isLoading: any = {
    bookingsLoading: true
  };

  startDate: Date;
  endDate: Date;

  dataAvailibity: any[] = [];
  groupsBookings: any[] = [];
  services: any[] = [];

  dataSource: MatTableDataSource<any>;

  @ViewChild(MatSort)sort: MatSort;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  bookings: any[] = [];
  bookingsFilter: any[] = [];
  loading = true;
  bookingsParams: any = {
    pagination: {
      page: 1,
      pagesize: 5,
      intervals: [5, 10, 25, 50, 100]
    },
    filters: {
      caseId: undefined,
      fromDateSolicitud: undefined,
      toDateSolicitud: undefined,
      serviceDefinition: [],
      state: [],
      id: undefined
    },
    total: 0
  };
  pageEvent: PageEvent = new PageEvent;
  tabla: FormGroup;
  getBookingsIncompletePollSubscription: Subscription;
  getServicesDefinitionSubscriptions: Subscription;
  getServicesGroupsSubscription: Subscription;
  getAvailabilityCoveragesBookingSubscription: Subscription;

  displayedColumns = ['idBooking', 'date', 'grupo', 'tipo', 'state', 'icon'];

  show: boolean = false;

  unmodifiedServices: any;
  componentSubscr: Subscription = new Subscription();

  constructor(private activate: ActivatedRoute,
              public router: Router,
              private _location: Location,
              private snackBarService: SnackbarService,
              private bookingsService: BookingsService,
              private progresBarService: ProgressBarService,
              private clientService: ClientService) {

    const routeParamsSubscr = this.activate.parent.params.subscribe(parametros => {
      this.userId = parametros.id;
    });
    this.tabla = new FormGroup({
      'idBooking': new FormControl(''),
      'fechaInicio': new FormControl(''),
      'fechaFin': new FormControl(''),
      'services': new FormControl('')
    });
    this.pageEvent.pageSize = this.bookingsParams.pagination.pagesize;
    this.pageEvent.pageIndex = 0;
  }

  ngOnDestroy() {
    if (this.getBookingsIncompletePollSubscription && !this.getBookingsIncompletePollSubscription.closed)
      this.getBookingsIncompletePollSubscription.unsubscribe();
    if (this.getServicesDefinitionSubscriptions && !this.getServicesDefinitionSubscriptions.closed)
      this.getServicesDefinitionSubscriptions.unsubscribe();
    if (this.getServicesGroupsSubscription && !this.getServicesGroupsSubscription.closed)
      this.getServicesGroupsSubscription.unsubscribe();
    if (this.getAvailabilityCoveragesBookingSubscription && !this.getAvailabilityCoveragesBookingSubscription.closed)
      this.getAvailabilityCoveragesBookingSubscription.unsubscribe();
  }

  ngOnInit() {
    // switch (this.progresBarService.getCountTipe()) {
    //   case "1":
    //     this.progresBarService.setCountTipe("");
    //     break;
    //   case "2":
    //     this.progresBarService.setCountTipe("");
    //     break;
    //   case "3":
    //     this.progresBarService.setCountTipe("");
    //     break;
    //   case "4":
    //     this.progresBarService.setCountTipe("");
    //     break;
    //   default:
    // }
    this.getServices();
  }

  getBookingsIncompletePoll() {
/*     const incompletePollSubs = this.bookingsService.getBookingsIncompletePoll().subscribe(
      (response : any) => {
        if(response != null){
          this.bookings = response;
          this.bookingsFilter = this.bookings;
          this.bookingsParams.total = this.bookings.length;
          this.searchBookings(this.bookingsFilter);
          //this.applyFilter();
        } else {
          this.snackBarService.openSnackBar(MESSAGE_TIMES.ERROR_MESSAGE, 'Sin obtención de registros', 'warn');
        }
        //this.loading = false;
        //this.isLoading.bookings = false;
        //this.progresBarService.emitBooleanObjects(this.isLoading);
      }, error => {
        this.snackBarService.openSnackBar(MESSAGE_TIMES.ERROR_MESSAGE, 'Problemas de servidor', 'error');
      }
    );
    this.componentSubscr.add(incompletePollSubs); */
  }

  backClicked() {
    this._location.back();
  }

  getServices() {
    const servicesSubsc = this.bookingsService.getServicesDefinition().subscribe((response: any) => {
      if (response != null) {
        this.unmodifiedServices = response;
        //this.getGroups();
      } else {
        this.snackBarService.openSnackBar(MESSAGE_TIMES.ERROR_MESSAGE, 'Sin obtención de registros de Asistencias', 'warn');
      }
    }, error => {
      this.snackBarService.openSnackBar(MESSAGE_TIMES.ERROR_MESSAGE, 'No se pueden obtener las Asistencias', 'error');
    }, () => {
      this.getGroups();
    });
    this.componentSubscr.add(servicesSubsc);
  }

  getGroups() {
    const serviceGroup = this.bookingsService.getServicesGroups().subscribe((response: any) => {
      if (response != null) {
        this.groupsBookings = response;
      } else {
        this.snackBarService.openSnackBar(MESSAGE_TIMES.ERROR_MESSAGE, 'Sin obtención de registros de grupos', 'warn');
      }
    }, error => {
      this.snackBarService.openSnackBar(MESSAGE_TIMES.ERROR_MESSAGE, 'No se pueden obtener los grupos', 'error');
    }, () => {
      this.getAvailabilityCoveragesBooking();
    });
    this.componentSubscr.add(serviceGroup);
  }

  getAvailabilityCoveragesBooking() {
    const availiabilitySubsc = this.clientService.getAvailabilityCoverages().subscribe((response: any) => {
      if (response !== undefined) {
        this.dataAvailibity = response;
        //this.searchBookings();
      } else {
        this.snackBarService.openSnackBar(MESSAGE_TIMES.ERROR_MESSAGE, 'Sin obtención de registros coberturas', 'warn');
      }
    }, error => {
      this.snackBarService.openSnackBar(MESSAGE_TIMES.ERROR_MESSAGE, 'No se pueden obtener los registros de coberturas', 'error');
    }, () => {
      this.getBookingsIncompletePoll();
    });
    this.componentSubscr.add(availiabilitySubsc);
  }

  searchBookings(bookings: any[]): void {
    const localService = bookings.map(el => {
      const service = this.dataAvailibity.find(({ id }) => id == el.serviceId);
      return ({
        ...el,
        serviceName: service ? service.name : 'N/A',
        groupId: service ? service.groupId : 'N/A'
      })
    });

    const localGroup = localService.map(el => {
      const group = this.groupsBookings.find(({ id }) => id == el.groupId);
      return ({
        ...el,
        groupName: group ? group.name : 'N/A'
      })
    });

    this.services = localGroup.map(el => {
      const { id: idBooking, caseId: idSolicitud, creationDate: date, serviceName: tipo, stateBookingName: state, groupName: grupo } = el;
      return { idBooking, idSolicitud, date, grupo, tipo, state, icon: 'keyboard_arrow_right' };
    });

    this.services = this.services.sort((a,b) => {
      return this.getTime(new Date(a.date)) - this.getTime(new Date(b.date));
    });

    this.services = this.services.slice();

    this.dataSource = new MatTableDataSource(this.services);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    // Parametros para el progressBar
    this.isLoading.bookingsLoading = false;
    this.progresBarService.emitBooleanObjects(this.isLoading);

    this.loading = false;
  }

  assit(idS: number) {
    this.router.navigate([`dashboard/asistencia`, idS])
  }

  onRowClicked(row) {
    // console.log('Row clicked: ', row);
  }

  getTime(date?: Date):number{
    return date != null ? date.getTime() : 0;
  }

  applyFilter() {
    /*filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;*/
    this.bookingsFilter = this.bookings;

    if (!(this.tabla.controls.idBooking.value == "" || this.tabla.controls.idBooking.value == null)) {
      this.bookingsParams.filters.idBooking = this.tabla.controls.idBooking.value;
      this.bookingsFilter = this.bookingsFilter.filter(x => `${x.id}`.indexOf(this.bookingsParams.filters.idBooking) != -1);
    }
    if (!(this.tabla.controls.fechaInicio.value == "" || this.tabla.controls.fechaInicio.value == null)) {
      this.bookingsParams.filters.fechaInicio = this.tabla.controls.fechaInicio.value;
      this.bookingsFilter = this.bookingsFilter.filter(x => this.getTime(new Date(x.creationDate)) >= this.getTime(new Date(this.bookingsParams.filters.fechaInicio)));
    }
    if (!(this.tabla.controls.fechaFin.value == "" || this.tabla.controls.fechaFin.value == null)) {
      this.bookingsParams.filters.fechaFin = this.tabla.controls.fechaFin.value;
      let filterDate = new Date(this.bookingsParams.filters.fechaFin);
      filterDate.setHours(23,59,59);
      this.bookingsFilter = this.bookingsFilter.filter(x => this.getTime(new Date(x.creationDate)) <= this.getTime(filterDate));
    }
    if (!(this.tabla.controls.services.value == "" || this.tabla.controls.services.value == null)) {
      this.bookingsParams.filters.services = this.tabla.controls.services.value;

      this.bookingsFilter = this.bookingsFilter.filter(x => {
        return this.bookingsParams.filters.services.includes(x.serviceId);
      });
    }
    /*if (!(this.tabla.controls.states.value == "")) {
      this.bookingsParams.filters.state = this.tabla.controls.states.value;
    }*/
    this.searchBookings(this.bookingsFilter);
  }

  clean() {
    this.bookingsFilter = this.bookings;
    this.tabla.controls['idBooking'].reset();
    this.tabla.controls['fechaInicio'].reset();
    this.tabla.controls['fechaFin'].reset();
    this.tabla.controls['services'].reset();
    //this.tabla.controls['states'].reset();
    this.bookingsParams.filters.caseId = undefined;
    this.bookingsParams.filters.idBooking = undefined;
    this.bookingsParams.filters.fechaInicio = undefined;
    this.bookingsParams.filters.fechaFin = undefined;
    this.bookingsParams.filters.services = [];
    this.bookingsParams.filters.state= [];

    this.searchBookings(this.bookingsFilter);
  }

  sortData(sort: Sort) {
    const data = this.services.slice();

    if(!sort.active || sort.direction === '') {
      this.services = data;
      return;
    }

    this.services = data.sort((a,b) => {
      const isAsc = sort.direction === 'asc';
      switch(sort.active) {
        case 'idBooking': return this.compareFunc(a.idBooking, b.idBooking, isAsc);
        case 'date': return this.compareFunc(this.getTime(new Date(a.date)), this.getTime(new Date(b.date)), isAsc);
        case 'grupo': return this.compareFunc(a.grupo, b.grupo, isAsc);
        case 'tipo': return this.compareFunc(a.tipo, b.tipo, isAsc);
        case 'state': return this.compareFunc(a.state, b.state, isAsc);
        default: return 0;
      }
    });
  }

  compareFunc(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  showFilter() : void {
    this.show = !this.show;
  }

  onPaginateChange(event) {
    this.pageEvent = event;
  }
}
