import * as interfaces from './zendes.interfaces';

export const DEFAULT_DEPARTMENT = "Soporte";

export class DepartmentCriteria {
  isLoggedUser: boolean;
  isInRouteStateBooking: boolean;
  department: string;

  /**
   *Setting the object
   */
  constructor(isLoggedUser: boolean, isInRouteStateBooking: boolean = false, department: string = DEFAULT_DEPARTMENT) {
    this.isLoggedUser = isLoggedUser;
    this.isInRouteStateBooking = isInRouteStateBooking;
    this.department = department;
  }

  /**
   * Function to check the state of the class
   */
  toString(): string {
    const departmentCriteria = {
      isLoggedUser: this.isLoggedUser,
      department: this.department,
      isInRouteStateBooking: this.isInRouteStateBooking
    };
    return JSON.stringify(departmentCriteria, undefined, 2);
  }
}