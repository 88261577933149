import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { BookingsService } from '../../../core/api/bookings/bookings.service';
import { ProgressBarService } from '../../../core/api/progress-bar.service';
import { CalendarioComponent } from '../../shared/calendario/calendario.component';
import * as moment from 'moment';
import {Subscription} from 'rxjs';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/api';
import * as _ from 'lodash';

export interface agenda {
  value: StateEnum;
  viewValue: string;
}

@Component({
  selector: 'app-reagendar',
  templateUrl: './reagendar.component.html',
  styleUrls: ['./reagendar.component.scss']
})
export class ReagendarComponent implements OnInit {
  @ViewChild(CalendarioComponent) calendar: CalendarioComponent;

  scheduledAppointment: boolean = false;
  title: any[] = ['Reagendar'];
  StateEnum = StateEnum;
  subtitle: any[] = [];
  ranges: any[] = [];
  selectedValue: string = "Inmediato";
  selected;
  selecto;
  opcion = "Escoge";
  stateInvalid = true;
  booking: any;
  bookingId: number;
  professionalId: number;
  isLoading: any = {
    booking: true,
    professionals: true
  };
  request = {
    bookingId: undefined,
    professionalId: undefined,
    timezoneMin: undefined,
    transferId:undefined,
    reasonReschedule: undefined,
    body: {
      start: undefined,
      end: undefined,
      desiredDate: undefined
    }
  };
  componentsSubscription: Subscription;

  agendas: agenda[] = [
    {value: StateEnum.INMEDIATO, viewValue: 'Inmediato'},
    {value: StateEnum.AGENDAR, viewValue: 'Agendar Visita'}
  ];
  statesAssociatedTransfer: any[] = [];
  currentOffSet : any = 0;
  professionalsId: any[] = [];
  rangesServices: any[] = [];
  allProfessionalsRange: any[] = [];
  busyRangesForAll: any[] = [];
  mapRangesBusyByProfessional: any = {};

  actionInProgress: boolean = false;

  //TODO: Si se va a realizar acciones de interacción, agregar snackbar
  constructor(
    private router: Router,
    public bookingService: BookingsService,
    private progresBarService: ProgressBarService,
    public dialogRef:DynamicDialogRef, public config: DynamicDialogConfig) {
      this.bookingId = config.data.bookingId;
      this.currentOffSet = new Date().getTimezoneOffset();
    }

  closeComponent(): void {
    this.dialogRef.close(false);
  }

  ngOnInit() {
    this.getBooking();
  }

  Clicke() {
    this.opcion = "Agenda Visita";
    this.selected = 1;
    this.selecto = "opcion3";
  }

  getBooking(): void {
    this.isLoading.booking = true;
    this.bookingService.getBooking(this.bookingId).subscribe((response: any) => {
      if (response !== undefined) {
        this.booking = response;
      }
      this.isLoading.booking = false;
      this.progresBarService.emitBooleanObjects(this.isLoading);
      this.bookingService.getStateTransfersByStateId(this.booking.stateId).subscribe((response: any) => {
        this.statesAssociatedTransfer = response;
      });
    });
  }

  getTimeZoneMin() {
    this.bookingService.getTimeZone(this.booking.latitude, this.booking.longitude).subscribe(response => {
      if (response != undefined) {
        let dst = response['dstOffset'];
        let raw = response['rawOffset'];
        this.request.timezoneMin = dst == 0 ? (raw / 60) : (raw < 0 ? ((raw + dst) / 60) : ((raw - dst) / 60));
      }
    });
  }

  parseUTCtoLocal(date): any {
    if(this.currentOffSet >= 0) {
      return date.subtract(this.currentOffSet, 'minutes').toISOString()
    } else {
      return date.add(this.currentOffSet, 'minutes').toISOString()
    }
  }

  parseLocaltoUTC(date): any {
    if(this.currentOffSet >= 0) {
      return date.add(this.currentOffSet, 'minutes').toISOString()
    } else {
      return date.subtract(this.currentOffSet, 'minutes').toISOString()
    }
  }

  getProfessionalsBusyRanges(ranges: any): void {
    if(ranges != null && ranges.length > 0) {
      ranges.map(
        range => {
          if( range.title == 'Reserva de booking') {
            if(this.mapRangesBusyByProfessional.hasOwnProperty(range.id)) {
              this.mapRangesBusyByProfessional[range.id].push(range);
            } else {
              this.mapRangesBusyByProfessional[range.id] = [range];
            }
          } else if(range.title == 'Reserva de contratista'){
            range.professionalChildren.map(professional => {
                range.id = professional;
              if(this.mapRangesBusyByProfessional.hasOwnProperty(professional)){
                this.mapRangesBusyByProfessional[professional].push(range);
              } else {
                this.mapRangesBusyByProfessional[professional] = [range];
              }
            });
          }
        }
      )
    }
    this.intersection();
  }

  intersection() {
    let mapCopy = _.cloneDeep(this.mapRangesBusyByProfessional);
    this.busyRangesForAll = mapCopy[this.professionalsId[0]];
    for(let i = 0; i< this.busyRangesForAll.length; i++) {
      let isBusy = false;
      for(let j = 1; j < this.professionalsId.length; j++) {
        for(let r = 0; r < this.mapRangesBusyByProfessional[this.professionalsId[j]].length; r++) {
          let aux = this.intersectionLogic(this.busyRangesForAll[i],
            this.mapRangesBusyByProfessional[this.professionalsId[j]][r]);
          this.busyRangesForAll[i] = aux.range;
            isBusy = aux.busy;
            if(isBusy) break;
        }
        if(!isBusy) {
          this.busyRangesForAll[i] = null;
          break;
        }
      }
    }

    this.busyRangesForAll = this.busyRangesForAll.filter(x => x !=null);
  }

  intersectionLogic(range, rangeNext) {
    let busy = false;
      if(range.start >= rangeNext.start && range.start >= rangeNext.end) {
        return  {
          range,
          busy
        };
      }
      if(range.end <= rangeNext.start && range.end <= rangeNext.end) {
        return {range, busy};
      }

      busy = true;
      if(range.start <= rangeNext.start && range.end >= rangeNext.end){
        range.start = rangeNext.start;
        range.end = rangeNext.end;
        return  {range, busy};
      }
      if(range.start > rangeNext.start && range.end >= rangeNext.end) {
        range.end = rangeNext.end;
        return {range,busy};
      }
      if(range.start <= rangeNext.start && range.end > rangeNext.end) {
        range.start = rangeNext.start;
        return {range, busy};
  }
    return { range, busy: false};
  }


 /*
    [iniComponentCalendario, función encargada de recibir el evento cuando se cargue el compoenete Calendario]
  */
  iniComponentCalendarioParent(model: any) {
    let body = {
      bookingId: this.bookingId,
      start: moment(model['start']).format('YYYY-MM-DD'),
      end: moment(model['end']).format('YYYY-MM-DD')
    };

    // Consulta si existe profesional disponible
    if  (this.bookingId != undefined) {
      //
      let transferTime = this.getTransferTime(model['start']);

      let definition= this.config.data.bookingDefinition;

      let dateTimePreviewSelect = definition['anticipationTime'] + transferTime;
      //

      this.bookingService.getBookingMatch(body).subscribe((response: any) => {
        if (response !== undefined) {
          this.professionalsId = response.filter(e => e.professionalId == this.booking.professionalId).map(e => e.professionalId);
          if(this.professionalsId.length > 0){
            body['professionals'] = `[${this.professionalsId}]`;
            this.professionalId = this.booking.professionalId;
          }

          // Consulta los profesiones disponibles en el rango de fechas
          this.bookingService.searchBookingProfessionalBusyRanges(body).subscribe((responseRange: any) => {
            if (responseRange !== undefined) {
              this.request.body.start = this.config.data.assistanceStart;
              this.request.body.end = this.config.data.assistanceEnd;
              let dataBooking = {
                start: this.parseUTCtoLocal(moment.utc(this.config.data.assistanceStart)),
                end: this.parseUTCtoLocal(moment.utc(this.config.data.assistanceEnd)),
                id: this.professionalId,
              };
              if(this.professionalsId.length == 0) {
                this.calendar.messageDateSelect('No hay técnicos disponibles para esta semana', 'error');
                return;
              }
                this.rangesServices = responseRange.filter(
                  (range) => range.title  != "Reserva de booking" && range.title != 'Reserva de contratista').map((x) => {
                  x.start = moment.utc(x.start);
                  x.end = moment.utc(x.end);
                return x;
              });
                this.allProfessionalsRange = responseRange.filter(
                  (range) => range.title == 'Reserva de booking' || range.title == 'Reserva de contratista').map(
                  range => {
                    if(range.title == 'Reserva de booking') {
                    range.start = this.parseUTCtoLocal(moment.utc(range.start));
                    range.end = this.parseUTCtoLocal(moment.utc(range.end));
                    } else if (range.title == 'Reserva de contratista') {
                      range.start = moment.utc(range.start);
                      range.end = moment.utc(range.end);
                    }
                  return range;
                });

                this.getProfessionalsBusyRanges(this.allProfessionalsRange);
                let rangesServicestoPaint = _.cloneDeep(this.rangesServices);
                rangesServicestoPaint = rangesServicestoPaint.map(range => {
                  range.end = moment.utc(range.end).add(dateTimePreviewSelect, 'minutes');
                  return range;
                });
                let busyAllToPaint = _.cloneDeep(this.busyRangesForAll);
                busyAllToPaint = busyAllToPaint.map(range => {
                  if(range.title == 'Reserva de booking') {
                    range.start = this.parseLocaltoUTC(moment.utc(range.start));
                    range.end = this.parseLocaltoUTC(moment.utc(range.end).add(dateTimePreviewSelect, 'minutes'));
                  }
                  return range;
                });
                this.calendar.paintBusyRangesOfProfessional(rangesServicestoPaint.concat(busyAllToPaint));
                this.calendar.paintSolicitud(dataBooking);
                this.scheduledAppointment = true;
              }

          }, (err: any) => {
            this.calendar.messageDateSelect('No hay técnicos disponibles para esta semana', 'error');
          });
        }
      }, (err: any) => {
        // No existen técnicos
        this.calendar.messageDateSelect('No hay técnicos disponibles para esta semana', 'error');
      });
    }
  }

  validationsBlocks(fechaInicioSolicitud, fechaFinSolicitud) {
    let isValid = false;
    let auxRanges2 = this.rangesServices.map((x) => {
      x.start = moment.utc(x.start);
      x.end = moment.utc(x.end);
      return x;
    });

    isValid = !auxRanges2.some( (puntos) => this.validateBlock(puntos, fechaInicioSolicitud, fechaFinSolicitud));

    if (!isValid) return false;

    for( let i = 0; i < this.professionalsId.length; i++ ) {

      auxRanges2 = this.mapRangesBusyByProfessional[this.professionalsId[i]];
      auxRanges2 = auxRanges2.map((x) => {
        x.start = moment.utc(x.start);
        x.end = moment.utc(x.end);
        return x;
      });

      if(!auxRanges2.some(puntos => this.validateBlock(puntos, fechaInicioSolicitud, fechaFinSolicitud))){
        this.professionalId = this.professionalsId[i];
        isValid = true;
        break;
      } else {
        isValid = false;
      }
    }

    return isValid;
  }

  validateBlock(puntos , fechaInicioSolicitud, fechaFinSolicitud): boolean {

    if(puntos.title == 'Reserva de booking' )
        return (puntos.start < fechaInicioSolicitud && fechaInicioSolicitud < puntos.end) ||
          (puntos.start < fechaFinSolicitud && fechaFinSolicitud < puntos.end) ||
          (fechaInicioSolicitud <= puntos.start && puntos.end <= fechaFinSolicitud ) ||
          (fechaInicioSolicitud == puntos.start && puntos.end == fechaFinSolicitud)
       else  return (puntos.start < fechaInicioSolicitud && fechaInicioSolicitud < puntos.end) ||
          (puntos.start < fechaFinSolicitud && fechaFinSolicitud < puntos.end) ||
          (fechaInicioSolicitud < puntos.start && puntos.end < fechaFinSolicitud ) ||
          (fechaInicioSolicitud == puntos.start && puntos.end == fechaFinSolicitud);
  }

  /**
   * [transferTime, Identificar el tiempo de transalado por zona geográfica cada ves que se selecciona una fecha y hora]
   */
   getTransferTime(start) {
    return this.statesAssociatedTransfer[0]? this.statesAssociatedTransfer[0].transferTimeMin : 60;
  }

  /*
    [selectDate, función encargada de recibir el evento cuando se selecciona una fecha y hora en el componente hijo Calendario]
  */
  selectDate(model: any) {
    let transferTime = this.getTransferTime(model['model'].start);
    let definition=this.booking.definition;
    let fechaInicioSolicitud = moment.utc(model['model'].start)
        .subtract(transferTime, 'minutes')
        .subtract(definition['anticipationTime'], 'minutes');

    let fechaFinSolicitud = moment.utc(model['model'].start).add(definition['duration'], 'minutes');
    if (this.validationsBlocks(fechaInicioSolicitud, fechaFinSolicitud)) {
      let dataBooking = {
        start: moment.utc(model['model'].start),
        end: moment.utc(model['model'].start).add(definition['duration'], 'minutes'),
        id: this.professionalId,
        bookingId: this.bookingId,
        duration: definition['duration'],
        transferTime: transferTime,
        anticipationTime: definition['anticipationTime']
      };
      dataBooking['professionals'] = "[" + this.professionalsId + "]";
          this.bookingService.searchBookingProfessionalBusyRanges(dataBooking).subscribe((response: any) => {
            if (response !== undefined ) {
              let dateTimePreviewSelect = definition['anticipationTime'] + transferTime;
              this.rangesServices = response.filter(
                (range) => range.title  != "Reserva de booking" && range.title != 'Reserva de contratista').map((x) => {
                x.start = moment.utc(x.start);
                x.end = moment.utc(x.end);
              return x;
            });
              this.allProfessionalsRange = response.filter(
                (range) => range.title == 'Reserva de booking' || range.title == 'Reserva de contratista').map(
                range => {
                  if(range.title == 'Reserva de booking') {
                  range.start = this.parseUTCtoLocal(moment.utc(range.start));
                  range.end = this.parseUTCtoLocal(moment.utc(range.end));
                  } else if (range.title == 'Reserva de contratista') {
                    range.start = moment.utc(range.start);
                    range.end = moment.utc(range.end);
                  }
                return range;
              });

              this.getProfessionalsBusyRanges(this.allProfessionalsRange);
              let rangesServicestoPaint = _.cloneDeep(this.rangesServices);
              rangesServicestoPaint = rangesServicestoPaint.map(range => {
                range.end = moment.utc(range.end).add(dateTimePreviewSelect, 'minutes');
                return range;
              });
              let busyAllToPaint = _.cloneDeep(this.busyRangesForAll);
              busyAllToPaint = busyAllToPaint.map(range => {
                if(range.title == 'Reserva de booking') {
                  range.start = this.parseLocaltoUTC(moment.utc(range.start));
                  range.end = this.parseLocaltoUTC(moment.utc(range.end).add(dateTimePreviewSelect, 'minutes'));
                }
                return range;
              });
              if (this.professionalId && this.validationsBlocks(fechaInicioSolicitud,fechaFinSolicitud)) {
                this.request.professionalId = this.professionalId;
                this.scheduledAppointment = true;
                this.calendar.paintBusyRangesOfProfessional(rangesServicestoPaint.concat(busyAllToPaint));
                this.calendar.paintSolicitud(dataBooking);
                this.request.professionalId = dataBooking.id;
                this.request.bookingId=this.booking.id;
                this.request.timezoneMin=new Date().getTimezoneOffset();
                if (new Date().getTimezoneOffset() >= 0) {
                  this.request.body.start = fechaInicioSolicitud.add(this.currentOffSet + transferTime + definition['anticipationTime'], 'minutes').toISOString();
                  this.request.body.end = fechaFinSolicitud.add(this.currentOffSet, 'minutes').toISOString()

                } else {
                  this.request.body.start = fechaInicioSolicitud.add(transferTime + definition['anticipationTime'], 'minutes').subtract(this.currentOffSet, 'minutes').toISOString();
                  this.request.body.end = fechaFinSolicitud.subtract(this.currentOffSet, 'minutes').toISOString()
                }

                this.request.body.desiredDate = this.request.body.start;
                this.request.reasonReschedule = "Cliente cambia la cita";
                this.stateInvalid = false;
                this.calendar.messageDateSelect('Se ha asignado correctamente su asistencia en este horario', 'success');
              } else {
                this.calendar.messageDateSelect('No hay técnicos disponibles para esta fecha', 'error');
              }
            }
          });
    } else {
      this.calendar.messageDateSelect('Por favor seleccione un espacio disponible para su asistencia', 'error');
    }
  }

  submitForm(): void {
    this.actionInProgress = true;
    this.bookingService.rescheduleBookingToProfessional(this.request).subscribe((response: any) => {
      if (response !== undefined) {
        this.dialogRef.close(true);
      }
      this.actionInProgress = false;
    });
  }
}

enum StateEnum {
  INMEDIATO, AGENDAR
}
