import { Component } from '@angular/core';

@Component({
  selector: 'avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {

  user: string = "AU";

  constructor() {
    this.user = `${localStorage.getItem('nameuser')[0]}${localStorage.getItem('apellidouser')[0]}`.toUpperCase();
  }
}
