import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { ClientService } from './client.service';

@Injectable()
export class AuthAssertLoginGuard implements CanActivate {
	constructor(
		private router: Router,
		private clientService: ClientService
	) {}

	canActivate() {
		if (localStorage.getItem('sessionToken')) {
			return true;
		}

		this.router.navigate(['home']);
		
		return false;
	}
}