import { Component, Input, OnInit } from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'service-header',
  templateUrl: './service-header.component.html',
  styleUrls: ['./service-header.component.scss']
})
export class ServiceHeaderComponent implements OnInit {

  @Input()
  title: string;

  constructor(private _location: Location) {
    this.title = "Default title";
  }

  goBack(): void {
    this._location.back();
  }

  ngOnInit() {
  }

}
