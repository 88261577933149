import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { BookingsService } from 'src/app/core/api/bookings/bookings.service';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-approve-dialog',
  templateUrl: './approve-dialog.component.html',
  styleUrls: ['./approve-dialog.component.scss']
})
export class ApproveDialogComponent implements OnInit {
  constructor(
              public dialogRef: MatDialogRef<ApproveDialogComponent>,
              private messageService: MessageService,
              private bookingService: BookingsService,
              @Inject(MAT_DIALOG_DATA) public data: any) {


  }

  ngOnInit() {}

  close(): void {
    this.dialogRef.close();
  }

  callRequest() {
    this.bookingService.approveBudget(this.data.bookingID).subscribe(res =>{
      this.dialogRef.close();
      this.messageService.add({severity: 'success', summary:'Éxito', detail:'Se ha aprobado el presupuesto'});
    }, err => {
      this.messageService.add({severity: 'error', summary:'Error', detail: 'Ha ocurrido un error. Por favor, inténtelo más tarde.'})
     });
  }

}
