import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {PlansService} from '../../../../core/api/plans/plans.service';
import * as moment from 'moment';
import {BookingsService} from '../../../../core/api/bookings/bookings.service';
import { TutenClientCoverageResponse } from 'src/app/core/api/plans/plans.interfaces';

@Component({
  selector: 'app-contracted-plan',
  templateUrl: './contracted-plan.component.html',
  styleUrls: ['./contracted-plan.component.scss']
})
export class ContractedPlanComponent implements OnInit {

  planContractor: any = null;
  planSubscription: Subscription;
  dispServiceDate: string;
  componentsSubscription: Subscription;
  groupsBookings: any = null;

  constructor(
              private router: Router,
              public plans: PlansService,
              public bookingsService: BookingsService) {
  }

  ngOnInit() {
    this.planes();
    this.getServicesGroupsNoId();
  }

  public async planes() {
    this.planSubscription = this.plans.getClientPlan().subscribe((response: any) => {
      if (response != undefined) {
        this.planContractor = response.planName;
      }
    });
    try {
      const response:TutenClientCoverageResponse = await this.plans.getClientCoverage().toPromise();
      this.dispServiceDate = moment(response.lastPeriodicityEndDate).format('DD/MM/YYYY');
    } catch (error) {
      console.log(error);
    }
  }

  public getServicesGroupsNoId() {
    const getServicesSubsc = this.bookingsService.getServicesGroupsNoId().subscribe((response: any) => {
      if (response != undefined) {
        this.groupsBookings = response;
      }
    });
  }

  detailsAvailable(group) {
    localStorage.setItem('nameGroup', group);
    this.router.navigate([`dashboard/disponibilidad-detalles`]);
  };

}
