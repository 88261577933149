import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BookingsService } from "../../../core/api/bookings/bookings.service";
import { MessageService } from 'primeng/api';
import { mapImageService } from 'src/app/contants/app.contants';

@Component({
  selector: 'app-exito',
  templateUrl: './exito.component.html',
  styleUrls: ['./exito.component.scss']
})
export class ExitoComponent implements OnInit {

  user: string = localStorage.getItem('userName');
  userId: any = localStorage.getItem('clientId');
  booking: any = {
    definition: {

    }
  };

  constructor(
    private route: ActivatedRoute,
    private messageService: MessageService,
    private bookingService: BookingsService) {}

  async ngOnInit() {
    await this.route.queryParams.subscribe(params => {
      this.bookingService.getBooking(parseInt(params.id)).subscribe(response => {
        this.booking = response;
      }, err => {
        this.messageService.add({severity: 'error', summary:'Error', detail: 'No se encontró la asistencia'});
      });

    });
  }

  get getImageByService() {
    if(this.booking.definition.picture != null) return this.booking.definition.picture;
    return mapImageService[this.booking.definition.id] ? mapImageService[this.booking.definition.id] : "assets/images/empty.jpg";
  }

}
