import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from "@angular/router";
import { Subject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import {
  TutenClientProfileData,
  TutenClient,
  TutenEngieClientIndentifier,
  TutenClientProfileProvide,
  TutenClientProfile,
  TutenEngieServi2Account
} from './client-interfaces';
import { _mapTutenClientProfile, _mapTutenClient } from './client-functions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/interfaces/app.interface';
import * as appActionTypes from 'src/app/actions/app.actions';
import { Search } from 'src/app/components/shared/search/search.component';


@Injectable({
  providedIn: 'root'
})
export class ClientService {
  isLogin: boolean;
  isEngie: boolean;
  read: String;
  // Observable string sources /*** https://stackoverflow.com/questions/37662456/angular-2-output-from-router-outlet */
  private emitChangeNotification = new Subject<any>();
  // Observable string streams /*** https://stackoverflow.com/questions/37662456/angular-2-output-from-router-outlet */
  changeEmittedNotification$ = this.emitChangeNotification.asObservable();
  //Variables creadas para manejar navegacion despues de login y register
  private navigateArgument: string;
  private isRegister: boolean;

  //Login and logout observer
  private loginAndLogoutSubject: Subject<boolean> = new Subject<boolean>();


  constructor(
    public api: ApiService,
    private router: Router,
    private store: Store<AppState>
    ) {
  }


  getLogingSubjectAsObserver(): Observable<boolean> {
    return this.loginAndLogoutSubject.asObservable();
  }

  emitLoginAndLogout(isAuthenticated: boolean): void {
    this.loginAndLogoutSubject.next(isAuthenticated);
  }

  loginClient(email: string, password: string, provider: string): Observable<TutenClientProfile> {
    let headers = {
      email,
      passwordOrProviderId: password,
      provider
    };
    return this.api.post('client/login', null, headers, null)
    .pipe(
      _mapTutenClientProfile()
    )
  }

  redirectLogin(data: TutenClientProfile) {
    localStorage.setItem('clientId', data.clientId.id.toString());
    localStorage.setItem('sessionToken', data.clientId.sessionToken);
    localStorage.setItem('active', data.clientId.active + "");
    localStorage.setItem('engie', data.clientId.engie + "");
    localStorage.setItem('emailuser', data.email);
    localStorage.setItem('nameuser', data.firstName);
    localStorage.setItem('apellidouser', data.lastName);
    localStorage.setItem('telefonouser', data.numberPhone);
    localStorage.setItem('expirationTokenDate', data.clientId.expirationTokenDate);
    localStorage.setItem('userName', data.name);
    let promesa = new Promise(
      ((resolve, rejsect) => {
        if (data.id) {
          //Si proviene de algun lugar, redirigelo alli
          if (this.navigateArgument != null)
            resolve(this.router.navigate([this.navigateArgument]));
          else {
            resolve(this.router.navigate(['/dashboard', data.clientId.id]));
          }
        } else {
          resolve(null);
        }
      })
    );
    this.emitLoginAndLogout(this.isAuthenticated());
    return promesa;
  }

  /**
   * Metodo creado para establecer el indice
   * del mat-tab en la vista de login
   */
  setIsRegister(isLocalRegister: boolean): void {
    this.isRegister = isLocalRegister;
  }

  /**
   * Metodo creado para obtener el indicenvm
   * del mat-tab en la vista de login
   */
  getIsRegister(): boolean {
    return this.isRegister;
  }

  /**
   * Funcion para setear el destino al cual se redirigira despues de
   * Hacer el loggin.
   * @param route la ruta a la que se redirigira despues de hacer el login
   */
  setNavigateArgument(route: string = null) {
    this.navigateArgument = route;
  }

  /**
   * Funcion para sacar al usuario loggeado
   * del sistema
   * @param isTimeOut logout por tiempo vencido defautl false
   */
  logoutClient(isTimeOut: boolean = false) {
    localStorage.clear();
    this.store.dispatch(new appActionTypes.RemoveUserLoggedActions());
    this.emitLoginAndLogout(false);
    if (!isTimeOut)
      this.router.navigate(['/home'])
  }

  validateTokenClient() {
    let clientId = localStorage.getItem('clientId');
    let headers = {
      token: localStorage.getItem('sessionToken')
    };
    return this.api.post('client/' + clientId + '/token', null, headers, null);
  }

  getClientId() {
    return localStorage.getItem('clientId');
  }

  public isAuthenticated(): boolean {
    this.isLogin = JSON.parse(localStorage.getItem('active'));
    this.isLogin && this.store.dispatch(new appActionTypes.SetUserLoggedActions());
    return Boolean(this.isLogin);
  }

  public isEngieClient() {
    this.isEngie = JSON.parse(localStorage.getItem('engie'));
    return this.isEngie;
  }
  getAvailabilityCoverages() {
    let headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };
    return this.api.get('client/available/coverage', null, null, headers)
  }

  getUnreadNotifications() {
    let headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };

    return this.api.get(`client/${localStorage.getItem('clientId')}/notifications/unread`, null, null, headers);
  }

  confirmRequestIfExist() {
    let headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };
    return this.api.get("client/booking/request", null, null, headers);
  }

  createRequest() {
    let headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };
    return this.api.post("client/booking/request/create", null, headers, null);
  }

  updateRequest(request) {
    const headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken'),
    };
    const body = request;
    return this.api.put('client/booking/request/edit', body, headers, null);
  }

  sendRequest(request) {
    let headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };
    const body = request;
    return this.api.put("client/booking/request/send", body, headers, null);
  }

  setReadOneNotification(notification: any) {
    let headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };
    let clientId = localStorage.getItem('clientId');


    if (notification.viewDate) {
      this.read = 'false';
    } else {

      this.read = 'true';
    }

    return this.api.get(`/client/${clientId}/notification/${notification.id}/view/${this.read}`, null, null, headers);
  }

  setReadAllNotifications() {
    let headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };
    let clientId = localStorage.getItem('clientId');

    return this.api.get(`client/${clientId}/notifications/view`, null, null, headers);
  }

  // TODO aldemaro.guzman this method should be deleted
  getAllNotifications(data) {
    let headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };
    const params = {
      page: data.page,
      pagesize: data.pageSize
    };
    let clientId = localStorage.getItem('clientId');
    return this.api.get(`client/${clientId}/notifications`, params, null, headers);
  }

  deleteNotification(notification: any) {
    let headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };

    return this.api.delete(`client/notification/${notification.id}/delete`, null, headers);
  }

  deleteAllNotifications() {
    let headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };

    let clientId = localStorage.getItem('clientId');

    return this.api.delete(`client/${clientId}/notifications/delete`, null, headers);
  }

  // Service message commands /*** https://stackoverflow.com/questions/37662456/angular-2-output-from-router-outlet */
  emitChangeForNotifications(change: any) {
    this.emitChangeNotification.next(change);
  }

  sendHelpRequest(request) {
    let headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };
    return this.api.post("client/booking/help/request", request, headers, null);
  }

  getInfoProfile() {
    let headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };

    return this.api.get('client/info/profile', null, null, headers);
  }

  getInfo() {
    let headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };

    return this.api.get('client/info', null, null, headers);
  }

  deleteRequest() {
    const headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };
    return this.api.delete('client/booking/request/delete', null, headers);
  }

  getServi2Accounts() {
    let headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };
    return this.api.get('client/servi2/accounts', null, null, headers);
  }

  activeServi2Account(clientServi2Id) {
    let headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };
    return this.api.put("client/servi2/" + clientServi2Id + "/active", null, headers, null);
  }

  getServi2Account(clientServi2Id) {
    let headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };
    return this.api.get('client/servi2/accounts/' + clientServi2Id, null, null, headers);
  }

  sendCallbackRequest(request) {
    let headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };
    return this.api.post("client/callbacks/request", request, headers, null);
  }

  getActiveClientNews(page?: number, pageSize?: number, type?: number) {
    let headers = {

    };

    let params: any = {
      page: page,
      pagesize: pageSize,
      channel: 2
    };

    if (type) {
      params.type = type;
    }

    return this.api.get(`client/news/all`, params, null, headers);
  }

  getActiveClientNewsBySearch(search: Search) {
    let headers = {

    };

    let params = {
      ...search.params,
      channel: 2
    };

    return this.api.get(`client/news/all`, params, null, headers);
  }

  getNotificationsBySearch(search: Search) {
    let headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };
    const params = {
      ...search.params
    };
    let clientId = localStorage.getItem('clientId');
    return this.api.get(`client/${clientId}/notifications`, params, null, headers);
  }

  getClientNews(newsId: number) {
    let headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };
    return this.api.get(`client/news/${newsId}`, null, null, headers);
  }

  getClientProfileActiveAccount() {
    let headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };

    return this.api.get(`client/servi2/activeAccount`, null, null, headers);
  }
  getClientProfile() {
    let headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };

    return this.api.get(`client/info/profile`, null, null, headers);
  }

  /**
   * Function to make the request to update
   * profile phone number.
   * @param phone the new profile's phone
   * @param authProvider GOOGLE or FACEBOOK
   * @param authProviderId The provider's id stored at the DB
   */
  setClientProfilePhone(phone: string, authProvider: string, authProviderId: string): Observable<any> {
    const headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken'),
      providerId: authProviderId,
      provider: authProvider,
      phone
    };

    return this.api.put('client/userCellPhone', null, headers)
  }

  /**
   * To get user active account
   * @param userId id of user <<tuten_client>>
   */
  getUserData(userId: any): Observable<any> {
    const headers = {
      userId: userId,
      token: localStorage.getItem('sessionToken')
    };
    return this.api.get('client/servi2/activeAccount', null, null, headers);
  }

  /**
   * Return the availability of an email
   * @param email email to validate
   */
  getAvailableUserEmail(email: string, provider: string): Observable<{ email: string, isAvailable: boolean }> {
    const queryParams = { email, provider };
    return this.api.get('client/servi2/availableEmail', queryParams).pipe(
      first(),
      map(({ isAvailable, email }: any) => {
        return { isAvailable, email }
      })
    );
  }

  /**
   * Function to register client user
   * @param regiterUserData register User Data
   */
  registerUser(regiterUserData: TutenClientProfileData): Observable<any>{
    const body = { ...regiterUserData };
    return this.api.post('client/register', body);
  }

  /**
   * Web service to email vail validation
   * @param Emailvalidationtoken email token sent by email
   */
  validateEmailToken(Emailvalidationtoken: string): Observable<TutenClientProfile> {
    const headers = { Emailvalidationtoken };
    return this.api.put('client/servi2/validateEmail', null, headers).pipe(
      _mapTutenClientProfile()
    )
  }

  getEngieClientByIdentifiers(engieClientIndentifiers: TutenEngieClientIndentifier): Observable<any> {
    const body = { ...engieClientIndentifiers };
    return this.api.post('client/servi2/getEngieClientByIdentifiers', body);
  }


  getEngieClientByIdentifiersWithoutInstalationNumber(engieClientIndentifiers): Observable<any> {
    const body = { ...engieClientIndentifiers };
    return this.api.post('client/servi2/getEngieClientByIdentifiers', body);
  }

//Web service to restart password
  changeEmailPassword(email, captcha){
    const headers = {
      captcha: captcha
    }
    return this.api.get(`client/servi2/${email}/resetPassword`,null,null, headers);
  }


  updatePassword(passwordToken: any, newPassword: any) {
    const headers = {
      passwordToken: passwordToken,
      newPassword: newPassword
    };
    return this.api.put(`client/servi2/updatePassword`, null, headers)
  }


  changePasswordBySessionToken(newPassword: string, oldPassword: string) {
    const headers = {
      sessionToken: localStorage.getItem('sessionToken'),
      newPassword,
      oldPassword
    };
    return this.api.put(`client/servi2/changePasswordBySessionToken`, null, headers)
  }
  lay_On_Account(Token: string){
    const headers ={
      Token
    }
    return this.api.post(`client/active`,null,headers);
  }

 lay_Off_Account(token: string, Password: string , userId) {
  const headers = {
      token,
      Password,
    };
    return this.api.post(`client/${userId}/desactive`, null, headers)
  }

  putClientInfoProfile(profileEdit: TutenClientProfileData) {
    const headers = {
      userId: localStorage.getItem('clientId'),
      sessionToken: localStorage.getItem('sessionToken')
    };
    const body = profileEdit;
    return this.api.put(`client/info/profile`, body, headers, null)
  }

  postAccounts(engieCLientIdentifiers: TutenEngieClientIndentifier, password) {
    const headers = {
      userId: localStorage.getItem('clientId'),
      sessionToken: localStorage.getItem('sessionToken'),
      password
    };
    const body = engieCLientIdentifiers;
    return this.api.post('client/postAccounts', body, headers, null)
  }

  deleteAccounts(engieCLientIdentifiers: TutenEngieClientIndentifier, password) {
    const headers = {
      userId: localStorage.getItem('clientId'),
      sessionToken: localStorage.getItem('sessionToken'),
      password
    };
    const body = engieCLientIdentifiers;
    return this.api.put('client/deleteAccounts', body, headers, null)
  }

  putClientInfoAcounts(tutenEngieServi2Account: TutenEngieServi2Account, password) {
    const headers = {
      userId: localStorage.getItem('clientId'),
      sessionToken: localStorage.getItem('sessionToken'),
      password
    };
    const body = tutenEngieServi2Account;
    return this.api.put('client/info/accounts', body, headers, null)
  }

}
