import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ClientService } from "../../../core/api";
import { BookingsService } from "../../../core/api/bookings/bookings.service";
import { Subscription } from "rxjs";
import { getSelectObject } from "../../shared/constants";
import { PlansService } from "src/app/core/api/plans/plans.service";
import * as moment from "moment";
import { TutenClientCoverageResponse } from "src/app/core/api/plans/plans.interfaces";
import { Search } from "../../shared/search/search.component";
import { MessageService } from "primeng/api";

@Component({
  selector: "app-services",
  templateUrl: "./services.component.html",
  styleUrls: ["./services.component.scss"],
})
export class ServicesComponent implements OnInit, OnDestroy {
  search: Search;

  title = ""
  subtitle: any[] = ["Revisa el estado de tus asistencias"];
  dataAvailibity: any;
  userId: any;
  statesServices = getSelectObject();
  finishService = [3, -5];
  countRunningservice = [4, 5, 6, 7, 8, 11, 12];
  budgetForPay = [6];
  budgetForApprove = [11];
  dispServiceDate: any;
  bookings: any[];
  groups: any;
  groupsBookings: any;
  groupsBooking: any;
  bookingsParams: any = {
    filters: {
      caseId: undefined,
      fromDateSolicitud: undefined,
      toDateSolicitud: undefined,
      serviceDefinition: [],
      state: [],
      id: undefined,
      excessPaid: null
    },
  };
  unmodifiedServices: any;
  services: any;
  componentsSubscription: Subscription = new Subscription();
  assitencesType;

  constructor(
    private activate: ActivatedRoute,
    public clientService: ClientService,
    public bookingsService: BookingsService,
    private messageService: MessageService,
    private plansService: PlansService
  ) {
    localStorage.removeItem("nameGroup");
    this.statesServices = this.statesServices.filter((states) => states != "");
    const routeSubscr = this.activate.parent.params.subscribe((parametros) => {
      this.userId = parametros.id;
    });
    this.componentsSubscription.add(routeSubscr);
  }

  ngOnInit() {
    this.assitencesType = history.state.type;
    
    this.search = new Search(() => {
      this.getBookingsFilter();
    }, 4);

    this.getBookingsFilter();
    this.getClientPlan();
    this.getServicesGroupsNoId();
  }

  ngOnDestroy() {
    this.unsubscribeSubscription(this.componentsSubscription);
  }

  getBookingsFilter() {
    if (this.assitencesType === 'countRunning') {
      this.countRunningFilter();
      this.title = 'Asistencias en proceso';
    } else if (this.assitencesType === 'countFinish') {
      this.title = 'Asistencias finalizadas';
      this.finishFilter();
    } else if (this.assitencesType === 'countBudgetsForApprove') {
      this.title = 'Presupuestos por aprobar'
      this.budgetForApproveFilter();
    } else if (this.assitencesType === 'countbudgetForPay') {
      this.title = 'Historial de presupuestos'
      this.budgetForPayFiter();
    } else if(this.assitencesType === 'countPollNoResponse') {
      this.title='Pendientes de calificación';
      this.getPollNoResponse();

    } else {
      this.getBookings();
    }
  }

  getClientPlan(): void {
    const plansSubscription = this.plansService
      .getClientCoverage()
      .subscribe((response: TutenClientCoverageResponse) => {
        if (response) {
          this.dispServiceDate = moment(response.lastPeriodicityEndDate).format(
            "DD/MM/YYYY"
          );
        }
      });
    this.componentsSubscription.add(plansSubscription);
  }

  unsubscribeSubscription(subscription: Subscription): void {
    if (subscription && !subscription.closed) {
      subscription.unsubscribe();
    }
  }

  finishFilter() {
    this.bookingsParams.filters.state = this.finishService;
    this.getBookings();
  }

  countRunningFilter() {
    this.bookingsParams.filters.state = this.countRunningservice;
    this.getBookings();
  }

  budgetForPayFiter() {
    this.bookingsParams.filters.state = null;
    this.bookingsParams.filters.excessPaid = true;
    this.getBookings();
  }

  budgetForApproveFilter() {
    this.bookingsParams.filters.state = this.budgetForApprove;
    this.getBookings();
  }

  private getBookings() {
    this.services = null;
    const bookingSubscription = this.bookingsService
      .searchBookingsBySearch({
        search: this.search,
        body: this.bookingsParams.filters
      })
      .subscribe(
        (response: any) => {
          if (response != null) {
            this.bookings = response.results;
            this.search.totalRecords = response.total;
            if (
              this.unmodifiedServices != undefined &&
              this.groupsBookings != undefined
            ) {
              this.searchBookings();
            } else {
              this.getServices();
            }
          } else {
            this.messageService.add({
              severity: "warn",
              summary: "Info",
              detail: "Sin obtención de registros",
            });
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "No se pueden obtener las Asistencias",
          });
        }
      );
    this.componentsSubscription.add(bookingSubscription);
  }

  private getPollNoResponse() {
    this.services = null;
    const bookingSubscription = this.bookingsService
      .getBookingsIncompletePoll({
        search: this.search
      })
      .subscribe(
        (response: any) => {
          if (response != null) {
            this.bookings = response.results;
            this.search.totalRecords = response.total;
            if (
              this.unmodifiedServices != undefined &&
              this.groupsBookings != undefined
            ) {
              this.searchBookings();
            } else {
              this.getServices();
            }
          } else {
            this.messageService.add({
              severity: "warn",
              summary: "Info",
              detail: "Sin obtención de registros",
            });
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "No se pueden obtener las Asistencias",
          });
        }
      );
    this.componentsSubscription.add(bookingSubscription);
  }

  private getServices() {
    const servicesSubscription = this.bookingsService
      .getServicesDefinition()
      .subscribe(
        (response: any) => {
          if (response != null) {
            this.unmodifiedServices = response;
            this.getGroups();
          } else {
            this.messageService.add({
              severity: "warn",
              summary: "Info",
              detail: "Sin obtención de registros de Asistencias",
            });
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "No se pueden obtener las Asistencias",
          });
        }
      );
    this.componentsSubscription.add(servicesSubscription);
  }

  private getGroups() {
    const groupSubscription = this.bookingsService
      .getServicesGroups()
      .subscribe(
        (response: any) => {
          if (response != null) {
            this.groupsBookings = response;
            this.getAvailabilityCoveragesBooking();
          } else {
            this.messageService.add({
              severity: "warn",
              summary: "Info",
              detail: "Sin obtención de registros de grupos",
            });
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "No se pueden obtener las Asistencias",
          });
        }
      );
    this.componentsSubscription.add(groupSubscription);
  }

  private getAvailabilityCoveragesBooking() {
    const covergaesSubscr = this.clientService
      .getAvailabilityCoverages()
      .subscribe(
        (response: any) => {
          if (response !== undefined) {
            this.dataAvailibity = response;
            this.searchBookings();
          } else {
            this.messageService.add({
              severity: "warn",
              summary: "Info",
              detail: "Sin obtención de registros coberturas",
            });
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "No se pueden obtener los registros de coberturas",
          });
        }
      );
    this.componentsSubscription.add(covergaesSubscr);
  }

  private searchBookings() {
    const localService = this.bookings.map((el) => {
      const service = this.dataAvailibity.find(({ id }) => id == el.serviceId);
      return {
        ...el,
        serviceName: service ? service.name : "N/A",
        groupId: service ? service.groupId : "N/A",
      };
    });
    const localGroup = localService.map((el) => {
      const group = this.groupsBookings.find(({ id }) => id == el.groupId);
      return {
        ...el,
        groupName: group ? group.name : "N/A",
      };
    });
    this.services = localGroup.map((el) => {
      const {
        id: idBooking,
        caseId: idSolicitud,
        creationDate: date,
        serviceName: tipo,
        stateBookingName: state,
        groupName: grupo,
      } = el;
      return { idBooking, idSolicitud, date, grupo, tipo, state };
    });
  }

  public getServicesGroupsNoId() {
    const getServicesSubsc = this.bookingsService
      .getServicesGroupsNoId()
      .subscribe((response: any) => {
        if (response != undefined) {
          this.groupsBooking = response;
        }
      });
  }

}
