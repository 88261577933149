import { Component, OnInit, OnDestroy } from '@angular/core';
import {BookingsService} from "../../core/api/bookings/bookings.service";
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/interfaces/app.interface';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  openLoginForm:boolean;

  groups: any[] = [
    {
      icon: "assets/images/card-gas.png",
      name: "Gas"
    },
    {
      icon: "assets/images/card-hogar.png",
      name: "Hogar"
    },
    {
      icon: "assets/images/card-telemedicina.png",
      name: "Medicina"
    }
  ];

  componentSubscr: Subscription = new Subscription();

  constructor(public booking: BookingsService, private store: Store<AppState>) {
    this.store.select('home').subscribe(
      home => this.openLoginForm = home.openLoginForm
    )
  }

  ngOnInit() {
    this.getServicesGroupsNoId();
  }

  ngOnDestroy() {
    this.componentSubscr.unsubscribe();
  }

  public getServicesGroupsNoId() {
    const getServicesSubsc = this.booking.getServicesGroupsNoId().subscribe((response: any) => {
      if (response != undefined) {
        //this.groups = response;  // TODO aldemaro.guzman this line only was disable for client demo
      }
    });
    this.componentSubscr.add(getServicesSubsc);
  }

}
