import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'table-info',
  templateUrl: './table-info.component.html',
  styleUrls: ['./table-info.component.css']
})
export class TableInfoComponent implements OnInit {

  @Input()
  list: any;

  @Input()
  mensajeSinRegistro: any;

  @Input()
  mensajeSinCoincidencias: any;

  @Input()
  mensajeError: any;

  @Input()
  tamanioIcono: any;

  @Input()
  searching: boolean = false;

  constructor() {
    if (this.mensajeSinRegistro == null || this.mensajeSinRegistro == undefined) {
      this.mensajeSinRegistro = "No hay registros";
    }

    if (this.mensajeSinCoincidencias == null || this.mensajeSinCoincidencias == undefined) {
      this.mensajeSinCoincidencias = "No existen registros que coincidan con los parámetros de búsqueda";
    }

    if (this.mensajeError == null || this.mensajeError == undefined) {
      this.mensajeError = "Ha ocurrido un error al consultar los registros";
    }

    if (this.tamanioIcono == null || this.tamanioIcono == undefined) {
      this.tamanioIcono = "80";
    }
  }

  ngOnInit() {
    
  }

}
