import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/api';

@Component({
  selector: 'app-reminder-dialog',
  templateUrl: './reminder-dialog.component.html',
  styleUrls: ['./reminder-dialog.component.scss']
})
export class ReminderDialogComponent implements OnInit {

    constructor(
      public dialogRef:DynamicDialogRef, public config: DynamicDialogConfig
    ) {
    }

  ngOnInit() {
  }

  onClose() {
    this.dialogRef.close();
  }

}
