import {Component, Input, OnInit} from '@angular/core';
import {ClientService} from '../../../../core/api/client/client.service';
import {SnackbarService} from '../../../../core/api/snackbar.service';
import {Subscription} from 'rxjs';
import {BookingsService} from '../../../../core/api/bookings/bookings.service';
import {Location} from '@angular/common';
import {MESSAGE_TIMES} from '../../../shared/constants';

@Component({
  selector: 'app-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.scss']
})
export class AvailabilityComponent implements OnInit {

  title: any[] = ['Asistencia'];
  subtitle: any[] = [];
  dataAvailibity: any;
  groups: any;
  selectServices: any;
  tableAvailable: any;
  detailsAvailibity: any;
  componentsSubscription: Subscription = new Subscription();
  fixed = false;

  constructor(public clientService: ClientService,
              private snackBarService: SnackbarService,
              public bookingsService: BookingsService,
              private _location: Location) {

  }

  ngOnInit() {

  this.getServicesGroups();
  }

  zoom() {
    this.fixed = !this.fixed
  }

  ngOnDestroy() {
    this.componentsSubscription.unsubscribe();
  }

  backClicked() {
    this._location.back();
  }

  getServicesGroups() {
    const serviceSubscr =
      this.bookingsService.getServicesGroups().subscribe((response: any) => {
        if (response !== undefined) {
          /**
           * [Crea grupos de servicios]
           */
          this.groups = [];
          for (var i = 0; i < response.length; i++) {
            this.groups.push({
              name: response[i].name,
              id: response[i].id,
              availableBookings: null,
              services: []
            });
          }
        }
        this.getAvailabilityCoverages();
      });
    this.componentsSubscription.add(serviceSubscr);
  }

  public getAvailabilityCoverages() {
    this.clientService.getAvailabilityCoverages().subscribe((response: any) => {
      if (response !== undefined) {
        this.dataAvailibity = response;
        let linkedServicesIds = [];
        let productsGroupsIds = [];

        /**
         * [ID precios preferenciales o linked services ]
         */
        for (var i = 0; i < response.length; i++) {
          if (response[i].linkedService != null) {
            linkedServicesIds.push(response[i].linkedService.id);
          }
        }

        /**
         * [productos por grupo]
         */
        for (var i = 0; i < response.length; i++) {
          if (response[i].type == 'GROUP') {
            productsGroupsIds.push(response[i].id);
            for (var f = 0; f < this.groups.length; f++) {
              if (this.groups[f].id == response[i].id) {
                this.groups[f].productId = response[i].productId;
                this.groups[f].grouptId = response[i].grouptId;
                this.groups[f].available = response[i].available;
                this.groups[f].isUnlimited = response[i].isUnlimited;
                this.groups[f].coveredAmount = response[i].coveredAmount;
                this.groups[f].requiresAsignation = response[i].requiresAsignation;
                this.groups[f].isInmediate = response[i].isInmediate;
                this.groups[f].resumeId = response[i].resumeId;
              }

            }
          }
        }

        /**
         * [cobertura por ramo]
         */
        this.selectServices = {...this.groups};
        for (var a = 0; a < this.selectServices.length; a++) {
          if (productsGroupsIds.indexOf(this.selectServices[a].id) >= 0) {
            for (var i = 0; i < response.length; i++) {
              if (this.selectServices[a].id == response[i].groupId) {
                /**
                 * [coloca el producto si ilimitado o si es limitado con disponibilidad mayor a cero]
                 */
                if ((linkedServicesIds.indexOf(response[i].id) == -1 && this.selectServices[a].isUnlimited == 'ilimitado') || (linkedServicesIds.indexOf(response[i].id) == -1 && this.selectServices[a].available > 0)) {
                  response[i].linkedService = null;
                  response[i].isLinkedService = false;
                  this.selectServices[a].services.push(response[i]);
                }

                /**
                 * [coloca el linkedservice si tiene y la disponibildad menor igual a 0]
                 */
                if (this.selectServices[a].isUnlimited == 'limitado' && linkedServicesIds.indexOf(response[i].id) >= 0 && this.selectServices[a].available <= 0) {
                  this.selectServices[a].services.push({
                    id: response[i].id,
                    name: response[i].name,
                    available: null,
                    type: 'SERVICE',
                    groupId: this.selectServices[a].id,
                    productId: null,
                    isUnlimited: 'ilimitado',
                    coveredAmount: this.selectServices[a].coveredAmount,
                    isInmediate: this.selectServices[a].isInmediate,
                    requiresAsignation: response[i].requiresAsignation,
                    linkedService: null,
                    isLinkedService: true
                  });
                }
              }
            }
          }
        }

        /**
         * [productos por servicio]
         */
        for (var i = 0; i < response.length; i++) {
          for (var a = 0; a < this.selectServices.length; a++) {
            if (this.selectServices[a].id == response[i].groupId && response[i].type == 'SERVICE') {
              /**
               * [coloca el producto si ilimitado o si es limitado con disponibilidad mayor a cero]
               */
              if (response[i].isUnlimited == 'ilimitado' || (response[i].isUnlimited == 'limitado' && response[i].available > 0)) {
                response[i].linkedService = null;
                response[i].isLinkedService = false;
                this.selectServices[a].services.push(response[i]);
              }

              /**
               * [coloca el linkedservice si tiene y la disponibildad menor igual a 0]
               */
              if (response[i].isUnlimited == 'limitado' && response[i].available <= 0 && response[i].linkedService != null) {
                this.selectServices[a].services.push({
                  id: response[i].linkedService.id,
                  name: response[i].linkedService.name,
                  available: this.selectServices[a].available,
                  type: 'SERVICE',
                  groupId: this.selectServices[a].id,
                  productId: null,
                  isUnlimited: 'ilimitado',
                  coveredAmount: response[i].linkedService.amountCovered,
                  isInmediate: response[i].linkedService.isInmediate,
                  requiresAsignation: response[i].linkedService.requiresAsignation,
                  linkedService: null,
                  isLinkedService: true
                });
              }
            }
          }
        }

        /**
         * [Arma objeto de tabla de disponibilidad cobertura por ramo]
         */
        this.tableAvailable = this.groups;
        for (var a = 0; a < this.tableAvailable.length; a++) {
          if (productsGroupsIds.indexOf(this.tableAvailable[a].id) >= 0) {
            for (var i = 0; i < response.length; i++) {
              if (this.tableAvailable[a].id == response[i].groupId) {
                /**
                 * [coloca el producto si ilimitado o si es limitado con disponibilidad mayor a cero]
                 */
                if (linkedServicesIds.indexOf(response[i].id) == -1) {
                  response[i].linkedService = null;
                  response[i].isLinkedService = false;
                  this.tableAvailable[a].services.push(response[i]);
                }
              }
            }
          }
        }

        /**
         * [Arma objeto de tabla de disponibilidad cobertura por servicios]
         */
        for (var i = 0; i < response.length; i++) {
          for (var a = 0; a < this.tableAvailable.length; a++) {
            if (this.tableAvailable[a].id == response[i].groupId && response[i].type == 'SERVICE') {
              /**
               * [coloca el producto si ilimitado o si es limitado con disponibilidad mayor a cero]
               */
              if (response[i].isUnlimited == 'ilimitado' || (response[i].isUnlimited == 'limitado' && linkedServicesIds.indexOf(response[i].id) == -1)) {
                response[i].linkedService = null;
                response[i].isLinkedService = false;
                this.tableAvailable[a].services.push(response[i]);
              }
            }
          }
        }
      }
      this.availibity();

    }, error => {
      this.snackBarService.openSnackBar(MESSAGE_TIMES.ERROR_MESSAGE, '', 'error'); //TODO Agregar texto de error para el llamado de backend
    });
    
  }

  availibity() {
    const DETAILS = this.tableAvailable.filter((ele: any) =>
      ele.name === localStorage.getItem('nameGroup')
    );
    this.detailsAvailibity = DETAILS;
    this.title = this.detailsAvailibity[0].name;
  }
}
