import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebard',
  templateUrl: './sidebard.component.html',
  styleUrls: ['./sidebard.component.scss']
})

export class SidebardComponent implements OnInit {

  propiedades: Object = {
    display: true
  };


  constructor() { }

  ngOnInit() {
  }

}
