import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ClientService } from './core/api';
import { NavigationEnd, NavigationStart, Router, ActivatedRoute } from "@angular/router";
import { API_MAIN_URL } from "./components/shared/constants";
import { ngxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';
import { userInfoObj } from './core/zendesk/zendes.interfaces';
import { DepartmentCriteria, DEFAULT_DEPARTMENT } from "./core/zendesk/zendesk.classes";
import { Subscription } from 'rxjs';
import { AssistanceObserver } from './core/api/bookings/booking.interfaces';
import { BookingsService } from './core/api/bookings/bookings.service';
import { CallDialogComponent } from './components/dashboard/shared/call-dialog/call-dialog.component';
import { MatDialog } from '@angular/material';
import { WebsocketService } from './websocket.service';
import { Store } from '@ngrx/store';
import { AppState } from './interfaces/app.interface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})


export class AppComponent implements OnInit, OnDestroy {
  nombre;
  telefono;
  motivo;
  url: string = API_MAIN_URL;
  href: string = "";
  dashboard: boolean;
  isLogingView: boolean;
  componentSubscriptions: Subscription = new Subscription();
  viewCallback: boolean;
  name_user: String;
  loading: boolean;
  isLogged: boolean;


  constructor(
    private clientService: ClientService,
    private bookingService: BookingsService,
    private router: Router,
    private _ngxZendeskWebwidgetService: ngxZendeskWebwidgetService,
    public dialog: MatDialog,
    private _wsService: WebsocketService,
    private store: Store<AppState>
  ) {
    this._wsService.mensajeAprobacion()
      .subscribe((data:any)=> {
        // console.log("---- Mensaje privado ---");
        this.viewCallback = true;
        this.name_user = data.usuario;
      });

      this.store.select('app').subscribe(
        app => {
          this.loading = app.loading;
          this.isLogged = app.isLogged;
        }
      )
   };


  ngOnInit() {
    this.joinWebSocket();
    this.implementsLoggedUserLogic();
    this.setChatUser(this.clientService.isAuthenticated());
    this.componentSubscriptions.add(this.setInitialChatUserSubscription());
    this.componentSubscriptions.add(this.setAssistanceObserverSubscription())
  }

  @HostListener('window:beforeunload', ['$event'])
    beforeunloadHandler(event) {
      if(localStorage.getItem("rememberMe") === null || localStorage.getItem("rememberMe") === "false") {
        localStorage.clear();
      }
    }

  ngOnDestroy() {
    this.componentSubscriptions.unsubscribe();
  }

  setChatUser(isAuthenticated: boolean): void {
    let identifyObj: userInfoObj = { name: 'Invitado Servi2', email: 'invitadoServi2@tuten.cl' }
    if (isAuthenticated) {
      identifyObj.name = localStorage.getItem('nameuser') + ' ' + localStorage.getItem('apellidouser');
      identifyObj.email = localStorage.getItem('emailuser');
    }
    this._ngxZendeskWebwidgetService.identify(identifyObj);
  }

  onCloseDialogCallback(){
    this.viewCallback = false;
  }

  setInitialDepartment(): void {
    const departmenCriteria = new DepartmentCriteria(this.clientService.isAuthenticated());
    // console.log('DEPARTMENT INITIAL: ', departmenCriteria.toString());
    this.setDepartment(departmenCriteria);
  }

  setAssistanceObserverSubscription(): Subscription {
    return this.bookingService.getAssistanceSubjectAsObserver()
      .subscribe((assistanceData: AssistanceObserver) => {
        const isLoggedUser = this.clientService.isAuthenticated();
        // If assistanceStatus is on Way true
        const isInRouteStateBooking = assistanceData.assistanceStatus === 5;
        const department = isInRouteStateBooking ? `asistencia${assistanceData.assistanceId}` : DEFAULT_DEPARTMENT;
        const departmenCriteria = new DepartmentCriteria(isLoggedUser, isInRouteStateBooking, department);
        this.setDepartment(departmenCriteria);
      });
  }

  setDepartment(departmenCriteria: DepartmentCriteria) {
    let enabled: string[] = [DEFAULT_DEPARTMENT];
    if (departmenCriteria.isInRouteStateBooking)
      enabled.push(departmenCriteria.department);
    // console.log(enabled);
    this._ngxZendeskWebwidgetService.setSettings({
        chat: {
          departments: {
            enabled,
            // select: departmenCriteria
          }
        }
      })
  }


  toggleChat() {
    this._ngxZendeskWebwidgetService.activate({ hideOnClose: true })
  }


  joinWebSocket(){
    //Realizar conexión con el Sockets y un topico
    let  user = "Cliente_" + Math.floor((Math.random() * 100000) + 1);
    let topic = "topicGeneral";
    this._wsService.joinConnection({nombre: user, topic:topic, type: 'client_solicitud_digital'})
    .subscribe((...data: any[])=>{
        // console.log("Usuario conectado WebSocket al topic " + topic);
    });
  }

  //Aqui esta Dialog para solicitar una llamada
  openCallDialog(): void {
    const dialogRef = this.dialog.open(CallDialogComponent, {
      width: '300px',
      data: {nombre: this.nombre, telefono: this.telefono , motivo: this.motivo}
    });

    dialogRef.afterClosed().subscribe(result => {});
  }

  setInitialChatUserSubscription(): Subscription {
    return this.clientService.getLogingSubjectAsObserver().subscribe((auhtenticated: boolean) => {
      this.setChatUser(auhtenticated);
    })
  }

  implementsLoggedUserLogic(): void {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.href = this.router.url;
        let respHref = (this.href.split('/'));
        const excludedViews = ['home'];
        const excludedViewsNavbar = ['validate_email', 'login']
        this.isLogingView = excludedViewsNavbar.includes(respHref[1].split('?')[0]);
        this.dashboard = excludedViews.includes(respHref[1]);
        // console.log("EVENT: ", event);
        this.setInitialDepartment();
      }
    });
  }


  public validateTokenClient() {
    this.clientService.validateTokenClient().subscribe((data: any) => {
      // console.log(data);
    });
  }
}

