import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpUrlEncodingCodec  } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgProgressModule, NgProgressInterceptor } from 'ngx-progressbar';
import { AgmCoreModule } from '@agm/core';
import { MAT_DATE_LOCALE } from "@angular/material";
import {CarouselModule} from "ngx-owl-carousel-o";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {ScrollingModule} from "@angular/cdk/scrolling";
import {FullCalendarModule} from "ng-fullcalendar";
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from 'angular2-moment';
import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider} from "angularx-social-login";

// Services
import { AuthGuard } from "./core/api/client/auth.guard";
import { SnackbarService } from './core/api/snackbar.service';
import { ProgressBarService } from './core/api/progress-bar.service';
import { WebsocketService } from './websocket.service';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/api';

//Pipes
import { isRequiredPipe } from './core/pipes/isRequired.pipe';
import { PhonePipe } from './core/pipes/phone.pipe';
import { RangePipe } from './core/pipes/range.pipe';

//Directives
import OnlyNumbers from './core/directives/only-numbers';
import { ResponsiveColumnsDirective } from "./core/directives/responsive-columns.directive";

// ngrx
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment.prod';


// reducers
import { homeReducer } from './reducers/home.reducer';
import { appReducer } from './reducers/app.reducer';
import { notificationsReducer } from './reducers/notifications.reducer';

// Components
import { DialogComponent } from './components/./solicitud/dialog.component';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { SolicitudComponent } from './components/solicitud/solicitud.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { SidebardComponent } from './components/dashboard/shared/sidebard/sidebard.component';
import { ResumenComponent } from './components/dashboard/resumen/resumen.component';
import { ServicesComponent } from './components/dashboard/services/services.component';
import {ProfileComponent} from "./components/dashboard/profile/profile.component";
import { ContrasenaComponent } from './components/dashboard/contrasena/contrasena.component';
import {AssistanceComponent} from "./components/dashboard/assistance/assistance.component";
import { CalendarioComponent } from './components/shared/calendario/calendario.component';
import { ExitoComponent } from './components/solicitud/exito/exito.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { TermsComponent } from './components/terms/terms.component';
import { LogoutDialogComponent } from './components/shared/logout-dialog/logout-dialog.component';
import { AudioDialogComponent } from './components/shared/audio-dialog/audio-dialog.component';
import { AvailabilityComponent } from './components/dashboard/plans/availability/availability.component';
import { BaseDialogComponent } from './components/shared/base-dialog/base-dialog.component';
import { ReagendarComponent } from './components/dashboard/reagendar/reagendar.component';
import { NotificationsComponent } from './components/dashboard/notifications/notifications.component';
import { LoadingComponent } from './components/shared/loading/loading.component';
import { ProgresbarComponent } from './components/shared/progresbar/progresbar.component';
import { SnackbarComponent } from './components/shared/snackbar/snackbar.component';
import { ReminderDialogComponent } from './components/shared/reminder-dialog/reminder-dialog.component';
import { PollComponent } from "./components/dashboard/assistance/poll/poll.component";
import { NoticiasComponent } from './components/dashboard/noticias/noticias.component';
import { NewsComponent } from './components/dashboard/noticias/news/news.component';
import { HelpRequestComponent } from './components/dashboard/help-request/help-request.component';
import { AyudaComponent } from './components/ayuda/ayuda.component';
import { GuiaRapidaComponent } from './components/guia-rapida/guia-rapida.component';
import { AssistPollIncompleteComponent } from './components/dashboard/services/assist-poll-incomplete/assist-poll-incomplete.component';
import { ServicesBudgetForPayComponent } from './components/dashboard/services/services-budget-for-pay/services-budget-for-pay.component';
import { HomeNoEngieComponent } from './components/home/home-no-engie/home-no-engie.component';
import { CallDialogComponent } from './components/dashboard/shared/call-dialog/call-dialog.component';
import { DialogRegisterComponent } from './components/shared/dialog-register/dialog-register.component';
import { ClientenoengieComponent } from './components/clientenoengie/clientenoengie.component';
import { Servi2AccountsComponent } from './components/servi2-accounts/servi2-accounts.component';
import { Servi2AccountDetailsComponent } from './components/servi2-account-details/servi2-account-details.component';
import { CancelAssistanceComponent } from "./components/dashboard/assistance/cancel-assistance/cancel-assistance.component";
import { ServicesBudgetToApproveComponent } from './components/dashboard/services/services-budget-to-approve/services-budget-to-approve.component';
import { ApproveDialogComponent } from './components/dashboard/shared/approve-dialog/approve-dialog.component';
import { CellphoneRegisterComponent } from './components/shared/cellphone-register/cellphone-register.component';
import { PlansComponent } from './components/dashboard/plans/plans.component';
import { ContractedPlanComponent } from './components/dashboard/shared/contracted-plan/contracted-plan.component';
import { RejectBudgetDialogComponent } from './components/dashboard/shared/reject-budget-dialog/reject-budget-dialog.component';
import { RegisterComponent } from './components/login/register-component/register-component.component';
import { ValidateEmailComponent } from './components/validateemail/validate-email.component';
import { EngieDetailsComponent } from './components/login/register-component/engie-details/engie-details.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MaterialModule} from './material/material.module';
import { HttpErrorInterceptor } from './core/global/http-error.nterceptor';
import { ngxZendeskWebwidgetModule } from 'ngx-zendesk-webwidget';
import { ZendeskConfig } from './core/zendesk/zendesk.config';
import { DownuserComponent } from './components/dashboard/downuser/downuser.component';
import { SideNotificationsComponent } from './components/dashboard/shared/side-notifications/side-notifications.component';
import { LayOnComponent } from './components/shared/lay-on/lay-on.component';
import { CustomMatPanelComponent } from './components/shared/custom-mat-panel/custom-mat-panel.component';
import { ChatComponent } from './components/dashboard/assistance/chat/chat.component';
import { MessageComponent } from './components/dashboard/assistance/chat/message/message.component';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { HeaderMenuComponent } from './components/header-menu/header-menu.component';
import { HeaderAppComponent } from './components/header-app/header-app.component';
import { HeaderMenuMobileComponent } from './components/header-menu-mobile/header-menu-mobile.component';
import { SidebarModule } from 'primeng/sidebar';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { CheckboxModule } from 'primeng/checkbox';
import { BannerHomeDetailsComponent } from './components/home/banner-home-details/banner-home-details.component';
import { CardModule } from 'primeng/card';
import { LoginFormMobileComponent } from './components/login-form-mobile/login-form-mobile.component';
import { RegisterFormComponent } from './components/register-form/register-form.component';
import { AsistBannerDetailsComponent } from './components/home/asist-banner-details/asist-banner-details.component';
import { BannerRegisterDetailsComponent } from './components/register-form/banner-register-details/banner-register-details.component';
import { TooltipModule } from 'primeng/tooltip';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { ClientEngieFormComponent } from './components/register-form/client-engie-form/client-engie-form.component';
import { ForgotPasswordFormComponent } from './components/forgot-password-form/forgot-password-form.component';
import { BannerForgotDetailsComponent } from './components/forgot-password-form/banner-forgot-details/banner-forgot-details.component';
import { ChangePasswordFormComponent } from './components/change-password-form/change-password-form.component';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AuthAssertNotLoginGuard } from './core/api/client/auth-assert-not-login.guard';
import { AuthAssertLoginGuard } from './core/api/client/auth-assert-login.guard';
import { AvatarComponent } from './components/shared/navbar/avatar/avatar.component';
import { BannerEngieClientComponent } from './components/dashboard/resumen/banner-engie-client/banner-engie-client.component';
import { PromotionsComponent } from './components/shared/promotions/promotions.component';
import { TableInfoComponent } from './components/shared/table-info/table-info.component';
import { SpinnerComponent } from './components/shared/spinner/spinner.component';
import { ServiceHeaderComponent } from './components/shared/service-header/service-header.component';
import { AsistencesComponent } from './components/dashboard/asistences/asistences.component';
import { FilePoolComponent } from './components/dashboard/shared/file-pool/file-pool.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { ExceedDialogComponent } from './components/solicitud/exceed-dialog/exceed-dialog.component';
import { NotificationsPanelComponent } from './components/shared/navbar/notifications-panel/notifications-panel.component';
import { AccordionModule } from 'primeng/accordion';
import { DialogModule } from "primeng/dialog"
import { MedicineDialogComponent } from './components/solicitud/medicine-dialog/medicine-dialog.component';
import { TelemedicineDialogComponent } from './components/solicitud/telemedicine-dialog/telemedicine-dialog.component';
import { DropdownModule } from 'primeng/dropdown';
import { AxaComponent } from './components/solicitud/axa/axa.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CalendarModule } from 'primeng/calendar';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("750230125882-9u0ttpv5io75miblfbomj6ke9tcpqqkn.apps.googleusercontent.com")
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("675474882908279")
  }
]);

export function provideConfig() {
  return config;
}

@NgModule({
  entryComponents:[
    DialogComponent,
    LogoutDialogComponent,
    AudioDialogComponent,
    CallDialogComponent,
    ApproveDialogComponent,
    BaseDialogComponent,
    SnackbarComponent,
    ReminderDialogComponent,
    DialogRegisterComponent,
    CancelAssistanceComponent,
    CellphoneRegisterComponent,
    RejectBudgetDialogComponent,
    ExceedDialogComponent,
    ReagendarComponent,
    MedicineDialogComponent,
    TelemedicineDialogComponent
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    CallDialogComponent,
    ApproveDialogComponent,
    SolicitudComponent,
    DashboardComponent,
    NavbarComponent,
    SidebardComponent,
    ResumenComponent,
    ServicesComponent,
    ProfileComponent,
    ContrasenaComponent,
    AssistanceComponent,
    CalendarioComponent,
    ExitoComponent,
    FooterComponent,
    PromotionsComponent,
    PrivacyComponent,
    TermsComponent,
    DialogComponent,
    LogoutDialogComponent,
    AudioDialogComponent,
    AvailabilityComponent,
    BaseDialogComponent,
    ReagendarComponent,
    NotificationsComponent,
    LoadingComponent,
    ProgresbarComponent,
    SnackbarComponent,
    ReminderDialogComponent,
    PollComponent,
    NoticiasComponent,
    isRequiredPipe,
    NewsComponent,
    DialogRegisterComponent,
    ClientenoengieComponent,
    HelpRequestComponent,
    AyudaComponent,
    GuiaRapidaComponent,
    OnlyNumbers,
    ResponsiveColumnsDirective,
    AssistPollIncompleteComponent,
    HomeNoEngieComponent,
    Servi2AccountsComponent,
    Servi2AccountDetailsComponent,
    ServicesBudgetForPayComponent,
    PhonePipe,
    CancelAssistanceComponent,
    ServicesBudgetToApproveComponent,
    RangePipe,
    ApproveDialogComponent,
    CellphoneRegisterComponent,
    PlansComponent,
    ContractedPlanComponent,
    RejectBudgetDialogComponent,
    RegisterComponent,
    ValidateEmailComponent,
    EngieDetailsComponent,
    DownuserComponent,
    SideNotificationsComponent,
    LayOnComponent,
    CustomMatPanelComponent,
    ChatComponent,
    MessageComponent,
    HeaderMenuComponent,
    HeaderAppComponent,
    HeaderMenuMobileComponent,
    LoginFormComponent,
    BannerHomeDetailsComponent,
    LoginFormMobileComponent,
    RegisterFormComponent,
    AsistBannerDetailsComponent,
    BannerRegisterDetailsComponent,
    ClientEngieFormComponent,
    ForgotPasswordFormComponent,
    BannerForgotDetailsComponent,
    ChangePasswordFormComponent,
    AvatarComponent,
    BannerEngieClientComponent,
    TableInfoComponent,
    SpinnerComponent,
    ServiceHeaderComponent,
    AsistencesComponent,
    FilePoolComponent,
    ExceedDialogComponent,
    NotificationsPanelComponent,
    MedicineDialogComponent,
    TelemedicineDialogComponent,
    AxaComponent
  ],
  imports: [
    ngxZendeskWebwidgetModule.forRoot(ZendeskConfig),
    NgProgressModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    CarouselModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    ScrollingModule,
    FullCalendarModule,
    MomentModule,
    NgIdleKeepaliveModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB3PlzlhobsZ8tXcr5gBx14uq348T-uKjM',
      libraries:["places", "geometry"]
    }),
    SocialLoginModule,
    FlexLayoutModule,
    MatTooltipModule,
    MaterialModule,
    ButtonModule,
    OverlayPanelModule,
    SidebarModule,
    CheckboxModule,
    CardModule,
    StoreModule.forRoot({ home: homeReducer, app: appReducer, notifications: notificationsReducer }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    }),
    TooltipModule,
    ProgressSpinnerModule,
    ToastModule,
    TableModule,
    PaginatorModule,
    InputTextareaModule,
    DynamicDialogModule,
    DialogModule,
    FileUploadModule,
    AccordionModule,
    DropdownModule,
    RadioButtonModule,
    CalendarModule,
    RecaptchaV3Module
  ],
  providers: [
    MessageService,
    AuthGuard,
    AuthAssertNotLoginGuard,
    AuthAssertLoginGuard,
    SnackbarService,
    ProgressBarService,
    WebsocketService,
    HttpUrlEncodingCodec,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    { provide: HTTP_INTERCEPTORS, useClass: NgProgressInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'es' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,

      multi: true
    },
    DialogService,
    { 
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6LcvRlMmAAAAALqdKz0VXg6mD2bmOZ4pb7tILMzZ'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
