import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormGroupDirective, NgForm, AbstractControl, FormArray } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material';
import { ClientService } from 'src/app/core/api/client/client.service';
import { timer, Subscription } from 'rxjs';
import { map, switchMap, first } from 'rxjs/operators';
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js/min';
import { TutenClientProfileData, TutenClient, TutenEngieClientIndentifier, TutenClientProfile } from 'src/app/core/api/client/client-interfaces';

import { HttpErrorResponse } from '@angular/common/http';
import { getClientIdsFromForm, getEngieDataFormGroup } from './engie-details/engie-details.component';
import { AppState } from 'src/app/interfaces/app.interface';
import { Store } from '@ngrx/store';
import * as actionTypes from 'src/app/actions/app.actions';


// Custom Error states matchers to trigger mat-error flag at templeate
class RepeatPasswordEStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return (control && control.parent.get('password').value !== control.parent.get('confirmPassword').value && control.dirty)
  }
}

class RepeatEmailEStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return (control && control.parent.get('email').value !== control.parent.get('confirmEmail').value && control.dirty)
  }
}


@Component({
  selector: 'app-register-component',
  templateUrl: './register-component.component.html',
  styleUrls: ['./register-component.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {

  @Input()
  url: string;
  registerFormGroup: FormGroup;
  passwordMatcher = new RepeatPasswordEStateMatcher();
  emailMatcher = new RepeatEmailEStateMatcher();
  componentSubs: Subscription = new Subscription();
  registeredClient = null;
  validIndexes: boolean[] = [];
  errorEngieMessage: string[] = [];
  loading: boolean = false;

  
  constructor(
    private formBuilder: FormBuilder,
    private clientService: ClientService,
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.setRegisterFormGroup();

  }

  ngOnDestroy() {
    this.componentSubs.unsubscribe();
  }

  /**
   * Setting form group and controls
   */
  setRegisterFormGroup() {
    this.registerFormGroup = this.formBuilder.group({
      isEngie: new FormControl(),
      engieAccountDatas: this.formBuilder.array([
        getEngieDataFormGroup(),
      ]),
      name: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.email
      ]),
        this.validateEmailTaken(this.clientService)
      ),
      phoneNumber: new FormControl('', Validators.compose([
        Validators.required,
        this.customNumberValidator
      ])),
      confirmEmail: new FormControl('', Validators.compose([
        Validators.required,
        Validators.email
      ])),
      //6 caracteres de largo, 1 mayuscula, 1 minuscula, 1 caracter especia, sin espacio
      password: new FormControl('', [
        Validators.required, 
        Validators.pattern('^(?=.*\\\d)(?=.*[\\\u002D-\\\u002E\\\u0040\\\u005F\\\u002A.])(?=.*[A-Z])(?=.*[a-z])\\S{6,}$')
      ]),
      confirmPassword: new FormControl('', Validators.required),
      termsAndConditions: new FormControl('', Validators.compose([
        Validators.required,
        this.customRequiredCheckValidator,
      ]))
    }, { validator: this.globalFormValidator })
  }

  /**
   * Function to validate requirements of form provided
   * @param form Form to validate
   */
  globalFormValidator(form: FormGroup): { [key: string]: boolean } | null {
    const password = form.get('password');
    const confirmPassword = form.get('confirmPassword');
    const email = form.get('email');
    const confirmEmail = form.get('confirmEmail');
    let errorObj = {};
    if (password.dirty && confirmPassword.dirty && password.value != confirmPassword.value) {
      errorObj['passwordsNotEqual'] = true
    }
    if (email.dirty && confirmEmail.dirty && email.value != confirmEmail.value) {
      errorObj['emailsNotEqual'] = true;
    }
    return !!Object.keys(errorObj).length ? errorObj : null;
  }

  /**
   * Async validator to validate email Taken
   * @param clientService service
   * @param time delay time
   */
  validateEmailTaken(clientService: ClientService, time: number = 500): any {
    return (input: FormControl) => {
      return timer(time).pipe(
        first(),
        switchMap(() => clientService.getAvailableUserEmail(input.value, 'CLIENT')),
        map((res: any) => {
          return res.isAvailable ? null : { emailTaken: true }
        })
      );
    };
  }

  
  /**
   * Validator to phones with mx format
   * @param control control to be evaluated
   */
  customNumberValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value) {
      const countryCode: CountryCode = 'MX' as CountryCode;
      try {
        const phoneNumber = parsePhoneNumber(control.value + '', countryCode);
        return phoneNumber.isValid() ? null : { invalidNumber: true };
      } catch (error) {
        return { invalidNumber: true };
      }
    }
    return null;
  }

  //Custom validator to force to check control
  customRequiredCheckValidator(control: AbstractControl): { [key: string]: boolean } | null {
    return control.value ? null : { isRequired: true };
  }

  /**
   * Function to make submit and redirect user
   */
  async submitForm() {
    this.loading = true;
    const { email, password, isEngie, name, lastName, phoneNumber } = this.registerFormGroup.value;
    const tutenClientsIds: number[] = getClientIdsFromForm(isEngie, this.registerFormGroup);
    const registerUserData: TutenClientProfileData = {
      email,
      password,
      engie: Boolean(isEngie).valueOf(),
      firstName: name,
      lastName,
      phone: phoneNumber,
      name: name + ' ' + lastName,
      provider: 'CLIENT',
      tutenClientsIds,
      authToken: '',
      id: '',
      idToken: '',
      photoUrl: ''
    };

    this.clientService.registerUser(registerUserData).subscribe((response: any) => {
      console.log(response)
      if (response.status == 200) {
        this.registeredClient = true;
      }
    }, (err: any) => {
      this.store.dispatch(new actionTypes.CloseLoadingActions());
    });
  }


}
