import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PlansService} from 'src/app/core/api/plans/plans.service';
import {BookingsService} from '../../../core/api/bookings/bookings.service';
import {MatTableDataSource} from '@angular/material';
import {Subscription} from 'rxjs';
import {ClientService} from '../../../core/api/client/client.service';
import {FormControl, FormGroup} from '@angular/forms';
import {ProgressBarService} from '../../../core/api/progress-bar.service';
import * as moment from 'moment';
import { TutenClientCoverageResponse } from 'src/app/core/api/plans/plans.interfaces';
import { statesBooking } from 'src/app/components/shared/constants';
import {DomSanitizer} from '@angular/platform-browser';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-dashboard',
  templateUrl: './resumen.component.html',
  styleUrls: ['./resumen.component.scss']
})

export class ResumenComponent implements OnInit, OnDestroy {

  public isEngieClient: boolean = false;
  loading: boolean;
  bookings: any;
  bookingSubscription: Subscription;
  groups: any;
  groupSubscription: Subscription;
  displayedColumns = ['idSolicitud', 'date', 'grupo', 'tipo', 'state', 'icon'];
  dataSource: MatTableDataSource<any>;
  countServices: any = {
    countRunning: undefined,
    countFinish: undefined,
    countPollNoResponse: undefined,
    countbudgetForPay: undefined
  };
  countLoaded: boolean = false;
  bookingsParams: any = {
    pagination: {
      page: 1,
      pagesize: 6,
      // intervals: [5, 10, 25, 50, 100]
    },
    filters: {
      caseId: undefined,
      serviceId: undefined,
      fromDateSolicitud: undefined,
      toDateSolicitud: undefined,
    },
    total: 0
  };
  name: string = localStorage.getItem('nameuser') != null && localStorage.getItem('apellidouser') != null ? localStorage.getItem('nameuser') + ' ' + localStorage.getItem('apellidouser') : '';
  title: any = name;
  subtitle: any[] = ['Aquí podrás gestionar todas tus asistencias de tu plan contratado'];
  planContractor: any[];
  dispServiceDate: string;
  userId: number;
  dataAvailibity: any;
  tabla: FormGroup;
  isLoading: any = {
    plansLoading: true,
    bookingsLoading: true
  };
  groupsBookings: any;

  infoServices: any[] = [
    {cantidad: '...', titulo: 'Asistencias en proceso', keyObject: 'countRunning', url:'/dashboard/asistencias'},
    {cantidad: '...', titulo: 'Asistencias finalizadas', keyObject: 'countFinish', url: '/dashboard/asistencias'},
    {cantidad: '...', titulo: 'Pendientes de calificación', keyObject: 'countPollNoResponse', url: '/dashboard/asistencias'},
    {cantidad: '...', titulo: 'Presupuesto por aprobar', keyObject: 'countBudgetsForApprove', url: '/dashboard/asistencias'},
    {cantidad: '...', titulo: 'Historial de presupuestos', keyObject: 'countbudgetForPay', url: '/dashboard/asistencias'},
  ];

  // Carrusel de botonera
  customOptions: any = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      380: {
        items: 2
      },
    },
    nav: false
  };
  unmodifiedServices: any;
  servicesSubscription: Subscription;
  countServicesSubscription: Subscription;
  availabilityCoverageSubscription: Subscription;
  planSubscription: Subscription;
  componentsSubscription: Subscription;
  ON_ARRIVAL = statesBooking.STATE_ARRIVAL;
  STATE_ON_WAY = statesBooking.STATE_ON_WAY;
  domain = location.host.replace('unsafe:', '');

  services: any[];

  onRowClicked(row) {
    // console.log('Row clicked: ', row);
  }

  constructor(private activate: ActivatedRoute,
              private router: Router,
              public plans: PlansService,
              public bookingsService: BookingsService,
              public clientService: ClientService,
              private progresBarService: ProgressBarService,
              public domSanitizer: DomSanitizer,
              private messageService: MessageService
              ) {

    this.isLoading.plansLoading = true;
    this.isLoading.bookingsLoading = true;

    this.tabla = new FormGroup({
      'idSolicitud': new FormControl(''),
      'fechaInicio': new FormControl(''),
      'fechaFin': new FormControl(''),
      'services': new FormControl(''),
      'states': new FormControl('')
    });

    this.loading = true;

    localStorage.removeItem('nameGroup');
    this.isEngieClient = localStorage.getItem('engie') === "true";

    this.componentsSubscription = this.activate.parent.params.subscribe(parametros => {
      this.userId = parametros.id;
    });

  }

  ngOnInit() {
    this.planes();
    this.getCountsServices();
    this.isEngieClient && this.getBookings();
    this.getServicesGroupsNoId();
  }

  ngOnDestroy() {
    this.unsubscribeSubscription(this.countServicesSubscription);
    this.unsubscribeSubscription(this.groupSubscription);
    this.unsubscribeSubscription(this.groupSubscription);
    this.unsubscribeSubscription(this.bookingSubscription);
    this.unsubscribeSubscription(this.servicesSubscription);
    this.unsubscribeSubscription(this.componentsSubscription);
    this.unsubscribeSubscription(this.planSubscription);
  }

  unsubscribeSubscription(subscription: Subscription): void {
    if (subscription && !subscription.closed) {
      subscription.unsubscribe();
    }
  }

  onPaginateChange(event) {
    this.bookingsParams.pagination.pagesize = event.pageSize;
    this.bookingsParams.pagination.page = event.pageIndex + 1;
    this.loading = true;
    this.getBookings();
  }

  // Filtro para tabla de servicios
  applyFilter() {
    if (!(this.tabla.controls.idSolicitud.value == '')) {
      this.bookingsParams.filters.caseId = this.tabla.controls.idSolicitud.value;
    }
    if (!(this.tabla.controls.fechaInicio.value == '')) {
      this.bookingsParams.filters.fromDateSolicitud = this.tabla.controls.fechaInicio.value;
    }
    if (!(this.tabla.controls.fechaFin.value == '')) {
      this.bookingsParams.filters.toDateSolicitud = this.tabla.controls.fechaFin.value;
    }
    if (!(this.tabla.controls.services.value == '')) {
      this.bookingsParams.filters.serviceId = parseInt(this.tabla.controls.services.value);
    }
    this.getBookings();
  }

  getCountsServices(): any {
    this.countServicesSubscription = this.bookingsService.getTotalcounts().subscribe((response: any) => {
      Object.keys(response).forEach(key => {
        const index = this.infoServices.findIndex(({keyObject}) => key === keyObject);
        this.infoServices[index].cantidad = response[key];
      });
      this.countServices = response;
      this.countLoaded = true;
    });

  }

  clean() {
    this.tabla.controls['idSolicitud'].reset();
    this.tabla.controls['fechaInicio'].reset();
    this.tabla.controls['fechaFin'].reset();
    this.tabla.controls['services'].reset();
    this.bookingsParams.filters.caseId = undefined;
    this.bookingsParams.filters.fromDateSolicitud = undefined;
    this.bookingsParams.filters.toDateSolicitud = undefined;
    this.bookingsParams.filters.serviceId = undefined;
    this.getBookings();
  }

  budgetclick() {
    this.progresBarService.setCountTipe('3');
  }

  public async planes() {
    this.planSubscription = this.plans.getClientPlan().subscribe((response: any) => {
      if (response != undefined) {
        this.planContractor = response.planName;
      }
    });
    try {
      const response:TutenClientCoverageResponse = await this.plans.getClientCoverage().toPromise();
      this.dispServiceDate = moment(response.lastPeriodicityEndDate).format('DD/MM/YYYY');
    } catch (err) {
      console.error(err)
    }
  }

  public getServicesGroupsNoId() {
    const getServicesSubsc = this.bookingsService.getServicesGroupsNoId().subscribe((response: any) => {
      if (response != undefined) {
        this.groupsBookings = response;
        // Parametros para el progressBar
        //this.isLoading.servicesGroupLoading = false;
        //this.progresBarService.emitBooleanObjects(this.isLoading)
      }
    });
    // this.componentsSubscription.add(getServicesSubsc);
  }

  private getBookings() {
    this.bookingSubscription = this.bookingsService.searchBookings({
      page: this.bookingsParams.pagination.page,
      pagesize: this.bookingsParams.pagination.pagesize,
      body: this.bookingsParams.filters
    }).subscribe((response: any) => {
      if (response != null) {
        this.bookings = response.results;
        this.bookingsParams.total = response.total;
        this.getServices();
      }
    }, error => {
      this.messageService.add({severity: 'error', summary:'Error', detail: 'No se obtuvieron los datos.'});
    });
  }

  private getServices() {
    this.servicesSubscription = this.bookingsService.getServicesDefinition().subscribe((response: any) => {
      if (response != null) {
        this.unmodifiedServices = response;
        this.getGroups();
      }
    }, error => {
      this.messageService.add({severity: 'error', summary:'Error', detail: 'No se obtuvieron las Asistencias'});
    });
  }

  private getGroups() {
    this.groupSubscription = this.bookingsService.getServicesGroups().subscribe((response: any) => {
      if (response != null) {
        this.groups = response;
        this.getAvailabilityCoverages();
      } else {
        this.messageService.add({severity: 'error', summary:'Warn', detail: 'No hay registros de grupos'});
      }
    }, error => {
      this.messageService.add({severity: 'error', summary:'Error', detail: 'No se obtuvieron los grupos'});
    });
  }

  private getAvailabilityCoverages() {
    this.availabilityCoverageSubscription = this.clientService.getAvailabilityCoverages().subscribe((response: any) => {
      if (response !== undefined) {
        this.dataAvailibity = response;
        this.searchBookings();
      }
    }, error => {
      this.messageService.add({severity: 'error', summary:'Error', detail: 'No se obtuvieron los registros'});
    });
  }

  private searchBookings() {
    const localService = this.bookings.map(el => {
      const service = this.dataAvailibity.find(({id}) => id == el.serviceId);
      return ({
        ...el,
        serviceName: service ? service.name : 'N/A',
        groupId: service ? service.groupId : 'N/A'
      });
    });
    const localGroup = localService.map(el => {
      const group = this.groups.find(({id}) => id == el.groupId);
      return ({
        ...el,
        groupName: group ? group.name : 'N/A'
      });
    });
    this.services = localGroup.map(el => {

      let {id: idBooking, caseId: idSolicitud, creationDate: date, serviceName: tipo, stateBookingName: state, groupName: grupo,
        closeDate: assistenceDate } = el;
      if(el.caso.category === "Telemedicina") {
        tipo = "Telemedicina";
      }
      return {idBooking, idSolicitud, date, grupo, tipo, state, icon: 'assets/images/home-icon-black.png',
       stateCode: el.state, assistenceDate };
    });
    this.loading = false;
    // Parametros para el progressBar
    this.isLoading.bookingsLoading = false;
    this.progresBarService.emitBooleanObjects(this.isLoading);
  }

  private goChat(service: any): void {
    if (service.stateCode !== this.STATE_ON_WAY) {
      return;
    }
    // Do chat navigation...
    this.router.navigate([`dashboard/asistencia/${ service.idBooking }/chat`]);
  }

}
