import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { ClientService } from 'src/app/core/api';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/interfaces/app.interface';
import * as actionTypes from 'src/app/actions/app.actions';
import { MessageService } from 'primeng/api';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss']
})
export class ForgotPasswordFormComponent {

  ingresar: FormGroup;
  openLoginForm: boolean;

  error: string;

  constructor(private router: Router,
    private clientService: ClientService,
    public dialog: MatDialog,
    private store: Store<AppState>,
    private messageService: MessageService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {

    this.store.select('home').subscribe(
      home => this.openLoginForm = home.openLoginForm
    );


    this.ingresar = new FormGroup({
      'email': new FormControl('', [
        Validators.required,
        Validators.email])
    })
  }
      sendEmail(){
        this.store.dispatch(new actionTypes.OpenLoadingActions());

        this.recaptchaV3Service.execute('Servi2_clients_recover_password')
        .subscribe(token => {
          this.clientService.changeEmailPassword(this.ingresar.value.email, token)
          .subscribe(response => {
            this.messageService.add({severity: 'success', summary:'Éxito', detail:'Se ha enviado un correo a: ' +
            this.ingresar.get('email').value + ' con los pasos para reestablecer la contraseña'});
          }, err => {
            this.messageService.add({severity: 'error', summary:'Error', detail: err.error.error});
          })
        }, err => {
          this.messageService.add({severity: 'error', summary:'Error', detail: "Error con el recaptcha, vuelve a intentarlo"});
        })

        this.store.dispatch(new actionTypes.CloseLoadingActions());
      }

  backTohome(){
    this.router.navigate(['home']);
  }

}
