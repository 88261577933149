import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from "./components/home/home.component";
import {DashboardComponent} from './components/dashboard/dashboard.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { DASHBOARD_ROUTES } from "./components/dashboard/dashboard.routes";
import {ExitoComponent} from './components/solicitud/exito/exito.component';
import {PrivacyComponent} from "./components/privacy/privacy.component";
import {TermsComponent} from "./components/terms/terms.component";
import {GuiaRapidaComponent} from "./components/guia-rapida/guia-rapida.component";
import {AyudaComponent} from "./components/ayuda/ayuda.component";
import {AuthGuard} from "./core/api/client/auth.guard";
import {ClientenoengieComponent} from "./components/clientenoengie/clientenoengie.component";
import { HomeNoEngieComponent } from './components/home/home-no-engie/home-no-engie.component';
import { ValidateEmailComponent } from './components/validateemail/validate-email.component';
import { LayOnComponent } from './components/shared/lay-on/lay-on.component';
import { RegisterFormComponent } from './components/register-form/register-form.component';
import { ForgotPasswordFormComponent } from './components/forgot-password-form/forgot-password-form.component';
import { ChangePasswordFormComponent } from './components/change-password-form/change-password-form.component';
import { AuthAssertNotLoginGuard } from './core/api/client/auth-assert-not-login.guard';
import { AuthAssertLoginGuard } from './core/api/client/auth-assert-login.guard';

const ROUTES: Routes = [
  {path: 'home', component: HomeComponent, canActivate:[AuthAssertNotLoginGuard]},
  {path: 'register', component: RegisterFormComponent, canActivate:[AuthAssertNotLoginGuard]},
  {path: 'privacidad', component: PrivacyComponent},//-----
  {path: 'condiciones', component: TermsComponent},//-----
  {path: 'ayuda', component: AyudaComponent},//-----
  {path: 'homenoengie', component: HomeNoEngieComponent, canActivate:[AuthAssertLoginGuard]},
  {path: 'validate_email', component: ValidateEmailComponent},//-----
  {path: 'guia-rapida', component: GuiaRapidaComponent},//-----
  {path: 'activar-cuenta', component: LayOnComponent},//-----
  {path: 'cambiar-contrasena', component: ChangePasswordFormComponent, canActivate:[AuthAssertNotLoginGuard]},
  {path: 'clientnotengie', component: ClientenoengieComponent},//-----
  {path: 'forgetpass', component: ForgotPasswordFormComponent, canActivate:[AuthAssertNotLoginGuard]},
  {path: 'dashboard', component: DashboardComponent, children: DASHBOARD_ROUTES, canActivate:[AuthAssertLoginGuard]},
  {path: '', pathMatch: 'full', redirectTo: 'home'},
  {path: '**', pathMatch: 'full', redirectTo: 'home'}
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES)],
  exports: [RouterModule],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    }
  ]
})
export class AppRoutingModule { }
