import { ngxZendeskWebwidgetConfig } from 'ngx-zendesk-webwidget';


export class ZendeskConfig extends ngxZendeskWebwidgetConfig {
  // accountUrl = 'tuten1024.zendesk.com';
  accountUrl = 'tutenlabssupport.zendesk.com';
  beforePageLoad(zE) {
    zE.setLocale('es');
    zE.hide();
  }
}