import { Component } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class Search {

  page: number;
  first: number;
	limit: number;
	order: number;
  field: string;
  totalRecords: number;

  get: any;

  form: FormGroup;

  constructor(get, limit = 10, first = 0) {
    this.refresh(limit, first);

    this.get = get;
  }

  refreshPage() {
    this.page = 0;
    this.first = 0;
  }

  refresh(limit = 10, first = 0) {
    this.page = 0;
    this.limit = limit;
    this.first = first;
    this.order = -1;
    this.field = "id";
    this.totalRecords = 0;
  }

  setForm(form: FormGroup) {
    this.form = form;

    return this;
  }

  sortFunction(event: SortEvent) {
		this.order = event.order;
    this.field = event.field;
  }

  onClickHeader(event) {
    this.get();
  }

  onPageChange(event): void {
    this.page = event.page;
    this.limit = event.rows;
    this.first = event.first;

    this.get();
	}

  get params() {
    let params  = {
      page: (this.page + 1).toString(),
      pagesize: this.limit.toString(),
      field: this.field.toString(),
      order: this.order.toString()
    };

    if (this.form != null) {

      Object.keys(this.form.value).forEach((key, index) => {
        if(this.form.value[key]) {
          let value = this.form.value[key];

          if (typeof value === 'object') {
            params = params[key] = value.id;
          } else {
            params = params[key] = value;
          }
        }
      });
    }

    this.form = null;

    return params;
  }
}
