import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ClientService } from 'src/app/core/api';

@Component({
  selector: 'promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.scss']
})
export class PromotionsComponent implements OnInit {

  @Input()
  type: any;

  @Output("onInit")
	onInit_ = new EventEmitter<Object>();

  news: [] = [];

  constructor(
    public clientService: ClientService,
    private router: Router
  ) {

  }

  ngOnInit() {
    if (this.onInit_) {
      this.onInit_.emit({
        target: this
      });
    }

    this.getActiveNews(1, 3);
  }

  reload() {
    this.getActiveNews(1, 3);
  }

  /* News */
  getActiveNews(page?: number, pageSize?: number) : void {
    // this.loadingNews = true;
    this.clientService.getActiveClientNews(page, pageSize, this.type).subscribe((response: any) => {
      if(response != null) {
        this.news = response.items;

        this.news.forEach((new_: any) => {
          new_.contentNews = decodeURI(new_.contentNews).replace(/(<([^>]+)>)/gi, "");
        });

        // this.news.forEach(x => {
        //   x.contentNews = decodeURI(x.contentNews);
        //   if(x.imgs != null) {
        //     x.firstImg = x.imgs[0];
        //     if(x.firstImg == null || x.firstImg == undefined) {
        //       x.firstImg = 'https://www.noticiasaldiayalahora.co/wp-content/uploads/2017/09/Noticiasaldiayalahorashare.png';
        //     }
        //   } else {
        //     x.firstImg = 'https://www.noticiasaldiayalahora.co/wp-content/uploads/2017/09/Noticiasaldiayalahorashare.png';
        //   }
        // });

      } else {
        // this.snackBarService.openSnackBar(MESSAGE_TIMES.WARNING_MESSAGE, 'No se pudo cargar las noticias', 'warn');
      }
      // this.loadingNews = false;
    },error => {
      // this.snackBarService.openSnackBar(MESSAGE_TIMES.ERROR_MESSAGE, 'Error al cargar las noticias', 'error');
      // this.loadingNews = false;
    });

    //this.progresBarService.emitBooleanObjects(this.loadingNews);
  }

  truncateHTML(text: string): string {
    let charlimit = 80;

    if(!text || text.length <= charlimit ) {
        return text;
    }
    let adding_spaces = text.replace(/<br \/>/g, "&nbsp;");

    let without_html = adding_spaces.replace(/<(?:.|\n)*?>/gm, '');
    let shortened = without_html.substring(0, charlimit) + "...";

    return shortened;
  }

  navigateToPromotion(id: string ): void {
    this.router.navigate(['/dashboard/noticias/' + id]);
  }
}
