import { Injectable } from '@angular/core';
import {ApiService} from "../api.service";
import { TutenClientCoverageResponse } from './plans.interfaces';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { _mapTutenClientCoverage } from './planst.functions';

@Injectable({
  providedIn: 'root'
})
export class PlansService {

  constructor(public api: ApiService) { }

  getClientPlan() {
    let headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };
    return this.api.get('plans/client', null, null, headers);
  }

  getClientCoverage(): Observable<TutenClientCoverageResponse> {
    const headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    }
    return this.api.get('plans/coverage', null, null, headers).pipe(
      _mapTutenClientCoverage()
    )
  }
}
