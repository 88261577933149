import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-message',
  template: `<div
              class="message"
              [ngClass]="{'message-client': message.createdBy == 2, 'message-professional': message.createdBy != 2}">
                <span class="content">
                <p>
                  {{ message.messageContent }}
                </p>
                </span>
                <span class="time">
                  {{ message.creationDate | date:'HH:mm' }}
                </span>
              </div>`,
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  @Input()
  message: any;

  constructor() { }

  ngOnInit() {
  }

}
