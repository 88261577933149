import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/interfaces/app.interface';
import { BannerService } from 'src/app/models/BannerService.model';

@Component({
  selector: 'asist-banner-details',
  templateUrl: './asist-banner-details.component.html',
  styleUrls: ['./asist-banner-details.component.scss']
})
export class AsistBannerDetailsComponent implements OnInit {

  assists: BannerService[];

  constructor(private store: Store<AppState>) {
    this.store.select('home').subscribe(
      home => this.assists = home.bannerServices
    )
  }

  ngOnInit() {
  }

}
