import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';

@Injectable()
export class AuthAssertNotLoginGuard implements CanActivate {
	constructor(
		private router: Router
	) {}

	canActivate() {
		if (!localStorage.getItem('sessionToken')) {
			return true;
		}

		this.router.navigate(['dashboard']);

		return false;
	}
}
