import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/api';

@Component({
  selector: 'exceed-dialog',
  templateUrl: './exceed-dialog.component.html',
  styleUrls: ['./exceed-dialog.component.scss']
})
export class ExceedDialogComponent implements OnInit {

  constructor(public dialogRef:DynamicDialogRef, public config: DynamicDialogConfig) { }

  ngOnInit() {
  }

  onClose() {
    this.dialogRef.close();
  }

}
