import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { parsePhoneNumber } from 'libphonenumber-js/min';
import { CountryCode } from 'libphonenumber-js/types';
import { Subscription, timer } from 'rxjs';
import { map, switchMap, first } from 'rxjs/operators';
import { PASSWORD_PATTERN } from 'src/app/contants/app.contants';
import { ClientService } from 'src/app/core/api';
import { TutenClientProfileData } from 'src/app/core/api/client/client-interfaces';
import { AppState } from 'src/app/interfaces/app.interface';
import { getClientIdsFromForm, getEngieDataFormGroup } from './client-engie-form/client-engie-form.component';
import * as actionTypes from 'src/app/actions/app.actions';
import * as MESSAGES from 'src/app/contants/messages.contants';

@Component({
  selector: 'register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss']
})
export class RegisterFormComponent implements OnInit, OnDestroy {

  @Input()
  url: string;
  registerFormGroup: FormGroup;
  componentSubs: Subscription = new Subscription();
  registeredClient = null;
  checked: boolean = false;
  hide: boolean = true;
  hideConfirm: boolean = true;
  messageSource: any = MESSAGES;

  openLoginForm: boolean;
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private formBuilder: FormBuilder,
    private clientService: ClientService) { }

  ngOnInit() {
    this.setRegisterFormGroup();
    this.store.select('home').subscribe(
      home => this.openLoginForm = home.openLoginForm
    );

  }

  cancelRegister():void {
    this.router.navigate(['home']);
  }

  ngOnDestroy() {
    this.componentSubs.unsubscribe();
  }

  setEngieClient(engieClient: any): void {
      this.registerFormGroup.get('name').setValue(engieClient['Nombre']);
      this.registerFormGroup.get('lastName').setValue(engieClient['Apellidos']);

      if (engieClient['Telefono1']) {
        this.registerFormGroup.get('phoneNumber').setValue(engieClient['Telefono1'].slice(-10));
      }

      if (engieClient['Email']) {
        this.registerFormGroup.get('email').setValue(engieClient['Email']);
        this.registerFormGroup.get('confirmEmail').setValue(engieClient['Email'])
      }
  }

  get isValid(): boolean {
    if(!this.registerFormGroup.get('name').valid){
      return false;
    };

    if(!this.registerFormGroup.get('lastName').valid) {
      return false;
    }

    if(!this.registerFormGroup.get('email').valid) {
      return false;
    }

    if(!this.registerFormGroup.get('phoneNumber').valid) {
      return false;
    }

    if(!this.registerFormGroup.get('confirmEmail').valid) {
      return false;
    }

    if(!this.registerFormGroup.get('password').valid) {
      return false;
    }

    if(!this.registerFormGroup.get('confirmPassword').valid) {
      return false;
    }

    if(!this.registerFormGroup.get('termsAndConditions').valid) {
      return false;
    }
        
    if(!this.registerFormGroup.get('privacyPolicy').valid) {
      return false;
    }

    if(this.registerFormGroup.getError('passwordsNotEqual') || this.registerFormGroup.getError('emailsNotEqual')) {
      return false;
    }

    if(this.registerFormGroup.get('isEngie').value === true){
      return this.registerFormGroup.get('engieAccountDatas').valid
    };

    return true;
  }

  /**
   * Setting form group and controls
   */
   setRegisterFormGroup() {
    this.registerFormGroup = this.formBuilder.group({
      isEngie: true,
      engieAccountDatas: this.formBuilder.array([
        getEngieDataFormGroup(),
      ]),
      name: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.email
      ])
      ),
      phoneNumber: new FormControl('', Validators.compose([
        Validators.required,
        this.customNumberValidator
      ])),
      confirmEmail: new FormControl('', Validators.compose([
        Validators.required,
        Validators.email
      ])),
      password: new FormControl('', [
        Validators.required,
        Validators.pattern(PASSWORD_PATTERN)
      ]),
      confirmPassword: new FormControl('', Validators.required),
      termsAndConditions: new FormControl('', Validators.compose([
        Validators.requiredTrue
      ])),
      privacyPolicy: new FormControl('', Validators.compose([
        Validators.requiredTrue
      ]))  
    }, { validator: this.globalFormValidator })
  }

  /**
   * Function to validate requirements of form provided
   * @param form Form to validate
   */
  globalFormValidator(form: FormGroup): { [key: string]: boolean } | null {
    const password = form.get('password');
    const confirmPassword = form.get('confirmPassword');
    const email = form.get('email');
    const confirmEmail = form.get('confirmEmail');
    let errorObj = {};
    if (password.dirty && confirmPassword.dirty && password.value != confirmPassword.value && '' != confirmPassword.value) {
      errorObj['passwordsNotEqual'] = true
    }
    if (email.dirty && confirmEmail.dirty && email.value != confirmEmail.value && confirmEmail.value != '') {
      errorObj['emailsNotEqual'] = true;
    }
    return !!Object.keys(errorObj).length ? errorObj : null;
  }

  /**
   * Async validator to validate email Taken
   * @param clientService service
   * @param time delay time
   */
  validateEmailTaken(clientService: ClientService, time: number = 500): any {
    return (input: FormControl) => {
      return timer(time).pipe(
        first(),
        switchMap(() => clientService.getAvailableUserEmail(input.value, 'CLIENT')),
        map((res: any) => {
          return res.isAvailable ? null : { emailTaken: true }
        })
      );
    };
  }

  /**
   * Validator to phones with mx format
   * @param control control to be evaluated
   */
  customNumberValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value) {
      const countryCode: CountryCode = 'MX' as CountryCode;
      try {
        const phoneNumber = parsePhoneNumber(control.value + '', countryCode);
        return phoneNumber.isValid() ? null : { invalidNumber: true };
      } catch (error) {
        return { invalidNumber: true };
      }
    }
    return null;
  }

  /**
   * Function to make submit and redirect user
   */
  async submitForm() {
    this.store.dispatch(new actionTypes.OpenLoadingActions());
    const { email, password, isEngie, name, lastName, phoneNumber } = this.registerFormGroup.value;
    const tutenClientsIds: number[] = getClientIdsFromForm(isEngie, this.registerFormGroup);
    const registerUserData: TutenClientProfileData = {
      email,
      password,
      engie: Boolean(isEngie).valueOf(),
      firstName: name,
      lastName,
      phone: phoneNumber,
      name: name + ' ' + lastName,
      provider: 'CLIENT',
      tutenClientsIds,
      authToken: '',
      id: '',
      idToken: '',
      photoUrl: ''
    };

      this.clientService.registerUser(registerUserData).subscribe((response) => {
          this.registeredClient = true;
          this.store.dispatch(new actionTypes.CloseLoadingActions());
      }, (err: any) => {
        this.store.dispatch(new actionTypes.CloseLoadingActions());
      });
    }

}
