import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'file-pool',
  templateUrl: './file-pool.component.html',
  styleUrls: ['./file-pool.component.scss']
})
export class FilePoolComponent {

  @Input()
  elements: any[] = [];

  @Input()
  title: string = "";

  @Input()
  fileIcon: string = "assets/images/empty.jpg";

  @Input()
  type = "document";

  @Output()
  actionOnDelete: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  deleteElement(i: number) {
    this.actionOnDelete.emit({context: this.type, i});
  }

  navigate(url: string): void {
    if(url !== null && url !== ""){
      window.open(url, '_blank');
    }
  }

}
