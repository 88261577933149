export const PASSWORD_PATTERN = '^(?=.*\\\d)(?=.*[\\\u002D-\\\u002E\\\u0040\\\u005F\\\u002A.])(?=.*[A-Z])(?=.*[a-z])\\S{6,}$';
export const mapImageService = {
  184367222: "assets/images/raia.jpg",
  184367224: "assets/images/gasodomesticos.jpg",
  184367223: "assets/images/gasnatural.jpeg",
  184367225: "assets/images/cerrajero.png",
  184367226: "assets/images/plomero.png",
  184367227: "assets/images/vidriero.jpg",
  184367228: "assets/images/electricista.png",
  184367229: "assets/images/ambulancia.jpg",
  184367230: "assets/images/orientacionMedicaTelefono.jpg",
  184367231: "assets/images/orientacionPsicologicaTelefono.jpg",
  184367232: "assets/images/orientacionNutricionalTelefono.jpg",
  184367233: "assets/images/medicoADomicilio.jpg",
}
