import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ClientService } from 'src/app/core/api';
import { TutenClientProfile } from 'src/app/core/api/client/client-interfaces';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-validate-email',
  templateUrl: './validate-email.component.html',
  styleUrls: ['./validate-email.component.scss'],
  providers: [HttpUrlEncodingCodec]
})
export class ValidateEmailComponent implements OnInit, OnDestroy {
  componentSubscription: Subscription = new Subscription();
  validateEmailToken: string;
  validatedClient: TutenClientProfile = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private clientService: ClientService,
    private decoded: HttpUrlEncodingCodec,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.getQueryParams();
    this.validateEmailByToken();
  }

  ngOnDestroy() {
    this.componentSubscription.unsubscribe();
  }

  /**
   * Function to fetch token validation
   */
  getQueryParams() {
    const queryParamsSubs = this.route.queryParamMap.subscribe(params => {
      if(params.get('token'))
        this.validateEmailToken = this.decoded.decodeValue(params.get('token').toString().replace(/\s/g, '+'));
      if(!this.validateEmailToken)
        this.router.navigate(['/home']);
      else
        this.router.navigate([], {queryParams: {token: null}, queryParamsHandling: 'merge'});
    })

    this.componentSubscription.add(queryParamsSubs);
  }



  async validateEmailByToken() {
    try {
      this.validatedClient = await this.clientService.validateEmailToken(this.validateEmailToken).toPromise();
      this.messageService.add({severity: 'success', summary:'Éxito', detail: "Usuario ya verificado"});

      this.clientService.redirectLogin(this.validatedClient);
    } catch (error) {
       this.messageService.add({severity: 'error', summary:'Error', detail: "Usuario ya verificado o no existe"});
        this.router.navigate(['/home']);
    }
  }
}
