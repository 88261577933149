import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from '@angular/material';
import { FormGroup, FormControl, Validators, AbstractControl, FormBuilder } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef, MessageService } from "primeng/api";
import { BookingsService } from "src/app/core/api/bookings/bookings.service";
import { TutenClientReasonCancellation } from "src/app/core/api/bookings/booking.interfaces";
import { Subscription } from "rxjs";
import { OnDestroy } from "@angular/core";

@Component({
  selector: 'app-cancel-assistance',
  templateUrl: 'cancel-assistance.compoment.html',
  styleUrls: ['./cancel-assistance.component.scss']
})
export class CancelAssistanceComponent implements OnInit, OnDestroy {

  currentView: number = 0;

  booking: any = null;

  options: any[] = [];

  cancelForm: FormGroup;
  cancelBookingSubscription: Subscription;
  actionInprocess : boolean = false;

  constructor(public dialogRef:DynamicDialogRef, public config: DynamicDialogConfig,  private formBuilder: FormBuilder,
    private bookingService: BookingsService,
    private messageService: MessageService) {
      this.options = [
        {label: 'Problema resuelto', value: 'Problema resuelto'},
        {label: 'No era lo que esperaba', value: 'No era lo que esperaba'},
        {label: 'Otra razón', value: 'Otra razón'}
    ];

    }

  ngOnInit() {
    this.setCancelForm();
    this.setSelectListener();
  }

  ngOnDestroy() {
    this.unsubscribeSubscription(this.cancelBookingSubscription);
  }

  unsubscribeSubscription(subscription: Subscription): void {
    if (subscription && !subscription.closed)
      subscription.unsubscribe();
  }

  setCancelForm(): void {
    this.cancelForm = new FormGroup({
      reasonCancellation: new FormControl('', Validators.required),
    });
  }

  setSelectListener(): void {
    const formControl: AbstractControl = this.formBuilder.control('', [Validators.required, Validators.maxLength(140)]);
    this.cancelForm.get('reasonCancellation').valueChanges.subscribe(value => {
      if (value === 'Otra razón')
        this.cancelForm.addControl('otherReasonCancellation', formControl);
      else
        this.cancelForm.removeControl('otherReasonCancellation');
    })
  }

  cancelAssistance() : void {
    const reasonCancellation = this.cancelForm.get('reasonCancellation').value;
      const otherReasonCancellation = reasonCancellation === 'Otra razón' ? this.cancelForm.get('otherReasonCancellation').value : '';
      const clientReasonCancellation: TutenClientReasonCancellation = { reasonCancellation, otherReasonCancellation };
    this.actionInprocess = true;
    this.cancelBookingSubscription = this.bookingService
        .cancelBooking(this.config.data.bookingId, clientReasonCancellation)
        .subscribe(
          (response) => {
            this.booking = response;
            this.currentView++;
            this.messageService.add({severity: 'success', summary:'Éxito', detail: "La solicitud ha sido cancelada"});
            this.actionInprocess = false;
          },
          (err) => {
            this.messageService.add({severity: 'error', summary:'Error', detail: "No se pudo cancelar la solicitud"});
            this.actionInprocess = false;
          });
  }

  onClose(formSubmited: boolean): void {
    const dataPassed = formSubmited ? this.booking : null;
    this.dialogRef.close(dataPassed);
  }
}
