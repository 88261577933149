import { Injectable } from '@angular/core';
import {ApiService} from '../api.service';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AxaService {

  constructor(
    public api: ApiService,
    public http: HttpClient
  ) { }

  connectAxaFrame(data) {
    let headers = {
      userId: localStorage.getItem('clientId'),
      token: localStorage.getItem('sessionToken')
    };

    return this.api.post('telemedicine/integration', data, headers, null)
  }

}
