export class Utils {
  static getMobileOperatingSystem(): string {
    var userAgent = navigator.userAgent || navigator.vendor;
      // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return "iOS";
    }
    
    if (userAgent.includes('Mac')) {
      return 'MacOS';
    } 

    if (userAgent.includes('Win')) {
      return 'Windows';
    }

    return "desktop";
  }
}
